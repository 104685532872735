/* ==========================================================================
   Page / Search Results
   ========================================================================== */

  .search-results {
    .header {
      padding-top: 100px;
      padding-bottom: 30px;
      position: relative;

      @include breakpoint(tablet) {
        padding-top: 200px;
        padding-bottom: 250px;
      }

      @include breakpoint(tablethor) {
        padding-top: 275px;
        padding-bottom: 240px;
      }
      @include breakpoint(mobileonly){
        display: flex;
        flex-direction: column-reverse;
        padding-top: 65px;
      }

      &-content {
        margin-bottom: 35px;

        .breadcrumb {
          margin-bottom: 35px;

          @include breakpoint(tablet) {
            margin-bottom: 60px;
          }
        }
      }
    }

    .searchbar {
      padding: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0;

      @include breakpoint(tablet) {
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        margin-top: 0;
        padding: 0;
        border-radius: 0;
        position: fixed;
        z-index: 9;
        top: 65px;
      }

      @include breakpoint(tablethor) {
        top: 143px;
      }
      @include breakpoint(mobileonly){
        width: 100%;
      }

      .form-search {
        background: transparent;
        padding: 20px 25px;
        max-width: 1200px;
        margin: auto;

        @include breakpoint(phablet) {
          padding: 20px 35px;
        }

        @include breakpoint(tablet) {
          padding: 20px 25px;

          .benefit {
            width: calc(50% - 75px);

            @include breakpoint(tablethor) {
              width: calc(50% - 120px);
            }
          }

          .postal-code {
            width: calc(50% - 170px);
          }
        }

        .btn-main-white {
          display: table;
          width: auto;
          margin: 25px auto 0;

          @include breakpoint(tablet) {
            display: inline-block;
            margin: 5px 0 0 15px;
            padding: 12px 45px;
          }
        }
      }
    }

    .container-white {
      padding: 20px 0 0 0;

      @include breakpoint(tablet) {
        padding: 30px 20px 0;
      }

      .h1-title-uppercase {
        @include breakpoint(laptop) {
          margin-bottom: 25px;
        }
      }
    }

    .container-univers {
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        margin-bottom: 60px;
      }
    }

    .container + .container-univers {
      padding: 0 5px;

      @include breakpoint(tablet) {
        padding: 0 25px;
      }

      @include breakpoint(tablethor) {
        padding: 0 5px;
      }
    }
  }

  .navigation.active + .search-results > .header > .searchbar {
    @include breakpoint(tablet) {
      top: 65px;
    }

    @include breakpoint(tablethor) {
      top: 132px;
    }
  }