/* ==========================================================================
   Components / Notes
   ========================================================================== */

  .mark {
    &-star {
      margin-bottom: 10px;

      @include breakpoint(phablet) {
        display: inline-block;
      }

      li {
        display: inline-block;
        width: 25px;
        height: 24px;
        background: url('./src/img/note-etoile-grise.svg') center center/ 25px 24px no-repeat;

        @include breakpoint(tablethor) {
          width: 18px;
          height: 17px;
          background-size: 18px 17px;
        }

        &.full {
          background-image: url('./src/img/note-etoile-full.svg');
        }

        &.half {
          background-image: url('./src/img/note-etoile-half.svg');
        }
      }
    }

    &-stat {
      font-weight: 600;

      @include breakpoint(phablet) {
        display: inline-block;
        position: relative;
        bottom: 5px;
        left: 10px;
      }

      @include breakpoint(tablethor) {
        font-size: 15px;
        bottom: 3px;
      }
    }
  }