/* ==========================================================================
   Components / Search Bar
   ========================================================================== */

  .searchbar {
      margin: 30px auto;
      background: transparent;
      position: relative;
      margin-top: 0;
      padding-bottom: 70px;
      border-radius: 6px;
      transition: all 0.2s ease;
      z-index: 5;

      @include breakpoint(tablet) {
        width: 100%;
        background: #f8aa00;
        background: -webkit-linear-gradient(left, rgba(255, 135, 0, 0.9), rgba(248, 170, 0, 0.9));
        background: -o-linear-gradient(right, rgba(255, 135, 0, 0.9), rgba(248, 170, 0, 0.9));
        background: -moz-linear-gradient(right, rgba(255, 135, 0, 0.9), rgba(248, 170, 0, 0.9));
        background: linear-gradient(to right, rgba(255, 135, 0, 0.9), rgba(248, 170, 0, 0.9));
        margin-top: 30px;
        padding: 36px 40px 40px;
      }

      @include breakpoint(tablethor) {
        &.active {
          position: fixed;
          top: 132px;
          left: 0;
          right: 0;
          z-index: 10;
          margin-top: 0;
          background: rgba(0, 0, 0, 0.5);
          padding: 20px;
          z-index: 5;
          border-radius: 0px;;
          transform: translateZ(0);
        }
      }
      @include breakpoint(mobileonly){
        display: flex;
        flex-direction: column-reverse;
      }

      &-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 1.1;
        margin-bottom: 22px;
        color: $whiteColor;
        text-align: center;
        text-shadow: 3px 1px 8px rgba(0, 0, 0, 0.55);

        @include breakpoint(tablet) {
          text-shadow: 3px 1px 8px rgba(0, 0, 0, 0.25);
        }

        @include breakpoint(laptop) {
          text-align: left;
        }
        @include breakpoint(mobileonly){
          margin-top: 40px;
        }
      }

      .form-search {
        background: rgba(0, 0, 0, 0.5);
        padding: 20px 25px;

        @include breakpoint(phablet) {
          padding: 40px 35px;
        }

        @include breakpoint(tablet) {
          background: transparent;
          padding: 0;
        }
      }

      .form-line {
        input {
          border: 0;
        }
      }

      .input-ct {
        background: $backgroundLight;
        padding: 0px 10px;
      }

      .icon-cluster {
        color: $orange;
        display: inline-block;
        vertical-align: middle;
        font-size: 22px;
      }

      input {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        height: 50px;
        padding: 10px;
        width: 100%;

        @include placeholder {
          font-size: 13px;
          color: #767676;
        }
      }

      .btn-submit-white,
      .postal-code,
      .benefit {
        display: inline-block;
        vertical-align: middle;
      }

      .benefit {
        width: 100%;

        @include breakpoint(tablet) {
          width: calc(50% - 10px);
          margin-right: 10px;
        }

        @include breakpoint(tablethor) {
          width: calc(40% - 10px);
        }
      }

      .postal-code {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;

        @include breakpoint(tablet) {
          width: calc(50% - 10px);
          margin: 0;
        }

        @include breakpoint(tablethor) {
          width: calc(30% - 10px);
        }

        @include breakpoint(laptop) {
          margin-left: 10px;
        }

        input {
          width: calc(100% - 22px);
        }
      }

      .btn-submit-white {
        display: table;
        margin: 25px auto 0;

        @include breakpoint(tablethor) {
          display: inline-block;
          width: calc(25% - 10px);
          margin: 0 0 0 20px;
        }
      }

      .icon-fleche-bas-blanc {
        text-align: center;
        font-size: 22px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%)
      }
    }