/* ==========================================================================
   Page / Paramètres Commandes
   ========================================================================== */

  .check-container {
    margin-bottom: 28px;

    .ct-check {
      background: $light_grey;
      cursor: pointer;
      text-align: center;
      display: block;
      width: 100%;
      padding: 30px 24px;
      margin: 0 20px 10px 0;
      border: 4px solid #fff;
      transition: all 0.2s ease;

      @media(max-width: 1279px) {
        margin: 10px 0px;
      }

      position: relative;
      overflow: hidden;

      input {
        position: absolute;
        left: -9999px;
      }

      &.active {
        border: 4px solid $orange;
      }

      label, p {
        display: block;
        margin-top: 25px;
        font-size: 14px;
        color: $orange;
        font-weight: 600;
        //position: relative;
        //top: 50%;
        //@include transform(translateY(-50%))
      }
    }
  }

  .container-list-product-agencies {
    .total-basket {
      padding: 65px 40px 30px;
      background: $light_grey;
      margin-bottom: 20px;
      position: relative;
    }
    .label-total {
      position: absolute;
      width: 170px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 23px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'rimouski-sb', sans-serif;
      text-transform: uppercase;
      background: url(./src/img/total-bg.svg) center center / 100% 100% no-repeat;
    }
    .recap-total {
      color: $orange;
      font-size: 45px;
      font-weight: 800;
      text-align: center;
      .unity {
        display: inline-block;
        vertical-align: top;
        font-weight: 800;
        font-size: 26px;
        margin-top: 7px;
      }
    }
  }

  .secure-payment{
    @media(max-width: 767px) {
      text-align: left;
    }
    img{
      display: inline-block;
      vertical-align: middle;
    }
  }

  .container-collapse-steps{
    margin-top: 66px;
    @include breakpoint(tablethor){
      margin-top: 144px;
    }

    .step-4{
      padding-bottom: 0;

      .btn-main-black-large, .btn-main-full-large {
        width: 250px!important;
      }

      .ct-img-cb{
        margin-bottom: 18px;
        //margin-top: 150px;
        @media(max-width: 1024px) {
          margin-top: 0px;
        }
        img{
          display: inline-block;
          vertical-align: middle;
        }
      }
      .h3-title{
        margin-bottom: 16px;
      }
      .info-icon{
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;;
        color: #fff;
        width: 29px;
        height: 29px;
        background: #f39302;
        text-align: center;
        line-height: 29px;
        font-weight: 700;
      }

      p{
        &.info{
          margin-top: 30px;
          margin-bottom: 45px;
          .txt{
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 48px);
            margin-left: 12px;
            font-size: 16px;
            font-weight: 700;
            color: $orange;
            .bold {
              font-size: 20px;
              display: block;
            }
          }
        }
      }
      .label{
        font-size: 12px;
      }
      .ct-btn{
        text-align: center;
        margin-top: 22px;
      }
      .month{
        width: 65px;
      }
      .year{
        width: 75px;
      }
      .crypto{
        width: 155px;
        position: relative;

        &:hover {
          .infoshover {
            opacity: 1.0;
            transform: translateX(0px);
            transition: all $duration $easeInOutCirc;
          }
        }

        span{
          display: inline-block;
          vertical-align: middle;
        }

        .question{
          margin-left: 5px;
          font-size: 9px;
          color: #fff;
          width: 13px;
          height: 13px;
          line-height: 13px;
          border-radius: 50%;;
          background: #a0a0a0;
          text-align: center;
        }

        .infoshover {
          display: block;
          opacity: 0;
          transform: translateX(15px);
          width: 235px;
          padding: 14px 24px;
          position: absolute;
          left: 120%;
          top: 0;
          background: $backgroundLight;
          transition: all $duration $easeInOutCirc;
          font-size: 12px;
          line-height: 1.2;
          box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);

          &:before {
            content: ' ';
            display: block;
            width: 15px;
            height: 15px;
            background: $backgroundLight;
            position: absolute;
            box-shadow: -5px 4px 8px 0 rgba(0, 0, 0, 0.1);
            left: -8px;
            top: 18px;
            transform: rotate(45deg);
          }
        }
      }
      .month,
      .year,
      .crypto{
        display: inline-block;
        vertical-align: middle;
        margin-right: 19px;
      }
    }
    .step-ct{
      margin-bottom: 5px;
      .container-slideup{
        display: none;
        .inline-flex{
          display: inline-flex;
          width: 100%;
          @include breakpoint(tablet){
            flex-wrap: wrap;
          }
          @include breakpoint(tablethor){
            flex-wrap: nowrap;
          }
          @media(max-width: 767px){
            flex-wrap: wrap;
          }
        }
      }
      .step{

        &.step-3{
          .btn-main-full{
            margin: 30px auto;
          }
          .form-common .error {
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            padding: 30px 0 0;
          }
        }
      }
      .step-click{
        cursor: pointer;
        .secure-payment{
          transition: all 0.2s ease;
        }
        &.active{
          cursor: auto;
          .secure-payment{
            opacity: 0;
            @media(max-width: 767px){
              display: none;
            }
          }
        }
      }
      .step-title{
        //height: 110px;
        //line-height: 110px;
        line-height: 145px;
        padding: 0;
        transition: all 0.2s ease;
        color: $title-disable;
        background: $light_grey;
        padding: 0;
        @media(max-width: 767px){
          height: auto;
          line-height: normal;
          padding: 10px 0px;
        }
        &.active{
          background: #fff;
          color: #000;
        }
        .container .ct-btn{
          @media (min-width: 768px) and (max-width: 1023px) {
            margin-top: -5%;
          }
        }
      }
    }
    .ct-total{
      line-height: normal;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 1024px) {
        vertical-align: middle;
      }
    }
    .ct-btn{
      text-align: right;

      .btn-main {
        padding: 9px 40px;
      }
    }
    .price{
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
      color: $orange;
      margin-bottom: 10px;

      @include breakpoint(tablet) {
        font-size: 28px;
        line-height: 28px;
      }
    }
    .number{
      font-size: 32px;
    }
    .label{
      font-size: 23px;
    }
    .ttc{
      font-size: 19px;
      text-transform: uppercase;
    }
    .reduction,
    .time{
      color: $text-color;
      text-align: right;
    }
    .reduction{
      font-size: 14px;
      font-weight: 400;
      @media(max-width: 1024px){
        font-size: 10px;
      }
    }
    .time{
      font-size: 12px;
      font-weight: 400;
      @media(max-width: 1024px){
        font-size: 10px;
      }
    }
    .bold{
      font-weight: 700;
    }

    .createform {
      display: none;
      padding: 0 15px;

      @include breakpoint(phablet) {
        max-width: 500px;
        margin-top: 35px;
        margin: auto;
      }

      @include breakpoint(tablet) {
        margin: 0;
      }

      @include breakpoint(tablethor) {
        margin-left: 40px;
      }

      .label {
        font-size: 12px;
      }

      .h3-title {
        margin-bottom: 40px;
      }

      .alert-js {
        font-weight: 600;
        color: $red;
        line-height: 1.5;
      }

      .btn-main-full-large {
        margin: 25px 0;
      }
      #infos-perso .buttons .btn{
        @media (max-width: 767px){
          margin-left: calc((100% - 184.66px)/2);
        }
      }

      .button-modif {
        display: none;
        margin-top: -20px;
        margin-bottom: 55px;

        #modifierInfos {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .form-log-in{
    background: #f6f6f5;
    padding: 39px 160px 44px 49px;
    margin-right: 1%;
    @media(max-width:1179px){
      padding: 44px 49px;
    }
    @media(max-width:767px){
      padding: 30px;
    }
    .btn-main-full-lite{
      margin: 0px auto;
    }
    .form-line{
      background: #fff;
      border: 1px solid #9c9b9a;
      border-radius: 3px;
      padding-left: 20px;
    }
    input{
      border: 0;
      display: inline-block;
      width: calc(100% - 50px);
      padding-left: 0;
    }

    .label{
      font-size: 12px;
      left: 60px;
    }

    .icon{
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      position: relative;
      bottom: 5px;
    }
    .forgot{
      display: block;
      text-align: right;
      color: #000;
      font-size: 14px;
      font-weight: 200;
      text-decoration: underline;
      float: right;
      margin-bottom: 30px;
      &:hover{
        text-decoration: none;
      }
    }
    .input-checkbox{
      font-size: 16px;
      font-weight: 200;
      margin: 8px 0px 30px;
      clear: both;
      .label{
        font-weight: 200;
        &:before{
          width: 20px;
          height: 20px;
          background: #fff;
        }
        &:after{
          top: -8px;
          left: 2px;
          width: 28px;
          height: 11px;
          background: transparent;
          transform: rotate(-46deg);
          border-radius: 3px;
          border-left: 4px solid #f39200;
          border-bottom: 4px solid #f39200;
        }
      }
    }
    .form-line{
      .label{
        &.on{
          left: 60px;
        }
      }
    }
    .title-form{
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .sub-title-form{
      font-size: 16px;
      font-weight: 200;
      margin-bottom: 30px;
    }
  }

  .create-account{
    border: 1px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;

    .ct-create{
      display: block;
      //position: relative;
      text-align: center;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      @media(max-width:767px){
        position: static;
        //transform: translate(0%, 0%);
        top: auto;
        left: auto;
        padding: 20px 0;
      }
      p{
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 24px;
      }

      .btn-main-full-lite {
        margin: auto;
      }
    }
  }

  .form-height{
    border-radius: 3px;
    flex-grow: 1;
    height: 100%;
    width: 49%;
    @include breakpoint(tablet){
      min-height: 471px;
    }
    @media (max-width: 767px){
      width: 100%;
    }
  }

  .account-avantages{
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 40px;
    margin-bottom: 50px;
    background: $light_grey;
    p{
      width: 90%;
      font-weight: 800;
      font-size: 21px;
      text-align: center;
      margin: 0 auto 50px;
    }
    .ct-txt{
      display: inline-block;
      font-size: 16px;
    }
    ul{
      text-align: center;
      li{
        text-align: center;
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
        max-width: 200px;
        @include breakpoint(tablethor){
          margin: 0px 35px;
        }
        @include breakpoint(tablet){
          margin: 0px 20px;
        }
        span{
          width: 100%;
        }
        .icon{
          margin-bottom: 14px;
          height: 80px;
        }
      }
    }
  }

  .ct-delivery-address {
    border: 1px solid darken($light_grey, 10%);
    padding: 30px 35px 40px;
    position: relative;
    display: block;
    //height: 100%;
    width: calc(100% / 3);
    margin-right: 1%;
    flex-grow: 1;
    transition: all 0.2s ease;

    &.dashed {
      //height: 100%;
      border: 1px dashed darken($light_grey, 10%);
      min-height: 368px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(tablet){
        min-height: 368px;
      }

      .icon-plus-orange {
        position: relative;

        &:before {
          position: relative;
          z-index: 1;
        }

        &:after {
          content: ' ';
          display: block;
          width: 91%;
          height: 91%;
          background: $orange;
          border-radius: 500px;
          transform: scale(0);
          transition: all $duration $easeInOutCirc;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 0;
        }
      }

      &:hover {
        border: 1px solid darken($light_grey, 10%);

        .delivery-center {
          .icon-fleche-droite-aside {
            margin-left: 20px;
          }

          .icon-plus-orange{
            &:before{
              color: #fff;
            }

            &:after {
              transform: scale(1.0);
              transition: all $duration $easeInOutCirc;
            }
          }
        }
      }
    }
    input[type="radio"]{
      position: absolute;
    }
    @include breakpoint(tablet){
      width: 48.5%;
      max-width: 48.5%;
      margin-bottom: 1%;
    }
    @include breakpoint(tablethor){
      width: calc(100% / 3);
      margin-bottom: 0;
    }
    @media(max-width: 767px){
      margin-bottom: 20px;
      width: 49%;
    }
    @media (max-width: 567px){
      width: 100%;
    }
    &.active {
      border: 2px solid $orange;
      .radio {
        &:after {
          width: 15px;
          height: 15px;
        }
      }
    }
    .title-adress {
      font-family: $special-font-family;
      padding-right: 20px;
      font-size: 26px;
      @media (max-width: 700px) {
        height: 50px;
      }
    }
    .radio {
      cursor: pointer;
      width: 28px;
      height: 28px;
      border-radius: 50%;;
      border: 1px solid $light_grey;
      position: absolute;
      top: 16px;
      right: 16px;
      &:after {
        content: "";
        display: inline-block;
        vertical-align: top;
        background: $orange;
        width: 0px;
        height: 0px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;;
        transition: all 0.2s ease;
      }
    }
    .ct-text {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 80px);
      padding-left: 35px;
      @media(max-width: 1179px){
        width: 100%;
        padding-left: 0px;
        margin-top: 20px;
        height: 115px;
      }
    }
    .name,
    .adresstxt {
      width: 100%;
      display: inline-block;
      line-height: 24px;
    }
    .name {
      font-size: 16px;
      font-weight: 700;
    }
    .adresstxt {
      font-size: 16px;
      font-weight: 400;
    }
    .ct-content {
      margin-top: 23px;
      margin-bottom: 29px;
    }
    .btn {
      padding: 14px 75px;
    }
    .delivery-center {
      //position: relative;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      text-align: center;
      @media(max-width: 767px){
        position: static;
        //transform: translate(0%, 0%);
        top: auto;
        left: auto;
      }
      p {
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: $orange;
        margin-top: 35px;
      }
      .txt {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
      .icon-fleche-droite-aside {
        &:before {
          color: $orange;
        }
      }
      .icon-plus-orange,
      .icon-fleche-droite-aside {
        transition: all 0.2s ease;
        &:before {
          transition: all 0.2s ease;
        }
      }
      .icon-plus-orange{
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .btn-main {
      margin: 0;
      @media(max-width: 1024px){
        width: 100%;
        display: block;
        text-align: center;
        padding: 14px 0px;
      }
    }
  }
  .step-1{
    .h1-basket-step{
      padding: 40px 0px 10px;
    }
    .width-for-mobile-basket{
      @media (min-width: 535px) and (max-width: 767px) {
        width: 60%;
      }
    }
    .width-for-mobile-details{
      @media (min-width: 535px) and (max-width: 767px) {
        width: 30%;
        margin-top: -50px;
      }
    }
  }

  .step-4 .container{
    .agenciesTchatBlock{
      display: none;
      @include breakpoint(laptop){
        display: inherit;
      }
    }
    .form  {
      padding-left: 25px;
    }
  }