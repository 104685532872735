/* ==========================================================================
   Page / Pages Agences
   ========================================================================== */

  .container-agencies {
    padding-top: 100px;

    @include breakpoint(tablethor) {
      padding-top: 180px;
    }

    .breadcrumb-list li span, .breadcrumb-list li a:after {
      color: $text-color;
    }

    .breadcrumb .container {
      width: 100%;
    }
  }

  .agenciesMap {
    margin-top: 40px;
    padding: 0 5px;

    @include breakpoint(phablet) {
      padding: 0 15px;
    }

    @include breakpoint(tablethor) {
      margin-top: 70px;
    }

    .h1-title-uppercase {
      margin-bottom: 30px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-form {
      .formmap {
        margin-bottom: 20px;

        .btn-submit-arrow{
          height: 35px;
          position: relative;
          top: 0;
          right: 10px;
        }
      }

      .label {
        display: inline-block;
        color: $text-color;
        font-weight: 800;
        font-size: 21px;
        margin-right: 50px;
      }

      .ct-input{
        display: inline-block;
        background: $light_grey;
        height: 48px;
        line-height: 22px;
        margin-top: 10px;

        input{
          width: 265px;
          font-size: 13px;
          background: $light_grey;
          border: 0;
          padding: 0px 20px;
          line-height: 48px;
          height: 48px;
          @include placeholder{
            font-size: 13px;
            color: #767676;
          }
        }
      }
    }

    &-content {
      display: table;
      width: 100%;
      min-height: 500px;
      position: relative;
      overflow: hidden;

      .infosAgency {
        display: block;
        width: 97%;
        height: auto;
        max-width: 400px;
        opacity: 0;
        background: $whiteColor;
        padding: 35px 35px 30px;
        box-shadow: 10px 0 28px 5px rgba(0,0,0,0.15);
        transform: translateY(300px);
        visibility: hidden;
        transition: all $duration $easeInOutQuart;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 5;

        &.active {
          opacity: 1.0;
          visibility: visible;
          transform: translateY(0);
          transition: all $duration $easeInOutQuart;
        }

        @include breakpoint(phablet) {
          top: 25px;
          left: 15%;
          padding: 35px 35px 45px;
          box-shadow: -10px 0 28px 5px rgba(0,0,0,0.15);
        }

        @include breakpoint(tablet) {
          top: 25px;
          left: 30%;
        }

        @include breakpoint(tablethor) {
          left: 8%;
          right: 0;
          top: 20%;
        }

        @include breakpoint(laptop) {
          left: 15%;
        }

        &-close {
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 15px;
          right: 10px;
          cursor: pointer;
          z-index: 6;

          &:hover {
            &:before, &:after {
              background: $text-color;
              transition: all $durationSmall $easeInOutCirc;
            }
          }

          &:before {
            content: ' ';
            display: block;
            width: 15px;
            height: 2px;
            background: $orange;
            transform: rotate(43deg);
            transform-origin: left;
            position: relative;
            top: -1px;
            left: 1px;
            border-radius: 20px;
            margin: 0 auto;
            transition: all $durationSmall $easeInOutCirc;
          }

          &:after {
            content: ' ';
            display: block;
            width: 15px;
            height: 2px;
            background: $orange;
            transform: rotate(-43deg);
            transform-origin: left;
            position: relative;
            top: 7px;
            left: 1px;
            border-radius: 20px;
            margin: 0 auto;
            transition: all $durationSmall $easeInOutCirc;
          }
        }

        &-title {
          display: block;
          color: $text-color;
          font-weight: 800;
          font-size: 18px;
          line-height: 1.3;
          margin-bottom: 30px;

          @include breakpoint(tablet) {
            font-size: 21px;
            line-height: 1.1;
          }
        }

        &-adress {
          font-size: 13px;
          color: $text-color;
          line-height: 1.5;
          background: url('./src/img/picto-localisation.svg')top left /24px 32px no-repeat;
          padding-left: 45px;
          margin-bottom: 15px;
        }

        &-phone {
          font-size: 13px;
          color: $text-color;
          line-height: 1.3;
          background: url('./src/img/picto-telephone.svg')top left /23px 21px no-repeat;
          padding: 3px 45px 5px;
          margin-bottom: 20px;
        }

        &-links {
          text-align: center;
          margin-bottom: 20px;

          @include breakpoint(phablet) {
            padding-left: 45px;
            text-align: left;
            margin-bottom: 20px;
          }

          a {
            display: block;
            font-size: 14px;
            margin-bottom: 5px;
            text-decoration: underline;

            &:hover {
              color: $orange;
            }
          }
        }

        &-more {
          text-align: center;
        }
      }

      .map {
        display: block;
        width: 100%;
        min-height: 380px;
        border-right: 1px solid #fff;
        border-radius: 5px;
        margin-bottom: 25px;
        overflow: hidden;

        @include breakpoint(tablethor) {
          display: table-cell;
          width: 55%;
          margin-bottom: 0;
          border-right: 10px solid #fff;
        }

        @include breakpoint(laptop) {
          width: 60%;
        }

        .map-marker-label{
          display: none;
        }
      }

      .agencieslist {
        width: 100%;
        border-left: 2px solid #fff;

        @include breakpoint(phablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        @include breakpoint(tablethor) {
          display: table-cell;
          width: 45%;
        }

        @include breakpoint(laptop) {
          width: 40%;
        }

        &-elem {
          padding: 15px 25px 15px 70px;
          position: relative;
          background: $light_grey url('./src/img/icone-localisation.svg') 15px 25px/27px 34px no-repeat;
          border-radius: 5px;
          margin-bottom: 15px;
          transition: all $durationSmall $easeOutQuint;
          cursor: pointer;

          @include breakpoint(phablet) {
            flex-basis: 49%;
            padding: 15px 35px 15px 75px;
            background: $light_grey url('./src/img/icone-localisation.svg') 25px 25px/27px 34px no-repeat;
          }

          @include breakpoint(800px) {
            padding: 15px 75px 15px 90px;
            background: $light_grey url('./src/img/icone-localisation.svg') 35px 25px/27px 34px no-repeat;
          }

          &:hover {
            .icon-fleche-droite-orange {
              right: 25px;
              color: $text-color;
              transition: all $durationSmall $easeOutQuint;
            }
          }

          &.inactive {
            opacity: 0.2;
            transition: all $durationSmall $easeOutQuint;
          }

          strong {
            display: block;
            color: $text-color;
            font-weight: 800;
            font-size: 18px;
            line-height: 1.1;
            margin-bottom: 5px;

            @include breakpoint(tablet) {
              font-size: 21px;
            }
          }

          p {
            display: block;
            font-size: 16px;
            margin-bottom: 5px;
          }

          .viewFiche {
            display: block;
            color: $orange;
            font-weight: 600;
            letter-spacing: 0.1em;
            font-size: 12px;
            text-transform: uppercase;
            border: 0;
            padding: 0;
            background: transparent;
            transition: all $durationSmall $easeOutQuint;
            cursor: pointer;

            @include breakpoint(tablet) {
              white-space: nowrap;
              font-size: 14px;
            }

            &:focus {
              outline-style: none;
            }

            &:hover {
              color: $text-color;
              transition: all $durationSmall $easeOutQuint;
            }
          }

          .icon-fleche-droite-orange {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 35px;
            height: 36px;
            transition: all $durationSmall $easeOutQuint;

            @include breakpoint(800px) {
              display: block;
            }
          }
        }

        .btn-load-more {
          margin: 30px auto 0;
        }
      }
    }
  }

  /* PAGE DETAILS AGENCES */

  .container-agency-info {
    padding: 100px 5px 0;

    @include breakpoint(phablet) {
      padding: 100px 15px 0;
    }

    @include breakpoint(tablethor) {
      padding-top: 180px;
    }

    .breadcrumb {
      margin-bottom: 40px;

      @include breakpoint(tablethor) {
        margin-bottom: 70px;
      }
    }

    .subtitle {
      @extend .h3-title;
      font-family: $special-font-familyReg;
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 15px;

      @include breakpoint(tablethor) {
        margin-bottom: 45px;
      }

      span {
        opacity: 0.6;
      }

      .logo-laposte {
        margin: 0 0 0 10px;
        width: 35px;
        position: relative;
        top: 7px;
      }
    }

    .agencyInformations {
      display: block;

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

      }

      &-visuel {
        display: block;
        width: 100%;

        @include breakpoint(phablet) {
          flex-basis: 50%;
          width: 50%;
        }

        @include breakpoint(tablethor) {
          flex-basis: 45%;
          width: 45%;
          order: 0;
        }

        @include breakpoint(laptop) {
          flex-basis: 30%;
          width: 30%;
          order: 0;
        }

        @include breakpoint(1300px) {
          flex-basis: 35%;
          width: 35%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &-infos {
        margin-bottom: 25px;

        @include breakpoint(phablet) {
          margin-bottom: 0;
          flex-basis: 50%;
          width: 50%;
          order: 1;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 42%;
            width: 42%;
          }
        }

        @include breakpoint(tablethor) {
          flex-basis: 50%;
          width: 50%;
          order: 2;
        }

        @include breakpoint(laptop) {
          flex-basis: 35%;
          width: 35%;
          order: 0;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 29%;
            width: 29%;
          }
        }
      }

      &-opening {
        @include breakpoint(phablet) {
          flex-basis: 60%;
          width: 60%;
          padding-right: 5%;
          order: 2;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 55%;
            width: 55%;
          }
        }

        @include breakpoint(tablethor) {
          flex-basis: 55%;
          width: 55%;
          padding: 15px 5%;
          order: 1;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 45%;
            width: 45%;
          }
        }

        @include breakpoint(laptop) {
          flex-basis: 35%;
          width: 35%;
          padding: 0;
          order: 0;
        }

        @include breakpoint(1300px) {
          flex-basis: 30%;
          width: 30%;
        }
      }

      &-description {
        margin-top: 30px;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          flex-basis: 100%;
          width: 100%;
          padding: 0 10px;
          order: 4;
        }

        @include breakpoint(tablethor) {
          margin-top: 0;
          padding-right: 10%;
          padding-left: 40px;
        }

        @include breakpoint(laptop) {
          padding-left: 0;
          flex-basis: 65%;
          width: 65%;
          order: 0;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 53%;
            width: 53%;
          }
        }

        @include breakpoint(1300px) {
          flex-basis: 70%;
          width: 70%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 57%;
            width: 57%;
          }
        }
      }

      &-testimony {
        margin-bottom: 35px;

        @include breakpoint(phablet) {
          flex-basis: 40%;
          width: 40%;
          position: relative;
          margin-bottom: 0;
          order: 3;
        }

        @include breakpoint(tablethor) {
          flex-basis: 45%;
          width: 45%;
          bottom: 2rem;
          order: 3;
        }

        @include breakpoint(laptop) {
          flex-basis: 35%;
          width: 35%;
          //bottom: 5px;
          order: 0;
        }

        @include breakpoint(1300px) {
          flex-basis: 30%;
          width: 30%;
          bottom: 2rem;
        }
      }

      &-presta {
        padding: 0 10px;

        @include breakpoint(phablet) {
          flex-basis: 100%;
          order: 5;
        }

        @include breakpoint(tablethor) {
          padding: 0;
          padding-left: 40px;
        }

        @include breakpoint(laptop) {
          flex-basis: 75%;
          order: 0;
          padding-left: 0;
        }

        .list-prestations {
          margin-top: 30px;
          margin-bottom: 40px;

          @include breakpoint(phablet) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
          }

          @include breakpoint(laptop) {
            width: 80%;
          }

          li{
            font-size: 21px;
            font-family: 'Rimouski Semibold', sans-serif;
            margin-bottom: 30px;

            @include breakpoint(phablet) {
              flex-basis: 50%;
            }

            @include breakpoint(tablethor) {
              flex-basis: 50%;
            }

            .icon{
              transition: all 0.2s ease;
              width: 62px;
              height: 62px;
              background-size: 62px 62px;
              margin-right: 15px;
            }

            .ct-icon{
              display: inline-block;
              vertical-align: middle;
            }

            .txt{
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 90px);
            }

            a{
              display: block;
              width: 100%;
              height: 100%;
              &:hover{
                .icon{
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    img,
    p{
      margin-top: 5px;
      margin-bottom: 15px;

      @include breakpoint(phablet) {
        margin-bottom: 35px;
      }
    }

    .btn-main {
      padding: 12px 18px;
      margin: 35px 0;
      font-size: 12px;

      @include breakpoint(tablet) {
        margin: 15px 0;
      }
      @media (min-width: 576px) and (max-width: 675px){
        padding: 12px 14px;
        font-size: 12px;
      }
    }
  }

  .practical-information{
    padding-top: 10px;

    @include breakpoint(phablet) {
      padding-left: 20px;
    }

    .h3-title{
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        margin-bottom: 25px;
      }
    }
  }

  .table-shedules{
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;

    @include breakpoint(desktop) {
      margin-top: 30px;
    }

    th,
    td{
      text-align: center;
      font-size: 16px;
      padding: 12px 10px;
    }
    .thead{
      background: $orange;
      color: #fff;
      th,
      td{
        padding: 15px 10px;
      }
    }
    .tbody{
      tr:nth-child(even) {
        background: #fff;
      }
      tr:nth-child(odd){
        background: $light_grey;
      }
    }
  }