/* ==========================================================================
   Page / Panier
   ========================================================================== */

  .basketPage {
    margin-top: 50px;

    .h1-title {
      margin-bottom: 30px;
    }
  }

  .container-basket {
    @media(max-width: 1023px) {
      margin-top: 90px;
    }
    margin-top: 167px;
    .title-axeo {
      color: $text-color;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        margin-bottom: 25px;
      }

      &:before {
        background: $text-color;
      }
    }

    .h2-basket {
      margin-bottom: -15px;
    }
    .h2 {
      font-family: 'rimouski-sb', sans-serif;
      color: $text-color;
      font-size: 26px;
      margin-bottom: 30px;
    }

    .h3-title {
      margin-bottom: 35px;
    }
  }

  .table-basket {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-bottom: 20px;
    .ct-quantity {
      text-align: center;

      .time,
      .calcul,
      .reduction{
        display: block;
        text-align: left;
      }

      .bold{
        font-weight: 700;
      }

      .calcul{
        margin-bottom: 5px;
      }

      .price{
        font-weight: 600;
        @media(max-width:767px){
          font-size: 14px;
        }
      }

      .reduction{
        font-size: 16px;
        font-weight: 400;
        position: relative;
        right: 15px;

        @media(max-width:767px){
          font-size: 13px;
          right: 0;
        }
      }

      .time{
        font-size: 13px;
        font-weight: 400;
      }

      .number{
        font-size: 30px;
        font-weight: 700;

        @media(max-width:767px){
          font-size: 20px;
        }
      }

      .result{
        color: $orange;
        font-size: 30px;
        font-weight: 700;

        @media(max-width:767px){
          font-size: 20px;
        }
        .unity{
          text-transform: uppercase;
          font-size: 15px;
        }
      }

      .euro{
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        font-weight: 700;
      }

      .equal,
      .multiply{
        display: inline-block;
        vertical-align: middle;
        margin: 0px 10px;
        position: relative;
        bottom: 2px;
      }


    }
    tr {
      background: $light_grey;
    }

    .ct-txt{
      .title{
        margin-bottom: 13px;
      }
      .volume{
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 11px;
      }
    }

    td {
      /*
      &:before {
        content: attr(data-title);
        display: none;
        text-align: left;
        text-transform: uppercase;
        font-family: 'rimouski-sb', sans-serif;
        margin-bottom: 10px;
        color: $text-color;
        font-size: 17px;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 10px;
      } */
      @media (max-width: 767px) {
        display: block;
        padding: 0;
        margin: 17px 15px 17px 15px;
        height: auto;
        text-align: left;
        &:before {
          display: block;
        }
      }
      &:first-child {
        position: relative;

        @include breakpoint(tablet) {
          width: 50%;
        }

        .supr {
          position: absolute;
          top: 16px;
          left: 20px;
          display: block;

          @media(max-width: 767px) {
            top: 0;
            left: auto;
            right: 0;
          }

          &:hover {
            .txt {
              color: $orange;
              transition: all $duration $easeOutQuart;
            }

            .icon-cross-basket:before {
              color: $orange;
              transition: all $duration $easeOutQuart;
            }
          }

          .txt {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 10px;
            color: #babab9;
            margin-left: 5px;
            transition: all $duration $easeOutQuart;
          }

          .icon-cross-basket {
            font-size: 8px;
            color: #000;

            &:before {
              color: #babab9;
              transition: all $duration $easeOutQuart;
            }
          }
        }
      }
      &:last-child {
        position: relative;
        text-align: center;
        &:before{
          display: none;
        }
        @include breakpoint(tablet) {
          width: 10%;
        }
      }
      vertical-align: middle;
      padding: 15px 30px;

      .ct-info {
        padding-bottom: 20px;
        border-bottom: 1px solid #d4d4d4;

        @include breakpoint(tablet) {
          padding-bottom: 10px;
          border-bottom: 0px;
        }

        .flat-rate{
          font-weight: 800;
          color: $orange;
          font-size: 21px;
          text-transform: uppercase;
        }

        .pipe{
          width: 2px;
          height: 20px;
          background: $text-color;
          margin: 0px 10px;
        }

        .formula{
          font-size: 21px;
          font-weight: 400;
        }

        .flat-rate,
        .pipe,
        .formula{
          display: inline-block;
          vertical-align: middle;
        }

        .date{
          font-weight: 700;
          font-size: 14px;
        }

        .surface{
          font-weight: 100;
          font-size: 14px;
          letter-spacing: 0.01em;
        }
      }
    }
  }

  .form-sold {
    margin-bottom: 8px;

    @media(max-width:1023px){
      margin-bottom: 20px;
    }

    input {
      border: 1px solid #9c9b9a;
      border-radius: 3px;
      display: block;
      width: 100%;
      height: 53px;
      padding-left: 15px;

      @include placeholder {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .container-price{
    text-align: right;
    margin-top: 35px;

    .ht-price,
    .tva-price{
      padding-right: 30px;
    }

    .tva-price {
      margin-bottom: 30px;
    }

    .ht-price{
      color: $orange;
      font-size: 25px;
      .euro{
        font-size: 15px;
        display: inline-block;
        vertical-align: middle;
      }
      .question{
        margin-left: 5px;
        font-size: 9px;
        color: #fff;
        width: 13px;
        height: 13px;
        line-height: 13px;
        border-radius: 50%;;
        background: #a0a0a0;
        text-align: center;
      }
    }
    .month,
    .year,
    .crypto{
      display: inline-block;
      vertical-align: middle;
      margin-right: 19px;
    }
    .btn-orange,
    .btn-black{
      padding: 14px 80px;
      margin: 0px auto;
    }
  }
  .step-ct{
    margin-bottom: 5px;
    .container-slideup{
      display: none;
      .inline-flex{
        display: inline-flex;
        width: 100%;
      }
    }

    .tva-price{
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .ttc-price{
    background: rgb(242,133,0);
    background: -moz-linear-gradient(left, rgba(242,133,0,1) 0%, rgba(248,170,0,1) 100%);
    background: -webkit-linear-gradient(left, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
    background: linear-gradient(to right, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
    border-radius: 3px;
    padding: 20px 28px;
    margin-bottom: 96px;
    text-align: right;
    @media(max-width: 767px){
      margin-bottom: 20px;
    }
    .price{
      font-size: 28px;
      font-weight: 800;
      color: #fff;
    }
    .quote{
      color: #fff;
      font-size: 14px;
      font-weight: 200;
      margin-top: 5px;
    }
    .number{
      margin-left: 55px;
    }
  }

  .orderbuttons {
    display: block;
    width: 100%;
    text-align: center;

    a {
      display: block;
      width: 100%;
      margin: 0 auto 20px;

      @include breakpoint(phablet) {
        width: 310px;
        margin: 0 auto 20px;

      }

      @include breakpoint(tablet) {
        display: inline-block;
        margin: 5px 15px;
        width: auto;
      }
    }
  }