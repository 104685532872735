/* ==========================================================================
   Layout / Prestations
   ========================================================================== */

  .prestations {
    margin-bottom: 35px;

    @include breakpoint(laptop) {
      width: 95%;
    }

    @include breakpoint(desktop) {
      width: 90%;
    }

    @media(max-width: 1359px){
      width: 100%;
    }

    @media(min-width: 1280px) and (max-width: 1380px){
      width: 97%;
    }

    .prestation {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      color: $text-color;
      background: $light_grey;
      padding: 30px 10px 20px;
      margin-bottom: 20px;
      transition: all $duration $easeInOutQuart;
      position: relative;
      @include breakpoint(phablet) {
        padding: 30px 25px;
      }
      @media(max-width: 768px){
        justify-content: flex-start;
      }

      &:hover {
        background: lighten($light_grey, 2%);
        transition: all $duration $easeInOutQuart;

        .prestation-state .icon-fleche-droite-orange {
          transform: translateX(10px);
          transition: all $duration $easeInOutQuart;
        }
      }

      .ct-icon {
        display: inline-block;
        align-self: center;
        height: 61px;
        margin-right: 20px;
        transition: all $duration $easeInOutQuart;

        @include breakpoint(phablet) {
          margin-right: 20px;
          position: relative;
          top: 0;
          right: 0;
        }

        .icon {
          width: 63px;
          height: 100%;
          background-size: 63px 61px;
          position: relative;

          &-prestation-partners{
            width: 65px;
            height: 65px;
          }
        }
      }

      &-info{
        display: inline-block;
        width: calc(100% - 85px);
        line-height: 25px;
        @include breakpoint(phablet){
          width: 50%;
        }
        @include breakpoint(tablet){
          width: 60%;
        }
        @include breakpoint(tablethor){
          width: 68%;
        }
        &-title span{
          color: $orange;
        }

        &-date {
          margin-right: 3%;
          text-align: left;
          font-size: 14px;

          @include breakpoint(phablet) {
            vertical-align: middle;
            text-align: left;
            font-size: 16px;
            margin-bottom: 0;
            margin-right: 0;
          }
        }

        &-start {
          text-transform: uppercase;

          @include breakpoint(tablet) {
            width: 21%;
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            padding: 0;
          }

          @include breakpoint(916px) {
            margin-left: 0;
          }

          @include breakpoint(tablethor) {
            width: 20%;
          }

          a {
            color: $orange;
            font-weight: 600;
            text-decoration: underline;
            &:hover{
              text-decoration: none;
            }
          }
        }
      }
      &-link{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(mobileonly){
          margin-top: 20px;
        }
        @include breakpoint(tablet){
          align-self: center;
          width: auto;
        }
        @media (min-width: 634px) and (max-width: 767px){
          width: 35%;
        }
        @media (min-width: 599px) and (max-width: 633px){
          width: 34%;
        }
      }

      &-state {
        display: inline-block;
        vertical-align: middle;
        min-width: 85px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        position: relative;
        margin-right: 10px;

        @include breakpoint(phablet) {
          margin-left: 5%;
        }

        @include breakpoint(768px) {
          text-align: left;
          margin: -20px 0;
          padding: 0;
          top: 0;
        }

        @include breakpoint(916px) {
          margin: 0;
        }

        &.current {
          color: $orange;
        }
      }

      &-price{
        width: 8%;
        display: inline-block;
        text-align: center;
        position: relative;
        //top: 5px;
        @include breakpoint(phablet) {
          margin-left: 5%;
        }

        @include breakpoint(768px) {
          margin: -20px 0;
          padding: 0;
          top: 0;
        }

        @include breakpoint(916px) {
          margin: 0;
        }
      }
    }
  }