/* ==========================================================================
   Components / Réassurance But
   ========================================================================== */

  .aim {
    width: 95%;
    margin: auto;
    padding: 25px 10px;

    @include breakpoint(tablethor) {
      padding: 5px 0;
    }

    @media (min-width: 1500px) {
      width: 1440px;
    }

    .ct-txt, .number-aim {
      display: inline-block;
      vertical-align: middle;
    }

    .label {
      display: block;
      font-size: 18px;
      color: $orange;
      font-family: $special-font-family;
      margin-bottom: 5px;

      @include breakpoint(desktop) {
        font-size: 20px;
      }
    }

    .number-aim {
      font-size: 90px;
      color: $orange;

      @include breakpoint(desktop) {
        font-size: 100px;
      }
    }

    .ct-txt {
      width:  auto;
      text-align: left;

      @include breakpoint(tablet) {
        width: calc(100% - 70px);
      }
    }

    p {
      padding: 0;
      text-align: center;

      @include breakpoint(tablet) {
        text-align: left;

      }

      @include breakpoint(tablethor) {
        padding: 33px 0px;
      }

      .txt {
        font-size: 14px;
        line-height: 1;

        @include breakpoint(laptop) {
          font-size: 16px;
        }
      }
    }
  }