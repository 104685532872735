/* ==========================================================================
   Components / Buttons
   ========================================================================== */

/* Boutons généraux */

.btn {
  border: none;
  display: block;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 0;
  background-image: none;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 0px;;
  background-clip: border-box;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &.valid {
    border: 2px solid $orange;
  }
}

button:focus {
  outline: none;
}

// Bouton général bordure orange couleur orange
.btn-main {
  display: table;
  background: transparent;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $orange;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  border-radius: 80px;;
  padding: 12px 45px;
  transition: all $duration $easeInOutQuart;
  margin: 25px auto;

  @include breakpoint(tablethor) {
    margin: 45px auto;
  }

  &:hover{
    background: $orange;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général background + bordure orange couleur orange
.btn-main-lite {
  display: table;
  background: transparent;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $orange;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  border-radius: 80px;;
  padding: 10px 30px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: $orange;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général background + bordure orange couleur orange
.btn-main-full {
  background: $orange;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $whiteColor;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  border-radius: 80px;;
  padding: 12px 45px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: lighten($orange, 8%);
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général background + bordure orange couleur orange
.btn-main-full-lite {
  background: $orange;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $whiteColor;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  border-radius: 80px;;
  padding: 10px 30px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: lighten($orange, 8%);
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général background + bordure orange couleur orange
.btn-main-full-large {
  display: inline-block;
  margin: 5px 15px;
  background: $orange;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $whiteColor;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  border-radius: 80px;;
  padding: 16px 65px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: lighten($orange, 8%);
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général blanc fond transparent
.btn-main-white {
  background: transparent;
  font-size: 15px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $whiteColor;
  letter-spacing: 0.1rem;
  border: 2px solid $backgroundLight;
  border-radius: 80px;;
  padding: 14px 45px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: $backgroundLight;
    color: $orange;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général bordure noire couleur noire
.btn-main-black {
  display: table;
  background: transparent;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $dark_grey;
  letter-spacing: 0.1rem;
  border: 2px solid $dark_grey;
  border-radius: 80px;;
  padding: 12px 45px;
  transition: all $duration $easeInOutQuart;
  margin: 25px auto;
  @include breakpoint(tablethor) {
    margin: 45px auto;
  }

  &:hover{
    background: $dark_grey;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton général bordure noire couleur noire
.btn-main-black-large {
  display: inline-block;
  margin: 5px 15px;
  background: transparent;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $dark_grey;
  letter-spacing: 0.1rem;
  border: 2px solid $dark_grey;
  border-radius: 80px;;
  padding: 16px 45px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: $dark_grey;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}
.btn-main-black-lite {
  display: inline-block;
  margin: 5px 15px;
  background: transparent;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $dark_grey;
  letter-spacing: 0.1rem;
  border: 2px solid $dark_grey;
  border-radius: 80px;;
  padding: 10px 30px;
  transition: all $duration $easeInOutQuart;

  &:hover{
    background: $dark_grey;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton background blanc couleur orange
.btn-white{
  @extend .btn-white;
  display: table;
  background: $backgroundLight;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: $orange;
  letter-spacing: 0.1rem;
  border-radius: 80px;;
  padding: 14px 45px;
  transition: all $duration $easeInOutQuart;
  margin: 10px auto;

  &:hover{
    background: $orange;
    color: $whiteColor;
    transition: all $duration $easeInOutQuart;
  }
}

// Bouton avec flèche à droite
.btn-with-arrow {
  font-family: $font-family-base;
  font-size: 15px;
  color: $orange;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  position: relative;
  top: 3px;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:after,
  span{
    display: inline-block;
    vertical-align: middle;
  }

  &:after {
    content: ' ';
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url('./src/img/icone-fleche-orange.svg') center center/contain no-repeat;
    margin-left: 10px;
    transition: all 0.2s ease;
  }

  &:hover {
    &:after {
      transform: translateX(5px);
      transition: all 0.2s ease;
    }
  }
}

.btn-with-arrow-white{
  @extend .btn-with-arrow;
  color: #fff;
  &:after {
    background: url('./src/img/icone-fleche-blanche.svg') center center/contain no-repeat;
  }
}

.btn-professional-sidebar {
  display: block;
  width: 100%;
  color: $whiteColor;
  font-weight: 600;
  text-align: center;
  background: #c2c0c0;
  background: -moz-linear-gradient(-39deg, #969694 0%, #c2c0c0 100%);
  background: -webkit-linear-gradient(-39deg, #969694 0%, #c2c0c0 100%);
  background: linear-gradient(-39deg, #969694 0%, #c2c0c0 100%);
  font-size: 14px;
  letter-spacing: 0.02rem;
  padding: 16px 20px;
  transition: all $durationSmall $easeOutCubic;
  position: relative;
  bottom: 5px;
  margin-bottom: 15px;

  .icon-fleche-droite-blanc {
    font-size: 25px;
  }

  .txt {
    margin-right: 10px;
    transition: all $durationSmall $easeOutCubic;
  }

  @include breakpoint(640px) {
    font-size: 16px;
  }

  @include breakpoint(tablet) {
    top: 10px;
    bottom: inherit;
  }

  @include breakpoint(laptop) {
    display: block;
    text-align: center;
    border: 0;
    margin-top: 15px;
    padding: 16px 20px;
    font-size: 15px;
    letter-spacing: 0.01rem;

    .txt {
      display: inline-block;
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 0;
      transition: all $durationSmall $easeOutCubic;
    }

    .icon-fleche-droite-blanc {
      display: inline-block;
      font-size: 22px;
    }

    &:hover {
      transition: all $durationSmall $easeInOutCirc;
      background: -moz-linear-gradient(-80deg, #969694 0%, #c2c0c0 100%);
      background: -webkit-linear-gradient(-80deg, #969694 0%, #c2c0c0 100%);
      background: linear-gradient(-80deg, #969694 0%, #c2c0c0 100%);

      .txt {
        margin-right: 15px;
        transition: all $durationSmall $easeOutCubic;
      }
    }
  }

  @include breakpoint(desktop) {
    .txt {
      margin-right: 10px;
    }

    &:hover {
      .txt {
        margin-right: 15px;
        transition: all $durationSmall $easeOutCubic;
      }
    }
  }

  @include breakpoint(1445px) {
    font-size: 16px;
    letter-spacing: 0.02rem;
    padding: 16px 30px;

    .txt {
      margin-right: 15px;
    }

    .icon-fleche-droite-blanc {
      display: inline-block;
      font-size: 25px;
    }

    &:hover {
      .txt {
        margin-right: 20px;
      }
    }
  }

}

.modal-footer{
  .btn-submit-arrow{
    .txt{
      color: $orange;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 30px;
      transition: all 0.2s ease;
      display: inline-block;
      vertical-align: middle;
    }
    &:hover{
      .txt{
        color: #000;
      }
    }
  }
}

.btn-back{
  float: left;
  &:hover{
    .icon{
      color: $orange;
    }
  }
  .icon{
    font-size: 43px;
    &:before{
      transition: all 0.2s ease;
    }
  }
  .txt{
    transition: all 0.2s ease;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    color: $orange;
    font-family: 'opensans-semibold', sans-serif;
    margin-left: 10px;
  }
  &:hover{
    .txt,
    .icon:before{
      color: $text-color;
    }
  }
}

.btn-print{
  text-transform: uppercase;
  color: $orange;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-align: center;
  margin-top: 30px;
  float: right;

  &:hover{
    text-decoration: none;
  }
}

.btn-suppr{
  border-radius: 50%;;
  border: 1px solid $dark_grey;
  transition: all 0.2s ease;
  width: 38px;
  height: 38px;
  margin: 0px auto;
  text-align: center;
  line-height: 34px;
  &:hover{
    background: $dark_grey;
    color: #fff;
  }
}

.btn-camera{
  width: 44px;
  height: 40px;
  border-radius: 3px;
  line-height: 35px;
  display: inline-block;
  background: $orange;
  text-align: center;

  &:hover{
    background: $orange_light;
  }
}

.btn-bin, .btn-bin-inte{
  .txt{
    color: $placeholder;
    text-transform: uppercase;
    font-size: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .icon{
    color: $orange;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;;
    text-align: center;
    line-height: 40px;
    transition: all 0.2s ease;
    position: relative;
  }
  &:hover{
    .icon{
      color: #000;
    }
  }
}

.btn-contact-agency{
  @extend .btn-contact;
  font-size: 14px;
  .txt{
    font-size: 14px;
    margin-right: 0;
  }
  &:hover{
    .txt{
      text-decoration: underline;
    }
  }
  &:after{
    content: "";
    display: none;
  }
}

.btn-submit-arrow{
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(./src/img/icone-fleche-orange.svg) center center/35px 35px no-repeat;
  transition: all $duration $easeInOutCirc;

  &:hover {
    background: url(./src/img/icone-fleche-noir.svg) center center/35px 35px no-repeat;
    transition: all $duration $easeInOutCirc;
  }
}

.btn-add-to-basket, .btn-add-to-basket-inte {
  @extend .btn-main-full;
  color: #fff;
  background: $orange;
  padding: 6px 25px 9px;
  border: 2px solid $orange;
  //max-width: 270px;
  line-height: normal;
  border-radius: 80px;;

  &:hover{
    background: transparent;
    color: $orange;
  }
  .txt{
    margin-right: 5px;
  }
  .txt,
  .icon{
    display: inline-block;
    vertical-align: middle;
  }
  &.white{
    background: #fff;
    border-color: #fff;
    color: $orange;
    &:hover{
      background: transparent;
      color: #fff;
    }
  }
}

.btn-prev-page {
  display: inline-block;
  float: left;
  width: 12px;
  height: 21px;
  background: url(./src/img/icone-fleche-gauche.svg) center center / 12px 21px no-repeat;
  transition: all $duration $easeInOutQuart;
  text-indent: -9999px;
  margin-top: 10px;
  margin-right: 20px;

  &:hover {
    transition: all $duration $easeInOutQuart;
    transform: translateX(-5px);
  }
}

.btn-chose {
  display: block;
  font-size: 14px;
  color: $whiteColor;
  background: transparent;
  margin: auto auto 50px;

  .txt{
    text-decoration: underline;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
  }
  .icon{
    &:before{
      color: #fff;
    }
    transition: all 0.2s ease;
  }
  &:hover{
    .txt{
      text-decoration: none;
    }
    .icon{
      margin-left: 10px;
    }
  }
}


/* PRE HEADER BUTTONS */

.btn-myaxeo {
  display: inline-block;
  width: 98px;
  font-size: 12px;
  color: $whiteColor;
  letter-spacing: 0.1rem;
  border: 2px solid $orange;
  font-weight: 700;
  background: $orange;
  height: 32px;
  line-height: 29px;
  border-radius: 30%;;
  text-align: center;
  margin-right: 40px;

  &:hover{
    background: transparent;
    color: $orange;
  }
}

.btn-emploi {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: $orange;
  font-weight: 700;
  margin-right: 10px;

  &:hover {
    color: $text-color;
  }
}

.btn-contact-us {
  padding: 0 36px;
  line-height: 45px;
  height: 49px;
  position: relative;
  background: transparent;
  top: -3px;
  transition: all 0.2s ease;
  text-decoration: none;

  .icon-fleche {
    font-size: 5px;
    color: $dark_grey;
    margin-left: 8px;
    margin-top: 4px;
  }

  .txt {
    font-size: $base-font-size;
    font-weight: 700;
    color: $dark_grey;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &.active,
  &:hover{
    background: $light_grey;
  }
}

.btn-contact-email{
  width: 100%;
  text-align: left;
  padding-left: 21px;

  .icon-contactez-nous{
    font-size: 12px;
    margin-right: 10px;
    &:before{
      color: $orange;
    }
  }

  .txt{
    font-weight: 700;
    font-size: 15px;
  }

  &:hover{
    background: $orange;

    span{
      color: $whiteColor;

      &:before{
        color: $whiteColor;
      }
    }
  }
}

.btn-help {
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 21px;

  .icon-contactez-nous{
    font-size: 12px;
    &:before{
      color: $orange;
    }
  }

  .txt{
    font-weight: 700;
    font-size: 15px;
  }

  &:hover{
    background: $orange;
    span{
      color: $whiteColor;

      &:before{
        color: $whiteColor;
      }
    }
  }

  .icon-aide {
    color: $orange;
    margin-right: 8px;
  }

}

.btn-be-called {
  height: 30px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid $orange;
  text-align: center;
  line-height: 22px;
  border-radius: 30%;;

  .icon{
    font-size: 14px;
  }

  .txt{
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 12px;
    color: $orange;
    letter-spacing: 0.1em;
  }

  &:hover{
    background: $orange;
    span,
    span:before{
      color: $whiteColor;
    }
  }
}

/* NAVIGATION BUTTONS */

.btn-open-aside{
  position: absolute;
  display: none;
  top: 50px;
  left: 1px;
  width: 50px;
  height: 50px;
  line-height: 47px;
  text-align: center;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background: $orange;
  transform: translateX(-100%);
  transition: all 0.2s ease;

  @media(max-width: 1279px){
    display: block;
  }

  &.active{
    background: $light_grey;
    box-shadow: -6px 3px 7px 0 rgba(67, 67, 67, 0.13);

    .icon-fleche-droite-aside {
      transform: rotate(90deg);
      &:before {
        content: "\e923";
        color: $orange;
        font-size: 14px;
      }
    }
  }

  .icon-fleche-droite-aside {
    transition: all 0.2s ease;
    &:before{
      color: #fff;
    }
  }
}

.btn-burger{
  height: 65px;
  width: 65px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;

  &.active{
    > span{
      transform: rotate(180deg);
      background: transparent;
      &:after{
        top: -14px;
        width: 18px;
        transform: translateY(14px) rotate(45deg);
      }
      &:before{
        top: 14px;
        width: 18px;
        transform: translateY(-14px) rotate(-45deg);
      }
    }
  }

  > span{
    width: 18px;
    height: 3px;
    border-radius: 2px;
    position: relative;
    background: $backgroundLight;
    display: inline-block;
    transition: all 0.2s ease;
    &:before,
    &:after {
      content: "";
      width: 15px;
      height: 3px;
      display: block;
      position: absolute;
      border-radius: 2px;
      transition: all 0.2s ease;
      background: $backgroundLight;
      left: 0;
    }
    &:after{
      top: -7px;
    }
    &:before{
      top: 7px;
    }
  }
}

.btn-basket,
.btn-login {
  width: 127px;
  height: 94px;
  display: inline-block;
  vertical-align: top;
  line-height: 92px;
  color: #fff;
  float: right;

  @media(max-width: 1470px) {
    width: 115px;
  }

  @media(max-width: 1366px){
    width: 110px;
  }

  @media(max-width: 1023px){
    width: 65px;
    height: 65px;
    line-height: 62px;
  }

  .vam{
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    line-height: normal;
    text-align: center;
  }

  .icon{
    width: 100%;
    text-align: center;
    display: block;
    height: auto;
    font-size: 36px;
  }

  .txt{
    display: none;
    width: 100%;

    text-align: center;
    font-size: 13px;
    margin-top: 3px;
    font-weight: 600;

    @include breakpoint(tablethor) {
      display: block;
    }
  }

  .icon-fleche-droite-blanc{
    color: $whiteColor;
    font-size: 40px;
  }
}

.btn-basket {
  background: $orange;

  &:hover{
    background: darken($orange, 10);

    .number{
      animation: number 0.5s ease 0.2s;
    }
  }

  .icon-panier{
    position: relative;
    width: 38px;
    display: inline-block;
  }

  .number{
    background: #fff;
    text-align: center;
    line-height: 18px;
    font-family: $font-family-base;
    color: $orange;
    font-size: 13px;
    font-weight: 700;
    position: absolute;
    bottom: -7px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;;
  }
}

.btn-login {
  background: $text-color;
  position: relative;

  &:hover{
    background: darken($text-color, 10);
  }
}

@keyframe(number) {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(2px);
  }
  75% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0px);
  }
}

.btn-deconnect {
  display: block;
  text-indent: -9999px;
  width: 23px;
  height: 23px;
  background: url('./src/img/picto-deconexion.svg') center center/20px 20px no-repeat;
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 1;
}

/* FOOTER BUTTONS */

.btn-job-offers,
.btn-become-franchise {
  height: 100px;
  line-height: 100px;
  width: 100%;
  float: left;
  text-align: center;

  @media(max-width: 767px){
    width: 100%;
    height: 70px;
    line-height: 70px;
  }

  &:hover{
    .icon-fleche-droite-blanc{
      transform: translateX(50px);
    }
  }

  .txt {
    font-size: 28px;
    color: $whiteColor;
    font-weight: 700;

    @media(max-width: 767px){
      font-size: 20px;
      //width: 140px;
      display: inline-block;
      text-align: right;
    }

    @include breakpoint(tablethor) {
      display: inline-block;
    }
  }

  .icon-fleche-droite-blanc{
    font-size: 40px;
    color: $whiteColor;
    margin-left: 10%;
    position: relative;
    top: -5px;
    transition: all 0.2s ease;
  }
}

.btn-job-offers{
  background: #5f5f5f;
  background: -webkit-linear-gradient(-39deg, $orange_light, $orange_dark);
  background: -o-linear-gradient(-39deg, $orange_light, $orange_dark);
  background: -moz-linear-gradient(-39deg, $orange_light, $orange_dark);
  background: linear-gradient(-39deg, $orange_light, $orange_dark);
  margin-right: 10px;
  @media(max-width: 767px){
    margin-top: 20px;
  }
  .inline-block-visible-xs{
    display: none;
    @media(max-width: 767px){
      display: inline-block;
    }
  }
}

.btn-become-franchise {
  background: #5f5f5f;
  background: -webkit-linear-gradient(left, #5f5f5f, #383838);
  background: -o-linear-gradient(right, #5f5f5f, #383838);
  background: -moz-linear-gradient(right, #5f5f5f, #383838);
  background: linear-gradient(to right, #5f5f5f, #383838);

  @media(max-width: 767px){
    margin-top: 4px;
  }
}

.btn-back-to-top {
  width: 55px;
  height: 55px;
  border-radius: 50%;;
  background: $orange;
  line-height: 50px;
  text-align: center;
  position: fixed;
  right: 30px;
  bottom: 20px;
  display: none;
  z-index: 10;
  opacity: 0;
  transition: all 0.2s ease;
  &.active{
    opacity: 1;
  }
  &:hover{
    background: darken($orange, 10);
  }
}

.btn-discover-services{
  margin-left: 15px;
  .icon{
    display: inline-block;
    vertical-align: middle;
    color: $orange;
    font-size: 24px;
  }
  .txt{
    font-weight: 700;
    color: $orange;
    margin-left: 10px;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 12px;
  }
  &:hover{
    .txt{
      text-decoration: underline;
    }
  }
}

.btn-apply{
  @extend .btn-basket;
  width: 250px;
  .icon{
    display: none;
    @media(max-width: 767px){
      display: inline-block;
      width: 65px;
      height: 65px;
    }
    width: 73px;
    height: 70px;
  }
  .txt {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    @media(max-width: 1024px) {
      display: block;
    }
    @media(max-width: 767px){
      display: none;
    }
  }
  @media(max-width: 767px){
    width: 65px;
  }
}

.btn-chevron{
  display: inline-block;
  vertical-align: middle;
  transition: all 0.2s ease;
  &.active{
    transform: scale(1, -1);
  }
}

.btn-detail{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: $orange;
  margin-top: 20px;
  margin-bottom: 20px;
  .txt{
    margin-right: 10px;
    transition: all 0.2s ease;
  }
  span{
    display: inline-block;
    vertical-align: middle;
  }
  &:hover{
    .txt{
      margin-right: 20px;
    }
  }
}
.validCommandMobile{
  @media (max-width: 1023px){
    position: absolute;
    bottom: - 542px;
    left: calc((100% - 290px) /2);
  }
  @media (min-width: 483px) and (max-width: 767px) {
    bottom: -508px;
  }
  @media (min-width: 455px) and (max-width: 482px) {
    bottom: -526px;
  }
}

.btn-cross {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  background: transparent;
  &:hover {
    span {
      color: $orange;
    }
  }
  span {
    font-size: 14px;
    display: block;
  }
}