/********************************/


/******* STYLES BOOSTRAP *******/


/******************************/

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input[type="radio"],
input[type="checkbox"] {
    margin-left: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

strong {
    font-weight: bold
}

em {
    font-style: italic;
}

a {
    text-decoration: none;
    color: #404040;
}

a:hover,
a:focus {
    text-decoration: none;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

.ie8 img {
    /*width: auto;  @bugfix for IE8 */
    height: auto;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    list-style: none outside none;
    margin-left: -5px;
    padding-left: 0;
}

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.cap {
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
    content: " ";
    display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.visually-hidden {
    border: 0;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.dib {
    display: inline-block;
}

.sr-only {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 0px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


/* RESPONSIVE
*********************************/

.img-responsive {
    display: block;
    height: auto;
    max-width: 100%;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container:before,
.container:after {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

.container:before,
.container:after {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
}

@media (min-width: 0px) and (max-width: 767px) {
    .container {
        width: 100%;
    }
}

@media (min-width: 1025px) and (max-width: 1499px) {
    .container {
        width: 90%;
    }
}

@media (min-width: 1500px) {
    .container {
        width: 1440px;
    }
}

.row {
    margin-right: -5px;
    margin-left: -5px;
}

.row:before,
.row:after {
    content: " ";
    display: table;
}

.row:after {
    clear: both;
}

.row:before,
.row:after {
    content: " ";
    display: table;
}

.row:after {
    clear: both;
}


/* SMARTPHONE
*********************************/

@media screen and (min-width: 0px) and (max-width: 767px) {
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: left;
    }
    .col-xs-12 {
        width: 100%;
    }
    .col-xs-11 {
        width: 91.66666666666666%;
    }
    .col-xs-10 {
        width: 83.33333333333334%;
    }
    .col-xs-9 {
        width: 75%;
    }
    .col-xs-8 {
        width: 66.66666666666666%;
    }
    .col-xs-7 {
        width: 58.333333333333336%;
    }
    .col-xs-6 {
        width: 50%;
    }
    .col-xs-5 {
        width: 41.66666666666667%;
    }
    .col-xs-4 {
        width: 33.33333333333333%;
    }
    .col-xs-3 {
        width: 25%;
    }
    .col-xs-2 {
        width: 16.666666666666664%;
    }
    .col-xs-1 {
        width: 8.333333333333332%;
    }
    .col-xs-pull-12 {
        right: 100%;
    }
    .col-xs-pull-11 {
        right: 91.66666666666666%;
    }
    .col-xs-pull-10 {
        right: 83.33333333333334%;
    }
    .col-xs-pull-9 {
        right: 75%;
    }
    .col-xs-pull-8 {
        right: 66.66666666666666%;
    }
    .col-xs-pull-7 {
        right: 58.333333333333336%;
    }
    .col-xs-pull-6 {
        right: 50%;
    }
    .col-xs-pull-5 {
        right: 41.66666666666667%;
    }
    .col-xs-pull-4 {
        right: 33.33333333333333%;
    }
    .col-xs-pull-3 {
        right: 25%;
    }
    .col-xs-pull-2 {
        right: 16.666666666666664%;
    }
    .col-xs-pull-1 {
        right: 8.333333333333332%;
    }
    .col-xs-pull-0 {
        right: 0;
    }
    .col-xs-push-12 {
        left: 100%;
    }
    .col-xs-push-11 {
        left: 91.66666666666666%;
    }
    .col-xs-push-10 {
        left: 83.33333333333334%;
    }
    .col-xs-push-9 {
        left: 75%;
    }
    .col-xs-push-8 {
        left: 66.66666666666666%;
    }
    .col-xs-push-7 {
        left: 58.333333333333336%;
    }
    .col-xs-push-6 {
        left: 50%;
    }
    .col-xs-push-5 {
        left: 41.66666666666667%;
    }
    .col-xs-push-4 {
        left: 33.33333333333333%;
    }
    .col-xs-push-3 {
        left: 25%;
    }
    .col-xs-push-2 {
        left: 16.666666666666664%;
    }
    .col-xs-push-1 {
        left: 8.333333333333332%;
    }
    .col-xs-push-0 {
        left: 0;
    }
    .col-xs-offset-12 {
        margin-left: 100%;
    }
    .col-xs-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-xs-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-xs-offset-9 {
        margin-left: 75%;
    }
    .col-xs-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-xs-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-xs-offset-6 {
        margin-left: 50%;
    }
    .col-xs-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-xs-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-xs-offset-3 {
        margin-left: 25%;
    }
    .col-xs-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-xs-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-xs-offset-0 {
        margin-left: 0;
    }
}


/* TABLETTE
*********************************/

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-11 {
        width: 91.66666666666666%;
    }
    .col-sm-10 {
        width: 83.33333333333334%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-8 {
        width: 66.66666666666666%;
    }
    .col-sm-7 {
        width: 58.333333333333336%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-5 {
        width: 41.66666666666667%;
    }
    .col-sm-4 {
        width: 33.33333333333333%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-2 {
        width: 16.666666666666664%;
    }
    .col-sm-1 {
        width: 8.333333333333332%;
    }
    .col-sm-pull-12 {
        right: 100%;
    }
    .col-sm-pull-11 {
        right: 91.66666666666666%;
    }
    .col-sm-pull-10 {
        right: 83.33333333333334%;
    }
    .col-sm-pull-9 {
        right: 75%;
    }
    .col-sm-pull-8 {
        right: 66.66666666666666%;
    }
    .col-sm-pull-7 {
        right: 58.333333333333336%;
    }
    .col-sm-pull-6 {
        right: 50%;
    }
    .col-sm-pull-5 {
        right: 41.66666666666667%;
    }
    .col-sm-pull-4 {
        right: 33.33333333333333%;
    }
    .col-sm-pull-3 {
        right: 25%;
    }
    .col-sm-pull-2 {
        right: 16.666666666666664%;
    }
    .col-sm-pull-1 {
        right: 8.333333333333332%;
    }
    .col-sm-pull-0 {
        right: 0;
    }
    .col-sm-push-12 {
        left: 100%;
    }
    .col-sm-push-11 {
        left: 91.66666666666666%;
    }
    .col-sm-push-10 {
        left: 83.33333333333334%;
    }
    .col-sm-push-9 {
        left: 75%;
    }
    .col-sm-push-8 {
        left: 66.66666666666666%;
    }
    .col-sm-push-7 {
        left: 58.333333333333336%;
    }
    .col-sm-push-6 {
        left: 50%;
    }
    .col-sm-push-5 {
        left: 41.66666666666667%;
    }
    .col-sm-push-4 {
        left: 33.33333333333333%;
    }
    .col-sm-push-3 {
        left: 25%;
    }
    .col-sm-push-2 {
        left: 16.666666666666664%;
    }
    .col-sm-push-1 {
        left: 8.333333333333332%;
    }
    .col-sm-push-0 {
        left: 0;
    }
    .col-sm-offset-12 {
        margin-left: 100%;
    }
    .col-sm-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-sm-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-sm-offset-9 {
        margin-left: 75%;
    }
    .col-sm-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-sm-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-sm-offset-6 {
        margin-left: 50%;
    }
    .col-sm-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-sm-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-sm-offset-3 {
        margin-left: 25%;
    }
    .col-sm-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-sm-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-sm-offset-0 {
        margin-left: 0;
    }
}


/* DESKTOP
*********************************/

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-11 {
        width: 91.66666666666666%;
    }
    .col-md-10 {
        width: 83.33333333333334%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-8 {
        width: 66.66666666666666%;
    }
    .col-md-7 {
        width: 58.333333333333336%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-5 {
        width: 41.66666666666667%;
    }
    .col-md-4 {
        width: 33.33333333333333%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-2 {
        width: 16.666666666666664%;
    }
    .col-md-1 {
        width: 8.333333333333332%;
    }
    .col-md-pull-12 {
        right: 100%;
    }
    .col-md-pull-11 {
        right: 91.66666666666666%;
    }
    .col-md-pull-10 {
        right: 83.33333333333334%;
    }
    .col-md-pull-9 {
        right: 75%;
    }
    .col-md-pull-8 {
        right: 66.66666666666666%;
    }
    .col-md-pull-7 {
        right: 58.333333333333336%;
    }
    .col-md-pull-6 {
        right: 50%;
    }
    .col-md-pull-5 {
        right: 41.66666666666667%;
    }
    .col-md-pull-4 {
        right: 33.33333333333333%;
    }
    .col-md-pull-3 {
        right: 25%;
    }
    .col-md-pull-2 {
        right: 16.666666666666664%;
    }
    .col-md-pull-1 {
        right: 8.333333333333332%;
    }
    .col-md-pull-0 {
        right: 0;
    }
    .col-md-push-12 {
        left: 100%;
    }
    .col-md-push-11 {
        left: 91.66666666666666%;
    }
    .col-md-push-10 {
        left: 83.33333333333334%;
    }
    .col-md-push-9 {
        left: 75%;
    }
    .col-md-push-8 {
        left: 66.66666666666666%;
    }
    .col-md-push-7 {
        left: 58.333333333333336%;
    }
    .col-md-push-6 {
        left: 50%;
    }
    .col-md-push-5 {
        left: 41.66666666666667%;
    }
    .col-md-push-4 {
        left: 33.33333333333333%;
    }
    .col-md-push-3 {
        left: 25%;
    }
    .col-md-push-2 {
        left: 16.666666666666664%;
    }
    .col-md-push-1 {
        left: 8.333333333333332%;
    }
    .col-md-push-0 {
        left: 0;
    }
    .col-md-offset-12 {
        margin-left: 100%;
    }
    .col-md-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-md-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-md-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-md-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-md-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
}

@media screen and (min-width: 1280px) {
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        float: left;
    }
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-11 {
        width: 91.66666666666666%;
    }
    .col-lg-10 {
        width: 83.33333333333334%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-8 {
        width: 66.66666666666666%;
    }
    .col-lg-7 {
        width: 58.333333333333336%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-5 {
        width: 41.66666666666667%;
    }
    .col-lg-4 {
        width: 33.33333333333333%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-2 {
        width: 16.666666666666664%;
    }
    .col-lg-1 {
        width: 8.333333333333332%;
    }
    .col-lg-pull-12 {
        right: 100%;
    }
    .col-lg-pull-11 {
        right: 91.66666666666666%;
    }
    .col-lg-pull-10 {
        right: 83.33333333333334%;
    }
    .col-lg-pull-9 {
        right: 75%;
    }
    .col-lg-pull-8 {
        right: 66.66666666666666%;
    }
    .col-lg-pull-7 {
        right: 58.333333333333336%;
    }
    .col-lg-pull-6 {
        right: 50%;
    }
    .col-lg-pull-5 {
        right: 41.66666666666667%;
    }
    .col-lg-pull-4 {
        right: 33.33333333333333%;
    }
    .col-lg-pull-3 {
        right: 25%;
    }
    .col-lg-pull-2 {
        right: 16.666666666666664%;
    }
    .col-lg-pull-1 {
        right: 8.333333333333332%;
    }
    .col-lg-pull-0 {
        right: 0;
    }
    .col-lg-push-12 {
        left: 100%;
    }
    .col-lg-push-11 {
        left: 91.66666666666666%;
    }
    .col-lg-push-10 {
        left: 83.33333333333334%;
    }
    .col-lg-push-9 {
        left: 75%;
    }
    .col-lg-push-8 {
        left: 66.66666666666666%;
    }
    .col-lg-push-7 {
        left: 58.333333333333336%;
    }
    .col-lg-push-6 {
        left: 50%;
    }
    .col-lg-push-5 {
        left: 41.66666666666667%;
    }
    .col-lg-push-4 {
        left: 33.33333333333333%;
    }
    .col-lg-push-3 {
        left: 25%;
    }
    .col-lg-push-2 {
        left: 16.666666666666664%;
    }
    .col-lg-push-1 {
        left: 8.333333333333332%;
    }
    .col-lg-push-0 {
        left: 0;
    }
    .col-lg-offset-12 {
        margin-left: 100%;
    }
    .col-lg-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-lg-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-lg-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-lg-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-lg-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
}


/*NAVBAR*/

.navbar-collapse {
    overflow-x: visible;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch;
}

.navbar-collapse:before,
.navbar-collapse:after {
    display: table;
    content: " ";
}

.navbar-collapse:after {
    clear: both;
}

.navbar-collapse:before,
.navbar-collapse:after {
    display: table;
    content: " ";
}

.navbar-collapse:after {
    clear: both;
}

.navbar-collapse.in {
    overflow-y: auto;
}

.navbar-toggle {
    position: relative;
    padding: 0 10px;
    margin-top: 0;
    /*margin-right: 15px;
    margin-bottom: 8px;*/
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #FFF;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}

@media (min-width: 1023px) {
    .navbar-toggle {
        display: none;
    }
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
    visibility: visible;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -moz-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    -moz-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    transition-timing-function: ease;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table;
    }
    tr.visible-xs {
        display: table-row !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1280px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 1280px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 1280px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1280px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1366px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1366px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1366px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1280px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}


/* NAVBAR
*********************************/

.navbar {
    position: relative;
    min-height: 100px;
    /*margin-bottom: 20px;*/
    border: 1px solid transparent;
}

@media (min-width: 767px) {
    .navbar-header {
        float: left;
    }
}

.navbar-collapse {
    /*max-height: 340px;*/
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
}

.navbar-collapse.in {
    overflow-y: auto;
}

@media (min-width: 767px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }
    /*
    .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
}*/
    .navbar-collapse.in {
        overflow-y: visible;
    }
}

@media (min-width: 1023px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        visibility: visible !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-collapse.in {
        overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 767px) {
    .container>.navbar-header,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
}

.navbar-toggle {
    background-color: #04597a;
    border: none;
    border-radius: 0;
    padding: 13px 0 14px;
    position: relative;
    width: 100%;
    height: 50px;
}

.navbar-toggle:focus {
    outline: none;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 30px;
    height: 6px;
    border-radius: 1px;
    margin: 0 auto;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}

.navbar-nav {
    margin: 7.5px -15px;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

@media (min-width: 767px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }
    .navbar-nav>li {
        float: left;
    }
    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}


/*MODAL STYLE*/
.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    width: 100%;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:768px) {
    .modal-dialog {
        width: 840px;
        margin: 30px auto
    }
    .modal-dialog-centered {
        min-height: calc(100% - (2rem * 2));
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
    }
    .modal-sm {
        width: 300px
    }
}

@media (min-width:1024px) {
    .modal-lg {
        width: 900px
    }
}


/*TOOL TIP*/

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    filter: alpha(opacity=0);
    opacity: 0;
    line-break: auto;
}

.tooltip.in {
    filter: alpha(opacity=90);
    opacity: .9;
}

.tooltip.top {
    padding: 5px 0;
    margin-top: -3px;
}

.tooltip.right {
    padding: 0 5px;
    margin-left: 3px;
}

.tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px;
}

.tooltip.left {
    padding: 0 5px;
    margin-left: -3px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #0cafc9;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #0cafc9;
}

.tooltip.top-left .tooltip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #0cafc9;
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #0cafc9;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #0cafc9;
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #0cafc9;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #0cafc9;
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #0cafc9;
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #0cafc9;
}
