/* ==========================================================================
   Page / Créer un compte
   ========================================================================== */

  .container-create {
    padding-top: 100px;

    @include breakpoint(tablethor) {
      padding-top: 180px;
    }

    .createform {
      margin-top: 40px;
      padding: 0 15px;

      @include breakpoint(tablethor) {
        margin-top: 70px;
      }

      .h1-title-uppercase {
        margin-bottom: 30px;

        @include breakpoint(phablet) {
          white-space: nowrap;
        }

        @include breakpoint(tablet) {
          white-space: inherit;
        }

        @include breakpoint(tablethor) {
          margin-bottom: 50px;
        }

        @include breakpoint(laptop) {
          white-space: nowrap;
        }
      }

      .alert-js {
        font-weight: 600;
        color: $red;
        line-height: 1.5;
      }

      .form {
        width: 100%;

        @include breakpoint(phablet) {
          max-width: 485px;
          margin: auto;
        }

        @include breakpoint(tablet) {
          max-width: 415px;
          margin: 0;
        }

        .inactive {
          .h1-title-uppercase { opacity: 0.6; }
        }

        .btn {
          display: block;
          margin: 15px 0;
          width: 100%;

          @include breakpoint(phablet) {
            display: inline-block;
            margin: 0 12px 0 0;
            padding: 15px 53px;
            width: inherit;
          }
        }
      }

      .button-modif {
        display: none;
        margin-top: -20px;
        margin-bottom: 55px;
      }

      &-reinsurance {
        background: $light_grey;
        padding: 40px 15px 30px;
        margin: 30px auto 0;
        text-align: center;

        @include breakpoint(phablet) {
          max-width: 485px;
        }

        @include breakpoint(tablet) {
          max-width: 415px;
          margin: 0 auto;
          padding: 45px 45px 30px;
          position: relative;
          left: 15px;
        }

        ul {
          margin-top: 30px;

          li {
            display: block;
            margin-bottom: 35px;

            img {
              margin-bottom: 20px;
            }

            p {
              font-size: 16px;
              letter-spacing: 0.02rem;
              text-align: center;
              width: 80%;
              margin: 30px auto 0;

              @include breakpoint(tablet) {
                width: 60%;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }