/* ==========================================================================
   Layout / Offres Axeo mises en avant
   ========================================================================== */

  .offers {
    padding-top: 60px;

    @include breakpoint(tablet) {
      padding-top: 85px;
    }

    @include breakpoint(laptop) {
      padding-top: 100px;
    }

    .col-xs-12.overflow {
      overflow: hidden;
    }

    .h1-title-uppercase {
      @extend .h1-title-uppercase;
      position: relative;
      margin-bottom: 35px;
      margin-left: 10px;

      @include breakpoint(tablet) {
        margin-bottom: 45px;
      }

      @include breakpoint(tablethor) {
        margin-bottom: 65px;
      }
    }

    .univer {
      img{
        @media(max-width: 1023px){
          width: 100%;
          height: auto;
        }
      }

      .h2 {
        font-family: $special-font-family;
        font-size: 12px;
        font-weight: 700;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.45);
        letter-spacing: 0.2em;
        text-transform: uppercase;
        position: relative;
        line-height: 20px;
        padding-bottom: 2px;
        margin-bottom: 15px;
        display: inline-block;
        width: auto;

        &:after{
          content: "";
          height: 2px;
          width: 80%;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &.univer-maintain-of-the-house{
        .h2 {
          &:after {
            background: $orange;
          }
        }
      }
      &.univer-exterior-gardens{
        .h2 {
          &:after {
            background: $green;
          }
        }
      }
      &.univer-housing{
        .h2 {
          &:after {
            background: $brown;
          }
        }
      }
      &.univer-child-care{
        .h2 {
          &:after {
            background: $pink;
          }
        }
      }
      &.univer-car-wash{
        .h2 {
          &:after {
            background: $purple;
          }
        }
      }

      .h3 {
        font-size: 26px;
        font-family: $special-font-family;
        line-height: 30px;
        margin-bottom: 5px;
      }
    }
  }