/* ==========================================================================
   Page / Fiche Produit
   ========================================================================== */

  .product {
    margin-top: 100px;
    padding: 0 5px;

    @include breakpoint(tablethor) {
      margin-top: 175px;
    }

    .breadcrumb {
      margin-bottom: 60px;
    }

    /* CHOIX DES OFFRES */
      .container-formulas {
        margin-bottom: 50px;

        @include breakpoint(laptop) {
          margin-bottom: 105px;
        }

        .row{
          width: 100%;
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: center;

          @media(max-width:767px){
            display: block;
          }
        }
      }

      .container-discover-formulas {
        position: relative;
        height: auto;
        padding-top: 5%;
        padding-bottom: 5%;

        @include breakpoint(1280px) {
          height: 100%;
        }
        @media (min-width: 426px) and (max-width: 1279px) {
          margin-left: calc((100% - 375.3px)/2);
        }

        p{
          font-size: 26px;
          font-family: $special-font-family;
        }
        &:after{
          content: "";
          position: absolute;
          top: 50%;
          left: 60%;
          transform: translate(-50%, -50%);
          width: 250px;
          height: 250px;
          background: url('./src/img/ombre-container-formules.svg') center center / 250px 250px no-repeat;

          @media (max-width: 1279px) {
            display: none;
          }
        }

        .ct-text{
          display: inline-block;
          vertical-align: middle;
          position: relative;
          z-index: 3;
          top: 25%;

          @include breakpoint(1280px){
            transform: translate(-50%, -50%);
            left: 5%;
          }
          @include breakpoint(phablet) {
            top: 50%;
          }

          @include breakpoint(1280px) {
            left: 40%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              top: 17rem;
            }
          }
          p, .anchor, .product{
            @media (max-width: 1279px) {
              display: none;
            }
          }
        }
      }

      .container-formula {
        padding: 30px;
        text-align: center;
        transition: all $duration $easeOutCubic;
        border-radius: 3px;
        position: relative;
        //height: 100%;
        z-index: 0;
        width: 32%;
        margin-right: 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1279px){
          margin-top: 30px;
          margin-right: 1%;
          width: 48.5%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }

        &:hover {
          transform: scale(1.05);
          transition: all $duration $easeOutCubic;
          //z-index: 1;
        }
        .block-title-formula{
          height: 100px;
          margin-bottom: 10%;
        }

        .btn-main-white {
          margin: 10px auto;
          display: table;
        }

        .btn-white:hover{
            background: darken($orange, 5%);
        }

        & {
          color: #fff;
        }
        &.punctual{
          background: $bg-grey;
          &-solo{
            width: calc(100% - (100%/3));
            margin-right: 0;
            @media (max-width: 1279px){
              width: 100%;
              max-width: 700px;
            }
          }
        }
        &.regular{
          background: rgb(242,134,0);
          background: -moz-linear-gradient(-45deg, rgba(242,134,0,1) 0%, rgba(248,170,0,1) 100%);
          background: -webkit-linear-gradient(-45deg, rgba(242,134,0,1) 0%,rgba(248,170,0,1) 100%);
          background: linear-gradient(135deg, rgba(242,134,0,1) 0%,rgba(248,170,0,1) 100%);
        }
        .save{
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translateX(-50%);
          background: #fff;
          color: $orange;
          text-transform: uppercase;
          padding: 7px 13px;
          border: 1px solid $orange;
          font-size: 11px;
          font-weight: 700;
        }
        .title{
          font-size: 21px;
          text-transform: uppercase;
          margin-bottom: 1px;
          font-weight: 800;
        }
        .regularity{
          font-size: 17px;
          font-weight: 600;
          font-style: italic;
        }
        .see{
          display: block;
          font-weight: 800;
          margin-bottom: 12px;
        }

        .formula-list{
          display: inline-block;
          margin-bottom: 36px;
          margin-top: 20px;
          li{
            text-align: left;
            font-size: 15px;
            margin-bottom: 3px;

            &:before{
              content: "\e92a";
              display: inline-block;
              color: #fff;
              margin-right: 10px;
              font-family: 'icomoon' !important;
            }
          }
        }
        .tax{
          margin-bottom: 47px;
          font-size: 15px;
        }
        .asterisk{
          font-size: 12px;
        }
        .price{
          font-weight: 800;
          margin-bottom: 10px;
          .number{
            font-size: 55px;
            line-height: 55px;
          }
          .euro{
            display: inline-block;
            vertical-align: top;
            font-size: 25px;
            position: relative;
          }
          .unity{
            font-size: 28px;
          }
        }
        .from{
          font-weight: 800;
          font-size: 18px;
          margin-top: 30px;
        }
      }

    /* DESCRIPTION DU PRODUIT */

      &-infos {
        padding: 0 10px;
        margin-bottom: 55px;

        @include breakpoint(tablet) {
          padding: 0 35px;
        }

        @include breakpoint(tablethor) {
          padding: 0 15px;
        }

        @include breakpoint(laptop) {
          width: 90%;
          padding: 0 5px;
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          color: $text-color;

          margin-bottom: 35px;

          @include breakpoint(laptop) {
            width: 96%;
          }

          b {
            font-weight: 700;
          }
        }

        .contentext ul {
          li {
            display: block;
            //color: $orange_dark;
            font-size: 16px;
            padding-left: 25px;
            margin-bottom: 8px;
            position: relative;

            &:before {
              content: ' ';
              display: inline-block;
              position: absolute;
              top: 8px;
              left: 0;
              width: 6px;
              height: 6px;
              border-radius: 2px;
              background-clip: padding-box;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              background: $orange;
              margin-right: 5px;
            }
          }
        }

        .container-reinsurance-list {
          margin-top: 50px;
          margin-bottom: 15px;

          @include breakpoint(tablet) {
            margin-top: 65px;
            margin-bottom: 60px;
          }

          .reinsurance-item {
            @include breakpoint(765px) {
              text-align: left;

              &:first-child {
                padding-left: 15px;
              }

              &:nth-child(2) {
                width: calc(36% - 13px);
              }

              &:last-child {
                padding-right: 5px;
              }
            }

            & + .reinsurance-item:after {
              top: 35px;
              left: 2px;
            }

            p {
              margin: 0;
              width: 100%;
              font-size: 15px;
              line-height: 1.2;
              color: #5e5e5e;
            }
          }
        }
      }

      &-advice {
        margin-bottom: 5px;

        a {
          color: $orange;
          font-size: 15px;
          font-weight: 600;
          transition: all $durationSmall $easeInOutQuart;
          position: relative;
          bottom: 25px;

          @include breakpoint(phablet) {
            bottom: 30px;
          }

          &:hover {
            transition: all $durationSmall $easeInOutQuart;

            &:after {
              left: 5px;
              transition: all $durationSmall $easeInOutQuart;
            }
          }

          &:after {
            content: "\E91D";
            display: inline-block;
            color: $orange;
            font-family: 'icomoon' !important;
            font-size: 40px;
            position: relative;
            top: 13px;
            left: 0;
            transition: all $durationSmall $easeInOutQuart;
          }
        }
      }

      &-offer {
        .h3-title {
          margin-bottom: 25px;

          @include breakpoint(tablet) {
            margin-bottom: 45px;
          }
        }

        .blockname {
          font-size: 21px;
          line-height: 1;
          font-weight: 800;
          color: $text-color;
          margin-bottom: 35px;
        }

        .details {

          font-size: 14px;
          text-align: left;

          ul > li {
            padding-left: 30px;
            margin-bottom: 17px;
            position: relative;

            &:last-child {
              margin-bottom: 28px;
            }

            &:before {
              content: ' ';
              display: block;
              width: 9px;
              height:9px;
              background: url('./src/img/puce-orange.svg') left center / 9px 9px no-repeat;
              position: absolute;
              top: 4px;
              left: 0;
            }

            b {
              display: inline-block;
              font-weight: 700;
            }
          }

          ul + b {
            display: block;
            font-weight: 700;
          }
        }
      }

      &-include {
        display: block;
        width: 100%;
        padding-left: 10px;
        margin-bottom: 45px;

        @include breakpoint(phablet) {
          padding-left: 110px;
          background: url('./src/img/icone-inclus.svg') left center / 77px 77px no-repeat;
        }

        @include breakpoint(tablet) {
          padding-left: 140px;
          margin-bottom: 95px;
        }
      }

      &-notinclude {
        display: block;
        width: 100%;
        padding-left: 10px;
        margin-bottom: 35px;

        @include breakpoint(phablet) {
          padding-left: 110px;
          background: url('./src/img/icone-noninclus.svg') left center / 77px 77px no-repeat;
        }

        @include breakpoint(tablet) {
          padding-left: 140px;
          margin-bottom: 75px;
        }

        .details ul > li {
          &:before {
            background: url('./src/img/puce-grise.svg') left center / 9px 9px no-repeat;
          }
        }
      }

      &-tabs {
        margin-bottom: 15px;

        @include breakpoint(laptop) {
          width: 95%;
          padding: 0 5px;
          margin-bottom: 55px;
        }

        .buttons {
          display: block;

          a {
            display: inline-block;
            color: $text-color;

            text-transform: uppercase;
            font-size: 24px;
            padding: 10px 28px;
            transition: all $duration $easeOutCubic;

            &:hover {
              color: $orange;
              background: lighten($light_grey, 5%);
              transition: all $duration $easeOutCubic;
            }

            &.current {
              color: $orange;
              background: lighten($light_grey, 2%);
            }
          }
        }

        .content {
          &-tab {
            display: none;
            opacity: 0;
            width: 100%;
            background: lighten($light_grey, 2%);
            padding: 45px 30px;
            transition: all $durationLong $easeOutCubic;

            @include breakpoint(phablet) {
              padding: 65px 45px;
            }

            &.current {
              display: block;
              opacity: 1.0;
              transition: all $durationLong $easeOutCubic;
            }
          }

          .avis {
            @include breakpoint(phablet) {
              padding: 65px 60px;
            }

            @include breakpoint(tablet) {
              padding: 65px 105px;
            }

            .product-advice {
              margin: 0;
            }

            &-stats {
              margin-top: 18px;

              ul > li {
                display: block;
                margin-bottom: 15px;

                .label {
                  display: block;
                  font-size: 15px;


                  @include breakpoint(tablet) {
                    display: inline-block;
                    width: 75px;
                  }
                }

                .state {
                  display: inline-block;
                  max-width: 365px;
                  width: 75%;
                  height: 10px;
                  background: #818181;
                  border-radius: 15px;
                  position: relative;

                  @include breakpoint(phablet) {
                    width: 80%;
                  }

                  span {
                    display: block;
                    height: 10px;
                    padding: 0 1%;
                    background: $orange;
                    border-radius: 15px;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }

                .percent {
                  display: inline-block;
                  width: 55px;
                  text-align: right;
                  font-size: 15px;

                }
              }
            }
          }

          .faq {
            color: #5e5e5e;

            .h4-title {
              margin-bottom: 20px;
              color: #5e5e5e;
            }

            p {
              color: #5e5e5e;
              font-size: 15px;

              line-height: 1.3;
              margin-bottom: 15px;

              & + .h4-title {
                margin-top: 30px;
              }
            }

            ul {
              margin-bottom: 15px;

              li {
                display: block;
                font-weight: 700;
                color: $orange_dark;
                font-size: 15px;
                padding-left: 25px;
                margin-bottom: 6px;
                position: relative;

                &:before {
                  content: ' ';
                  display: inline-block;
                  position: absolute;
                  top: 8px;
                  left: 0;
                  width: 6px;
                  height: 6px;
                  border-radius: 2px;
                  background-clip: padding-box;
                  -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
                  background: $orange;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      .container-offers {
        background: $light_grey;
        padding: 40px 0 30px;
        margin: 50px 0 30px;

        @include breakpoint(laptop) {
          padding: 75px 0 90px;
        }

        .title-offers {
          margin-bottom: 35px;
        }
      }

      .modal{
      .input-ct{
        background: #f4f4f4;
        padding: 10px;
        input{
          background: transparent;
          border: 0;
        }
      }
      .icon-cluster{
        color: $orange;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .container-sticked-content .aside{
      width: inherit;
      max-width: inherit;
      z-index: inherit;
      position: inherit;
      transform: inherit;
      transition: inherit;
    }
  }

  /* AUTRES OFFRES */
  .discoverOffers {
    background: $light_grey;
    padding: 75px 0 65px;
    position: relative;
    top: 80px;

    .h1-title-uppercase {
      margin-left: 5px;
      margin-bottom: 20px;

      @include breakpoint(tablethor) {
        margin-bottom: 15px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 45px;
      }
    }
  }