/* ==========================================================================
   Components / Agency & Tchat Bloc
   ========================================================================== */

  .agenciesTchatBlock {
    //max-width: 400px;
    //width: 83%;
    //z-index: 26;
    //top: 75px;
    //height: auto;
    //right: 0;
    //position: fixed;
    //transition: all 0.5s ease;
    //transform: translate(100%, 0%);
    display: block;
    width: auto;
    max-width: inherit;
    top: inherit;
    position: inherit;
    transform: translateX(0px);
    z-index: 0;

    @include breakpoint(tablet) {
      top: 90px;
    }

    @include breakpoint(tablethor) {
      display: block;
      width: auto;
      max-width: inherit;
      top: inherit;
      position: inherit;
      transform: translateX(0px);
      z-index: 0;
    }

    &.open {
      transition: all 0.5s ease;
      transform: translate(0%, 0%);
    }

    .cntresp {
      max-height: 90vh;
      height: 100%;
      overflow-y: auto;

      @include breakpoint(phablet) {
        max-height: 80vh;
      }

      @include breakpoint(tablet) {
        max-height: 75vh;
      }

      @include breakpoint(tablethor) {
        max-height: inherit;
        overflow: inherit;
      }
    }

    .h3-title {
      @include breakpoint(smallMobile) {
        font-size: 20px;
      }
    }
  }

  .agencies, .activities {
    box-shadow: 5px 3px 13px 0 rgba(67, 67, 67, 0.2);
    background: $light_grey;
    width: 100%;
    height: auto;
    padding: 25px;

    @include breakpoint(phablet) {
      padding: 30px 35px;
    }

    @include breakpoint(tablet) {
      width: auto;
      padding: 20px 45px;
    }

    @include breakpoint(tablethor) {
      margin-left: 20px;
      position: relative;
      box-shadow: 0 0 0 0 rgba(67, 67, 67, 0.2);
      left: 2.8rem;
    }

    @include breakpoint(1280px) {
      margin-left: 0;
      padding: 40px 45px;
      left: 6.2rem;
      width: 90%;
      height: 295px;
    }

    @include breakpoint(1500px) {
      left: 12rem;
      max-width: 435px;
    }

    @media(max-width: 1023px){
      width: 100%;
      height: auto !important;
      display: inline-block;
      vertical-align: top;
    }

    .icon-agence, .icon-conseiller{
      display: none;

      @include breakpoint(tablet) {
        display: inline-block;
      }
    }

    .label{
      font-size: 16px;
      font-weight: 700;
      color: $orange;
    }

    .form-line{
      margin-top: 20px;
      @media(max-width: 767px){
        margin-top: 10px;
      }
    }

    .ct-btn{
      @media(max-width: 767px){
        margin-top: 10px;
      }
    }

    .ct-input{
      background: #fff;
      height: 48px;
      line-height: 22px;
      margin-top: 10px;
      position: relative;

      @media(max-width: 767px){
        margin-top: 5px;
      }

      input{
        background: #fff;
        border: 0;
        font-size: 14px;
        width: calc(100% - 45px);
        padding: 0px 20px;
        line-height: 48px;
        height: 48px;
      }
    }

    .icon-agence,
    .icon-conseiller{
      font-size: 60px;
      position: relative;
      margin-top: 15px;
      .path2{
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .ct-text{
      display: block;
      width: 100%;
      padding-left: 0;
      margin-top: 15px;

      @include breakpoint(1280px) {
        width: calc(100% - 80px);
        display: inline-block;
        vertical-align: top;
        padding-left: 15px;
        margin-top: 15px;
      }

      @media(max-width: 767px){
        margin-top: 0;
      }

      p {
        font-size: 15px;
      }

      strong {
        display: block;
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    .btn-submit-arrow {
      position: absolute;
      top: 4px;
      height: 40px;
    }
  }

  .activities{
    margin-bottom: 15px;
    padding: 35px 25px;

    @include breakpoint(tablet) {
      padding: 20px 45px 45px;
      margin-bottom: 0;
    }

    .icon-conseiller{
      font-size: 48px;
    }

    @include breakpoint(1280px) {
      .ct-center{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ct-btn {
      margin-top: 20px;
    }

    .btn-contact:after {
      display: none;

      @include breakpoint(tablet) {
        display: inline-block;
      }
    }

    .btn-with-arrow:after {
      @include breakpoint(smallMobile) {
        display: none;
      }
    }
  }

  .agencies{
    margin-bottom: 7px;
    p{
      font-size: 13px;
      margin-bottom: 10px;
    }
    .contact-us{
      margin-top: 20px;
      @media(max-width: 767px){
        margin-top: 15px;
      }
      .txt{
        font-size: 14px;
        font-weight: 700;
      }
      .number{
        font-size: 15px;
        font-weight: 700;
        color: $orange;
      }
    }
  }

  /* BLOCK ON SIDEBAR */
  .aside {
    max-width: 365px;
    width: 83%;
    z-index: 26;
    top: 75px;
    height: auto;
    right: 0;
    position: fixed;
    transition: all 0.5s ease;
    transform: translate(100%, 0%);

    @include breakpoint(tablet) {
      top: 90px;
    }

    @include breakpoint(tablethor) {
      top: 165px;
    }

    @include breakpoint(laptop) {
      top: 205px;
    }

    @include breakpoint(1280px) {
      display: block;
      width: 100%;
      max-width: none;
      top: auto;
      position: static;
      transform: translateX(0px);
      z-index: 0;
    }

    @include breakpoint(1485px) {
      width: auto;
    }

    .cntresp {
      max-height: 90vh;
      height: 100%;
      overflow-y: auto;

      @include breakpoint(phablet) {
        max-height: 80vh;
      }

      @include breakpoint(tablet) {
        max-height: 75vh;
      }

      @include breakpoint(laptop) {
        max-height: 80vh;
      }

      @include breakpoint(1280px) {
        max-height: inherit;
        overflow: inherit;
      }
    }

    .agencies, .activities {
      margin-left: 0;
      left: 0!important;
      width: 100%;
      max-width: 100%;
      height: 260px;

      @include breakpoint(1280px) {
        padding: 30px 45px 65px;
      }

      .icon {
        @include breakpoint(tablet) {
          display: block;
        }
      }

      .ct-text {
        width: 100%;
        padding-left: 0;
      }
    }

    .agencies {
      height: auto;

      @include breakpoint(1280px) {
        padding: 30px;
      }

      .h3-title {
        margin-bottom: 35px;
      }

      .icon-agence .path2 {
        left: 55px;
        right: inherit;
      }
    }

    .activities {
      .icon-conseiller .path2 {
        left: 50px;
        right: inherit;
      }
    }
  }