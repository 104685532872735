/* ==========================================================================
   Page / Configurateur Produit
   ========================================================================== */

  .configurator {
    margin-top: 100px;
    padding: 0 5px;

    @include breakpoint(tablethor) {
      margin-top: 175px;
    }

    .breadcrumb {
      margin-bottom: 60px;
    }

    .h1-title {
      margin-bottom: 45px;
    }

  }

  .container-formule-description {
    p {
      font-size: 18px;
    }
  }

  .center-arrow{
    text-align: center;
  }

  .ct-form{
    padding: 22px;
    border: 1px solid #dadada;
    margin-bottom: 20px;

    .img-upload{
      position: absolute;

    }

    @include breakpoint(tablethor) {
      padding: 30px;
      padding-right: 80px;

    }

    .form-line{
      .label{
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        vertical-align: middle;

        @include breakpoint(phablet) {
          width: 305px;
        }

        @include breakpoint(tablethor) {
          width: 220px;
        }

        @include breakpoint(laptop) {
          width: 320px;
        }
      }

      &:last-child {
        .label {
          width: 100%;

          @include breakpoint(phablet) {
            width: 120px;
          }

          @include breakpoint(tablethor) {
            width: 100%;
            margin-bottom: 15px;
          }

          @include breakpoint(laptop) {
            width: 140px;
          }

          @include breakpoint(1440px) {
            width: 320px;
          }
        }

        .row-input {
          width: 100%;

          @include breakpoint(phablet) {
            width: calc(100% - 130px);
          }

          @include breakpoint(tablethor) {
            width: 100%;
          }

          @include breakpoint(laptop) {
            width: calc(100% - 190px);
          }

          @include breakpoint(1440px) {
            width: calc(100% - 335px);
          }
        }
      }
    }

    .row-input{
      width: 100%;
      display: inline-block;
      text-align: left;
      margin-top: 10px;

      @include breakpoint(phablet) {
        width: calc(100% - 310px);
        margin-top: 0;
      }

      @include breakpoint(tablethor) {
        width: calc(100% - 225px);
      }

      @include breakpoint(laptop) {
        width: calc(100% - 335px);
      }
    }

    input{
      height: 35px;
      font-size: 14px;
      font-weight: 700;
    }

    input,
    textarea{
      border: 1px solid #e2e2e2;
      border-radius: 3px;
      text-align: center;
    }

    textarea{
      resize: none;
      text-align: left;

      @include placeholder{
        font-weight: 100;
        font-size: 14px;
        color: #3d3d3d;
        font-style: italic;
      }
    }

    .unity-cube{
      font-size: 14px;
      span{
        display: inline-block;
        vertical-align: top;
        font-size: 7px;
        position: relative;
        top: 4px;
      }
    }

    .comments{
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 15px;
      padding: 10px 25px 10px 10px;
      height: 60px;

      @include breakpoint(phablet) {
        width: 83%;
        margin-bottom: 0;
      }

      @include breakpoint(tablet) {
        width: calc(100% - 80px);
      }
    }

    .btn-camera{
      display: inline-block;
      vertical-align: middle;

      @include breakpoint(phablet) {
        margin-left: 10px;
      }

      @include breakpoint(tablet) {
        margin-left: 30px;
      }
    }

    .input-val{
      display: none;
      vertical-align: middle;
      margin-top: 10px;
      font-size: 13px;
      font-weight: 400;
      position: absolute;

      b {
        font-size: 13px;
        font-weight: 600;
      }

      &.show {
        display: inline-block;
      }
    }
  }

  .ct-additional-needs {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 0;

    @include breakpoint(phablet) {
      text-align: left;
      position: relative;
      //right: 10px;
    }

    .ct-check{
      background: transparent;
      display: inline-block;
      vertical-align: middle;
      width: 95px;
      border: 0;
      padding: 20px 0 0;
      cursor: pointer;

      label {
        font-size: 14px;
        font-weight: 600;
        color: $orange;
        cursor: pointer;
      }

      &.active{
        border: 0;
        .icon{
          &:after{
            background: $orange;
          }
          &:before{
            color: #fff;
          }
        }
      }

      .icon{
        position: relative;
        &:after{
          transition: all 0.2s ease;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-radius: 7px;
          border: 2px solid $orange;
          width: 47px;
          height: 47px;
          display: block;
          z-index: 1;
        }
        &:before{
          color: $orange;
          font-size: 25px;
          z-index: 2;
          position: relative;
        }
      }
    }
}

  .step-container-caracteristics{
    .btn{
      margin-bottom: 130px;
    }
    .h3-title{
      margin-top: 50px;
    }
  }
  .step-container-informations{
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  .container-configurator {
    margin-top: 20px;
    .row{
      display: inline-flex;
      width: 100%;
      @media(max-width: 1023px){
        display: block;
      }
    }
    .row-input{
      margin-bottom: 14px;
      .btn-less,
      .btn-more{
        width: 28px;
        height: 27px;
      }
    }
    #bedrooms,
    #bathrooms{
      width: 60px;
    }
    #area{
      width: 100px;
    }
    .center-arrow{
      height: 100%;
      .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .container-informations{
    margin-top: 30px;
  }

  .container-additional-needs{
    margin-bottom: 35px;

    @media(max-width: 1023px){
      margin-bottom: 50px;
    }

    @include breakpoint(phablet) {
      padding: 0 15px;
    }

    .h4-title {
      text-align: center;

      @include breakpoint(phablet) {
        text-align: left;
      }
    }
    .form-line{
      display: flex;
      label{
        width: 140px;
        @include breakpoint(350px){
          width: 55%;
        }
        @include breakpoint(smallMobile){
          width: 130px;
        }
      }
      .form-common{
        .select-content{
          width: auto;
        }
        .civilite-option{
          @include breakpoint(mobileonly){
            margin-right: 5px;
          }
        }
      }
    }
  }

  .container-calendars-presta{
    margin-bottom: 130px;
    @media(max-width: 1023px){
      margin-bottom: 50px;
    }

    select{
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      background: url(/fonts/icone-fleche-bas-orange.svg) right 10px center/13px 7px no-repeat;
      padding-right: 25px;
      padding-left: 10px;
    }

    .select-content{
      height: 35px;
      line-height: 35px;
    }

    .input-checkbox{
      margin: 20px 0px;

      input{
        height: auto;
        position: absolute;
        left: -999px;
        width: auto;
        &:checked{
          & + label{
            &:after{
              display: block;
            }
          }
        }
      }
      .label{
        top: auto;
        left: auto;
        font-size: 16px;
        color: $dark_grey;
        position: relative;
        text-transform: none;
        padding-left: 40px;
        cursor: pointer;
        display: block;
        position: relative;
        &:before,
        &:after{
          content: '';
          position: absolute;
          top: -1px;
          left: 4px;
        }
        &:before{
          display: block;
          width: 23px;
          height: 23px;
          border: 1px solid #9e9e9c;
          border-radius: 3px;
        }
        &:after{
          width: 34px;
          height: 30px;
          margin: 4px;
          background: url('./src/img/icone-coche.svg') center center/ 34px 30px no-repeat;
          display: none;
          top: -13px;
          left: 0;
        }
      }

      span {
        font-size: 12px;
        user-select : none;
      }
    }

    p{
      font-weight: normal;
      font-size: 14px;
      line-height: 1.5;

      a{
        text-decoration: underline;
        font-weight: 800;
        &:hover{
          text-decoration: none;
        }
      }
    }

    .bold,
    .title-choice{
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .bold{
      margin-bottom: 0;
      margin-top: 15px;
    }

    .ct-choice{
      overflow: hidden;
      padding: 50px 20px 30px;
      background: $light_grey;
      position: relative;

      @include breakpoint(tablet) {
        padding: 40px 25px 19px;
      }

      .btn-bin, .btn-bin-inte{
        position: absolute;
        top: 0px;
        right: 0px;
        margin-top: 0;
      }
    }

    .ct-calendar,
    .ct-from,
    .ct-to{
      margin-right: 2%;
      margin-left: 10px;

      @include breakpoint(phablet) {
        margin-left: 0;
      }

      .form-line{
        display: inline-block;
        vertical-align: middle;
      }

      &{
        display: block;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .ct-select-box{
      display: inline-block;
      vertical-align: middle;
    }

    .txt,
    .hours,
    .minutes{
      display: inline-block;
      vertical-align: middle;
      min-width: 30px;

      @media(max-width:767px){
        margin-right: 10px;
      }
    }
    .txt{
      @media (max-width: 767px) {
        display: contents;
      }
    }

    .ct-calendar{
      input{
        border: 1px solid #9c9b9a;
        border-radius: 5px;
        background: transparent;
        padding-right: 30px;
        padding-left: 10px;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        color: $text-color;
        @include placeholder{
          font-size: 14px;
          color: $text-color;
        }
      }
      .Zebra_DatePicker_Icon{
        background: url(./src/img/icone-calendrier.svg) center center/18px 18px no-repeat;
        width: 18px;
        height: 18px;
        top: 4px !important;
        right: 10px !important;
      }
      .date-picker{
        padding-right: 0 !important;
      }
    }

    .public-holiday{
      font-size: 12px;
      font-weight: 700;
      margin-top: 40px;
      text-align: center;

      @include breakpoint(tablet) {
        text-align: left;
        margin-top: 20px;
      }

      .txt {
        font-size: 13px;
        margin-left: 15px;
        width: calc(100% - 30px);
      }
      .orange{
        color: $orange;
      }
    }

    .intervention{
      .input-checkbox{
        margin-bottom: 0;
      }

      .input-checkbox .label:after {
        background: url('./src/img/icone-coche-bgris.svg') center center/34px 30px no-repeat!important;
      }

      .label{
        font-size: 12px;
        font-weight: 800;

        span{
          display: inline-block;
          vertical-align: middle;
          line-height: 19px;
          position: relative;
          bottom: 1px;
          margin-left: 5px;
        }
      }
    }

    .choice-list {
      margin-top: 25px;
    }

    .container-choice{
      display: none;
      margin-bottom: 30px;

      &.showed {
        display: block;
      }
    }

    .btn{
      margin-top: 50px;
      @media(max-width:767px){
        margin-top: 10px;
      }
    }
    .widthBoutonAddMobile{
      @media (min-width: 450px) and (max-width: 767px){
        width: calc(100% - 100%/3);
      }
    }
    .widthBoutonValidateMobile{
      margin-left: inherit;
    }
  }
  .widthBoutonValidateMobile{
    @media (min-width: 450px) and (max-width: 767px){
      width: calc(100%/3);
      margin-left: calc(100% - 100%/3);
    }
  }
  .width-btn-basket-mobile{
    position: absolute;
    @media (min-width: 450px) and (max-width: 767px) {
      width: auto;
      margin-left: calc(100% - 270px);
    }
    @media (max-width: 325px) {
      bottom: -685px;
    }
    @media (min-width: 356px) and (max-width: 346px) {
      bottom: -665px;
    }
    @media (min-width: 347px) and (max-width: 454px) {
      bottom: -640px;
    }
    @media (min-width: 455px) and (max-width: 505px) {
      bottom: -625px;
    }
    @media (min-width: 505px) and (max-width: 767px){
      bottom: -605px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      bottom: -625px;
      width: 36%;
      margin-left: 64%;
    }
    @include breakpoint(tablethor){
      position: inherit;
    }
  }
  .container-further-information{
    margin-bottom: 30px;

    .h3-title{
      margin-bottom: 20px;
    }
    .h4-title{
      font-size: 21px;
      font-weight: 800;
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        margin-bottom: 30px;
      }
    }

    .ct-check {
      @media(max-width: 1279px) {
        margin: 0 0 10px;
      }

      label {
        color: $text-color;
        font-weight: 400;
        cursor: pointer;
      }
    }

    .btn-add-to-basket{
      display: block;
      margin: 0px auto;
      text-align: center;
    }
    .withForMobile{
      @media (min-width: 500px) and (max-width: 767px){
        width: 50%;
      }
    }
  }

  .container-detail-benefit{
    margin-bottom: 10px;
    border-radius: 3px;
    color: #fff;
    background: rgb(242,133,0);
    background: -moz-linear-gradient(-45deg, rgba(242,133,0,1) 0%, rgba(248,170,0,1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
    background: linear-gradient(135deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);

    @include breakpoint(1280px) {
      width: 100%;
    }

    @include breakpoint(1485px) {
      width: auto;
    }

    @media(max-width: 1023px){
      margin-bottom: 30px;
      margin-top: 20px;
    }

    .ct-head{
      border-bottom: 3px solid #fff;
      text-align: center;
      padding: 20px 0px;
    }
    .ct-footer{
      padding: 25px;
      text-align: center;
      .btn{
        display: inline-block;
        vertical-align: middle;
      }
    }
    .detail{
      display: inline-block;
      width: 100%;
      padding: 0px 10px;
      white-space: nowrap;
      .txt,
      .icon{
        display: inline-block;
        vertical-align: middle;
        color: #fff;
      }
      .icon{
        font-size: 13px;
        &:before{
          color: #fff;
        }
      }
      .txt{
        transition: all 0.2s ease;
        margin-right: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.15em;
      }
      &:hover{
        .txt {
          margin-right: 10px;
        }
      }
    }
    .title{
      font-size: 18px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .sub-title{
      font-size: 17px;
      font-style: italic;
      margin-bottom: 20px;
    }
    .ct-price-increase{
      background: #f9bb4d;
      margin-bottom: 5px;
      padding: 7px 25px;
      font-size: 13px;
      text-align: left;
    }
    .percent{
      float: right;
      font-weight: 800;
    }
    .ct-price-total{
      background: #f9bb4d;
      padding: 10px 25px;
      text-align: right;
    }
    .total-price{
      .price,
      .total{
        display: inline-block;
        vertical-align: middle;
      }
      .price{
        font-size: 40px;
        font-weight: 700;
        margin-left: 30px;
      }
      .total{
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
      }

      small {
        font-size: 12px;
      }
    }
    .ct-price-calcul{
      text-align: right;
      padding-top: 30px;
      .price-calcul,
      .number-hours{
        padding: 0 25px;
        text-align: right;
        font-weight: 700;
      }
      .price-calcul{
        margin-bottom: 9px;
        .number,
        .hour{
          display: inline-block;
          vertical-align: middle;
        }
        .number{
          font-size: 40px;
          position: relative;
          vertical-align: bottom;
          line-height: 40px;
        }
        .euro{
          font-size: 30px;
          position: relative;
          bottom: 5px;
        }
      }
      .number-hours{
        text-align: right;
        padding-bottom: 25px;
        .multiplication,
        .number{
          display: inline-block;
        }
        .multiplication{
          vertical-align: bottom;
          font-size: 30px;
          transform: rotate(45deg);
          line-height: 24px;
        }
        .number{
          font-size: 40px;
          line-height: 40px;
        }
        .hour{
          font-size: 21px;
        }
      }
    }
    .reduction-tax{
      color: $text-color;
      font-size: 14px;
      margin-bottom: 3px;
      margin-left: 20%;
      .reduction{
        font-weight: 700;
      }
    }
    .article{
      font-size: 12px;
      color: $text-color;
      padding-left: 45px;
    }
  }

  .container-confirm {
    padding-top: 130px;

    @include breakpoint(1020px) {
      padding-top: 210px;
    }

    .h1-title-uppercase {
      margin-bottom: 20px;

      @include breakpoint(tablethor) {
        margin-bottom: 28px;
      }
    }

    .h3-title {
      margin-bottom: 35px;

      @include breakpoint(tablet) {
        margin-bottom: 55px;
      }
    }

    .container-reinsurance-list {
      margin-bottom: 30px;
      margin-top: 50px;

      @include breakpoint(tablethor) {
        margin-bottom: 45px;
      }
    }
  }