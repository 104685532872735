/* ==========================================================================
   Layout / Bloc Texte Image
   ========================================================================== */

  .container-axeo {
    position: relative;
    overflow: hidden;
    color: $whiteColor;
    margin-top: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;

    @include breakpoint(tablethor) {
      margin-top: 0;
      margin-bottom: 45px;
    }

    .h1-title-uppercase-white {
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        margin-bottom: 40px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 55px;
      }
    }

    &:before{
      content: "";
      position: absolute;
      width: 56%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      right: 0;
      height: 100%;
      z-index: 1;

      @media(max-width: 1023px){
        width: 100%;
      }
    }

    .visuel-axeo{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: 100%;
      max-width: inherit;
    }

    .container-reinsurance {
      position: relative;
      z-index: 2;
      padding: 100px 0;

      @include breakpoint(desktop) {
        padding: 120px 0;
      }

      a {
        .icon{
          transition: all 0.2s ease;
        }
        &:hover{
          .icon{
            transform: scale(1.15);
          }
        }
      }

      .h2 {
        @extend .h3-title;
        color: $whiteColor;
        margin-bottom: 15px;
      }

      p {
        font-family: $font-family-base;
        font-size: 17px;
        font-weight: 300;
        line-height: 1.3;
        margin-bottom: 20px;

        b, strong {
          font-weight: 600;
        }
      }
    }

    .content {
      line-height: normal;
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      @media(max-width: 1023px){
        margin-top: 0px;
        padding: 0px 30px;
      }

      .txt{
        color: $whiteColor;
        font-size: 22px;
        font-family: $special-font-family;
        width: 100%;
        margin-top: 27px;
        display: block;
        text-align: center;

        @media(max-width: 1024px){
          margin-top: 0px;
        }
      }

      a{
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        @media(max-width: 767px){
          font-size: 16px;
        }
      }
    }

    ul{
      width: 100%;
      @include flexbox();

      li{
        list-style-type: none;
        @include flex-grow(1);
      }

      .icon{
        @media(max-width: 1200px){
          -webkit-background-size: 120px 120px;
          background-size: 120px 120px;
          width: 120px;
        }
      }
    }
  }