.form-gab-contact{
  .input-checkbox{
    input:checked + .label{
      font-weight: 700;
    }
  }
  .parag{
    margin-top: 10px;
    margin-bottom: 37px;
    @include breakpoint(tablet){
      max-width: 90%;
    }
    @include breakpoint(tablethor){
      max-width: 85%;
    }
    @include breakpoint(laptop){
      max-width: 75%;
    }
    @include breakpoint(desktop){
      max-width: 70%;
    }
  }
  .civilite,
  .type{
      margin-bottom: 30px;
  }
  .title-know-us{
    margin-top: 8px;
    margin-bottom: 22px;
  }
  .ct-form-1{
    width: 640px;
    @media(max-width: 1279px){
      width: 100%;
    }
  }
  .btn-main{
    margin: 30px 0px 25px;
    padding: 12px 75px;
    @media (max-width: 767px){
      margin-left: calc((100% - 234px)/2);
    }
  }
}
.legal-notice{
  font-size: 10px;
  font-weight: 300;
}
