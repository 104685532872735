/* ==========================================================================
   Page / Homepage
   ========================================================================== */

    .homepage {
        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            padding-top: 150px;
            padding-bottom: 0;
            background-position: top center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            z-index: 9;

            @include breakpoint(tablet) {
                padding: 20px 50px 0;
                min-height: 765px;
            }

            @include breakpoint(tablethor) {
                padding: 0px 50px 0;
                background-position: center 25px;
            }
            @include breakpoint(mobileonly){
              margin: 0;
              padding-top: 100px;
            }

            &:after{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.2);
                position: absolute;
                z-index: 0;
            }

            &-searchbox {
                display: block;
                width: 100%;
                max-width: 950px;
                margin: auto;
                position: relative;
                z-index: 1;

              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                margin: 0;
                padding-top: 12%;
              }

              @include breakpoint(tablet) {
                  width: 95%;
              }

              @include breakpoint(laptop) {
                  max-width: 1020px;
              }

              .h1-title-white {
                  display: none;

                  @include breakpoint(tablet) {
                      display: block;
                  }

                  span {
                      display: block;
                      margin: 13px auto 0;
                      font-size: 36px;
                  }
              }

              .btn-main-white {
                display: inline-block;
                padding: 14px 65px;
                margin-top: 25px;
                margin-left: 20px;

                @include breakpoint(tablethor) {
                  margin-top: 0;
                  padding: 14px 45px;
                }
              }
          }
          .header-searchbox .searchbar #searchFormProductHome .error{
            margin-top: 20px;
            color: $whiteColor;
            font-weight: 400;
            font-size: 16px;
            padding-right: 110px;
          }
        }

        .description{
          position: relative;

          @media(max-width: 767px){
            .container{
              padding-left: 0;
              padding-right: 0;
            }
            .row{
              margin-left: 0;
              margin-right: 0;
            }
            [class*="col-"]{
              padding-left: 0;
              padding-right: 0;
              margin-right: 0;
              margin-left: 0;
            }
          }

          .h1-title-uppercase-white {
            margin-bottom: 30px;

            @include breakpoint(tablet) {
              margin-bottom: 50px;
            }

            @include breakpoint(laptop) {
              margin-bottom: 80px;
            }
          }

          .backgroundSpeaker {
            background: #f38c00 url(./src/img/visuel-rectangle-arondi.svg) right -50% center / 580px no-repeat;
            background: -webkit-linear-gradient(left, #f38c00, #f8a900);
            background: -o-linear-gradient(right, #f38c00, #f8a900);
            background: -moz-linear-gradient(right, #f38c00, #f8a900);
            background: linear-gradient(to right, #f38c00, #f8a900);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            &:after{
              content: "";
              width: 580px;
              height: 580px;
              background: url(./src/img/visuel-rectangle-arondi.svg) center bottom / 455px no-repeat;
              display: block;
              position: absolute;
              top: 0;
              right: -305px;

              @include breakpoint(tablethor) {
                background: url(./src/img/visuel-rectangle-arondi.svg) center center / 580px no-repeat;
                right: -182px;
                top: -80px;
              }
            }
          }

          &-speaker {
            display: block;
            background: #f38c00 url(./src/img/visuel-rectangle-arondi.svg) right -50% center / 580px no-repeat;
            background: -webkit-linear-gradient(left, #f38c00, #f8a900);
            background: -o-linear-gradient(right, #f38c00, #f8a900);
            background: -moz-linear-gradient(right, #f38c00, #f8a900);
            background: linear-gradient(to right, #f38c00, #f8a900);
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            &:after{
              content: "";
              width: 580px;
              height: 580px;
              background: url(./src/img/visuel-rectangle-arondi.svg) center bottom / 455px no-repeat;
              display: block;
              position: absolute;
              top: 0;
              right: -305px;

              @include breakpoint(tablethor) {
                background: url(./src/img/visuel-rectangle-arondi.svg) center center / 580px no-repeat;
                right: -182px;
                top: -80px;
              }
            }

            @include breakpoint(tablet) {
              background: transparent;
              &:after { display: none; }
            }

            @include breakpoint(tablethor) {
              width: 55%;
              min-height: 750px;
            }

            .speakers-content{
              padding: 60px 30px;
              overflow: hidden;
              position: relative;

              @include breakpoint(phablet) {
                padding: 60px 60px;
              }

              @include breakpoint(tablet) {
                padding: 80px 65px 0;
              }

              @include breakpoint(tablethor) {
                padding: 90px 5px;
              }

              @include breakpoint(laptop) {
                padding: 110px 0 110px 25px;
                float: right;
              }

              @include breakpoint(1400px) {
                padding: 110px 80px 110px 0;
              }

              .ct-img{
                border-radius: 50%;;
                overflow: hidden;
                width: 154px;
                height: 154px;

                @include breakpoint(laptop) {
                  margin-left: 20px;
                }

                img{
                  width: 100%;
                }

                @media(max-width: 767px){
                  width: 90px;
                  height: 90px;
                }
              }

              .ct-img, .ct-text{
                display: inline-block;
                vertical-align: middle;
              }

              .ct-text{
                width: calc(100% - 180px);
                padding-left: 30px;
                margin-top: 13px;

                @media(max-width: 767px){
                  width: calc(100% - 95px);
                  padding-left: 15px;
                }

                &.flr{
                  width: 100%;
                  text-align: center;
                  padding-left: 0;
                  margin-top: 20px;

                  @include breakpoint(tablethor) {
                    width: calc(100% - 180px);
                    margin-top: 10px;
                    float: right;
                  }

                  @include breakpoint(laptop) {
                    padding-left: 30px;
                    margin-top: 0;
                  }

                  .btn-main-white {
                    margin: auto;
                    width: 265px;
                    padding: 10px 5px;

                    @include breakpoint(tablethor) {
                      margin: 0;
                    }
                  }
                }
              }

              p {
                margin-bottom: 10px;

                @include breakpoint(tablet) {
                  font-size: 18px;
                }
              }

              .title {
                font-size: 30px;
                line-height: 30px;
                font-family: $special-font-family;

                @media(max-width: 767px){
                  margin-bottom: 15px;
                }
              }

              .content-profil {
                width: 570px;

                @include breakpoint(tablet) {
                  margin-bottom: 55px;
                }

                @include breakpoint(tablethor) {
                  margin-bottom: 0;
                }

                .ct-img,
                .ct-text{
                  position: relative;
                  z-index: 2;
                  text-align: left;
                }

                @media(max-width: 1300px){
                  width: 100%;
                }
                @media(max-width: 1024px){
                  width: auto;
                }
              }
            }
          }
      }

        .universe {
          position: relative;
          margin-top: 20px;

          @include breakpoint(tablethor) {
            bottom: 115px;
            margin-top: 0;
          }

          &-item {
            @media screen and (max-width: 576px) {
              width: 100% !important;
            }

            @media screen and (min-width: 576px) and (max-width: 1024px) {
              width: 50% !important;

              &:first-child {
                width: 100%!important;
              }
            }
          }
        }

        .container-axeo {
          margin-bottom: 0;

          &:before {
            @media(max-width: 1023px){
              width: 100%;
            }
          }
        }

        .newsletter {
          padding-top: 86px;
          padding-bottom: 75px;
          background: $light_grey;
          position: relative;

          .form-title{
            @extend .h2-title;
            text-shadow: 0px 0px 0px transparent;
            margin-bottom: 50px;
            text-align: center;
          }

          .label {
            color: $orange;
            font-size: 17px;
            font-weight: 700;

            @media(max-width: 767px){
              margin-bottom: 15px;
              display: block;
            }
          }

          .form-news-letter{
            width: 100%;
            .error {
              font-size: 13px;
              color: $red;
              margin-top: 20px;
              margin-left: 140px;
              font-weight: 400;
            }
            .success{
              color: $green;
              font-size: 13px;
              margin-top: 20px;
              margin-left: 290px;
            }
          }

          .form-line{
            text-align: center;
            .label,
            .input-ct{
              display: inline-block;
              vertical-align: middle;
            }
            .label{
              margin-right: 50px;
              @media(max-width: 767px){
                margin-right: 0;
                display: block;
                width: 100%;
              }
            }
          }

          .input-ct{
            background: #fff;
            width: 390px;
            position: relative;

            @media(max-width: 767px){
              width: 100%;
            }

            input{
              border: 0;
              background: #fff;
              height: 49px;
              font-size: 13px;
              width: 100%;
              display: inline-block;
              vertical-align: middle;
              padding-left: 25px;
            }

            .btn-submit-arrow {
              position: absolute;
              top: 0;
              right: 15px;
              bottom: 0;
              margin: auto;
            }
          }
      }

        .btn-open-aside {
          display: block;

          @include breakpoint(tablethor) {
            display: none!important;
          }
        }
    }

