/* ==========================================================================
   Page / Editoriale
   ========================================================================== */

  .container-edito,
  .container-text-img{
    position: relative;

    .btn-with-arrow-white{
      float: right;
      text-decoration: none;
    }

    .container-white{
      background: #ffffff;
      padding: 40px 10px 30px;
      color: $text-color;
      margin-left: -30px;
      margin-right: -30px;
      clear: both;

      @include breakpoint(tablet) {
        padding: 60px 30px 30px;
      }

      @include breakpoint(laptop) {
        padding: 40px 30px 30px;
      }

      @media(max-width: 1024px){
        margin-left: 0;
        margin-right: 0;
      }
    }

    .container-black{
      padding: 25px 35px;
      color: #fff;

      &.height-container{
        min-height: 400px;
        @media (max-width: 767px) {
          min-height: auto;
        }
      }
      a{
        color: #fff;
        @media(max-width: 767px){
          width: 100%;
          white-space: normal;
        }
      }

      ul{
        li{
          &:before{
            background: #fff;
          }
        }
      }

    }
    h1,
    h2,
    p,
    a,
    strong,
    ul{
      float: none;
    }

    h1,
    .h1-title-uppercase{
      @extend .h1-title-uppercase;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        &:before{
          width: 4px;
        }
      }
      &:before{
        background: $text-color;
      }
    }
    h2,
    .p-title{
      font-family: $special-font-family;
      font-size: 26px;
      margin-bottom: 17px;
    }

    .h2-title {
      font-size: 33px;
      line-height: 1;
      font-family: $special-font-family;
      text-shadow: 3px 1px 8px rgba(0, 0, 0, 0.25);
      text-transform: none;
      padding-left: 0;
      margin-bottom: 17px;

      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3;
      }

      &-white {
        @extend .h2-title;
        color: $whiteColor;
      }

      &:before,
      &:after{
        display: none;
      }
    }

    h3 {
      font-size: 24px;
      font-family: $special-font-family;
      letter-spacing: 0.02rem;
      margin-bottom: 17px;

      @include breakpoint(tablet) {
        font-size: 26px;
        line-height: 1.3;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
    }

    p{
      font-size: 16px;
      margin-bottom: 25px;
      &.clear{
        strong{
          display: block;
        }
      }
    }
    a{
      font-size: 16px;
      text-decoration: underline;
      color: $orange;
      &:hover{
        text-decoration: none;
      }
    }
    strong{
      font-weight: 700;
    }
    .quote-president{
      font-style: italic;
    }
    .mb50{
      margin-bottom: 50px;
    }
    ul{
      margin-bottom: 25px;
      &.list-numbers{
        .txt,
        .number{
          font-family: "Open Sans", sans-serif;
        }
        li:before{
          display: none;
        }
      }
      li{
        font-size: 16px;
        position: relative;
        &:before{
          content: "";
          display: inline-block;
          position: relative;
          top: -3px;
          width: 7px;
          height: 7px;
          border-radius: 2px;
          transform: rotate(45deg);
          background: $orange;
          margin-right: 5px;
        }
      }
    }
    img{
      float: left;
      margin-right: 33px;
      margin-bottom: 20px;
      &.center{
        margin: 0px auto;
        float: none;
        display: block;
        @media(max-width: 1023px){
          width: 80%;
        }
        @media(max-width: 767px){
          width: 100%;
        }
      }
      @media(max-width: 767px){
        display: block;
        height: auto;
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  .container-text-img{
    margin-bottom: 150px;
    margin-top: 45px;

    @media (max-width: 1023px) {
      margin-top: 0;
      margin-bottom: 50px;
    }
    .backgound-edito{
      background: rgb(242,133,0);
      background: -moz-linear-gradient(-45deg, rgba(242,133,0,1) 0%, rgba(248,170,0,1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
      background: linear-gradient(135deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      z-index: 2;
    }

    .container-black{
      position: relative;
      z-index: 3;
      padding: 50px 15px;

      @include breakpoint(tablet) {
        padding: 50px 35px;
      }
    }
    .container-img{
      margin-top: 25%;
      @media(max-width: 1366px){
        margin-top: 30%;
      }
      @media(max-width: 1279px){
        margin-top: 20%;
      }
      @media(max-width: 1023px){
        margin-top: -1px;
        margin-left: -5px;
        margin-right: -5px;
      }
      img{
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
        left: 0;
        @media(max-width: 1023px){
          position: relative;
          top: auto;
          left: auto;
          bottom: auto;
        }
      }
    }
  }

  .container-item-edito{
    overflow: hidden;
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 100px;
    }

    &.txt-white{
      color: #fff;
    }

    .overlay{
      &:hover{
        border: 6px solid #fff;
      }
    }

    /*&.home-maintenance{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-orange.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $orange;
        }
      }
    }
    &.gardens-exteriors{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-verte.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $green;
        }
      }
    }
    &.diy{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-marron.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $brown;
        }
      }
    }
    &.child-care{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-rose.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $pink;
        }
      }
    }
    &.seniors-autonomy{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-bleue.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $blue;
        }
      }
    }
    &.professional-cleaning{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-gris.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid #a7a7a7;
        }
      }
    }
    &.car-wash{
      .btn-with-arrow-white{
        &:after{
          background: url(./src/img/icone-fleche-violet.svg) center center / 40px 40px no-repeat;
        }
      }
      .overlay{
        &:hover{
          border: 6px solid $purple;
        }
      }
    }*/

    &.bg-img{
      position: relative;

      img{
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        min-width: 100%;
        min-height: 100%;
        margin: auto;

        @media (max-width: 1023px) {
          min-width: auto;
          max-width: none;
        }
      }
      .overlay{
        background: rgba(0, 0, 0, 0.6);
        display: block;
      }
    }

    .overlay{
      padding: 100px;
      transition: all 0.2s ease;
      border: 6px solid transparent;
      color: #fff;
      @media (min-width: 1025px) {
        min-height: 500px;
      }
      &:hover{
        .btn-with-arrow-white{
          &:after{
            transform: translateX(5px);
          }
        }
      }

      @media(max-width:767px){
        padding: 40px 30px;
      }
    }

    .h1-title-uppercase-white,
    .h1-title-uppercase{
      margin-bottom: 45px;
    }

    .h3-title{
      margin-bottom: 10px;
      color: #fff;
    }

    .btn-with-arrow{
      margin-top: 20px;
    }

    &.bg-orange,
    &.bg-grey{
      position: relative;
    }

    &.bg-orange{
      background: rgb(242,133,0);
      background: -moz-linear-gradient(-45deg, rgba(242,133,0,1) 0%, rgba(248,170,0,1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
      background: linear-gradient(135deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
      &:after{
        content: "";
        position: absolute;
        background: url(./src/img/icone-geometrie-blanc.svg) center center / 570px 570px no-repeat;
        top: -100px;
        right: -110px;
        width: 570px;
        height: 570px;
      }
    }

    &.bg-grey{
      color: $text-color;
      background: #f2f2f2;
      &:after{
        content: "";
        position: absolute;
        background: url(./src/img/icone-geometrie-orange.svg) center center / 570px 570px no-repeat;
        top: -100px;
        right: -110px;
        width: 570px;
        height: 570px;
      }
      .overlay{
        color: $black;
        .h3-title{
          color: $black;
        }
      }
    }

  }