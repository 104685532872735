/* ==========================================================================
   Page / Connexion
   ========================================================================== */

  :-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
  }

  .container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 50px 0;
    position: relative;

    @-moz-document url-prefix() {
      padding: 150px 0;
    }

      @include breakpoint(tablet) {
      min-height: 60vh;
    }

    @include breakpoint(tablethor) {
      padding: 80px 0;
      min-height: 100vh;

      @-moz-document url-prefix() {
        padding: 180px 0;
      }
    }

    &:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.15;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    & + .footer {
      margin-top: 0;
    }

    .loginform {
      margin-top: 20%;
      position: relative;
      z-index: 1;

      @include breakpoint(phablet) {
        margin-top: 10%;
      }

      .h1-title-white {
        font-size: 35px;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          font-size: 47px;
        }

        @include breakpoint(tablet) {
          margin-bottom: 28px;
        }
      }

      &-form {
        display: block;
        width: 90%;
        margin: auto;
        position: relative;
        padding: 25px 30px 10px;
        text-align: left;
        @include breakpoint(mobileonly) {
          width: 100%;
        }
        @include breakpoint(565px) {
          width: 415px;
        }

        &:after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background: #f28500;
          background: -moz-linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
          background: -webkit-linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
          background: linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
          opacity: 0.9;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }
      }

      &-row {
        margin-bottom: 20px;
        position: relative;
        z-index: 2;

        &:last-child {
          margin-top: 55px;
        }

        input[type="radio"] {
          display: none;
        }

        input[type="radio"] + label {
          color: $whiteColor;
          font-size: 16px;
          position: relative;
          cursor: pointer;

          b {
            font-weight: 700;
          }

          &:before {
            content: ' ';
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 500px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            padding: 0;
            margin-top: 0;
            margin-right: 14px;
            position: relative;
            top: 3px;
            cursor: pointer;
          }

          &:after {
            content: ' ';
            border-radius: 800px;
            width: 10px;
            height: 10px;
            background: $whiteColor;
            position: absolute;
            top: 6px;
            left: 1px;
            right: 0;
            bottom: 0;
            margin: 2px;
            transform: scale(0.0);
            opacity: 0;
            transition: all $durationSmall $easeOutQuint;
          }
        }

        input[type="radio"]:checked + label {
          &:after {
            transform: scale(1.0);
            opacity: 1.0;
            transition: all $durationSmall $easeOutQuint;
          }
        }

        input {
          width: 100%;
          padding: 18px;
          font-size: 13px;
          color: #5d5d5d;
          border: 0px;
          outline-style: none;
          margin-top: 5px;
          letter-spacing: 0.03rem;
          transition: all $durationSmall $easeInOutCirc;

          &:focus {
            box-shadow:inset 0 0 0 4px rgba(234,147,9,0.3);
            transition: all $durationSmall $easeInOutCirc;
          }
        }

        .forgot-psswd {
          float: right;
          text-align: right;
          color: $whiteColor;
          font-size: 12px;
          padding: 2px 0;
          margin-top: 8px;
          position: relative;
          opacity: 0.7;
          transition: all $durationSmall $easeInOutCirc;

          &:hover {
            opacity: 1.0;
            transition: all $durationSmall $easeInOutCirc;

            &:after {
              width: 65%;
              transition: all $durationSmall $easeInOutCirc;
            }
          }

          &:after {
            content: ' ';
            width: 99%;
            margin: auto;
            height: 1px;
            background: $whiteColor;
            position: absolute;
            left: 0;
            right: 0;
            bottom:0;
            opacity: 0.8;
            transition: all $durationSmall $easeInOutCirc;
          }
        }

        .btn-main-white {
          margin: 15px auto;
          padding: 15px 65px;
        }

        .create {
          display: table;
          text-align: center;
          margin: 15px auto 0;
          color: $whiteColor;
          font-size: 13px;
          padding: 2px 0;
          position: relative;

          &:after {
            content: ' ';
            width: 0;
            height: 1px;
            background: $whiteColor;
            position: absolute;
            left: 0;
            bottom:0;
            opacity: 0.8;
            transition: all $durationSmall $easeInOutCirc;
          }

          &:hover {
            &:after {
              width: 98%;
              transition: all $durationSmall $easeInOutCirc;
            }
          }
        }
      }
    }
  }