/* ==========================================================================
   Page / Accueil - Mon Compte
   ========================================================================== */

  .container-account {
    padding-top: 100px;

    @include breakpoint(725px) {
      padding-left: 35px;
      padding-right: 35px;
    }

    @include breakpoint(1020px) {
      padding-top: 180px;
    }

    @include breakpoint(1030px) {
      padding-left: 0;
      padding-right: 0;
    }

    .breadcrumb {
      margin-bottom: 40px;

      @include breakpoint(tablethor) {
        margin-bottom: 70px;
      }
    }

    .breadcrumb-list li span, .breadcrumb-list li a:after {
      color: $text-color;
    }

    .breadcrumb .container {
      width: 100%;
      padding: 0;
    }

    .h1-title-uppercase {
      margin-bottom: 20px;

      @include breakpoint(tablethor) {
        margin-bottom: 28px;
      }
    }

    .h3-title {
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        margin-bottom: 35px;
      }
    }

    .services {
      margin-top: 40px;
      margin-bottom: 35px;

      @include breakpoint(767px) {
        margin-top: 70px;
        margin-bottom: 85px;
      }

      &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
          flex-basis: 100%;
          margin-bottom: 11px;
          background: $light_grey;
          transition: all $duration $easeOutQuint;

          @include breakpoint(phablet) {
            flex-basis: 47.5%;
            margin-right: 13px;
          }

          @include breakpoint(900px) {
            flex-basis: 31.5%;
          }

          @include breakpoint(1120px) {
            flex-basis: 32%;
          }

          @include breakpoint(1345px) {
            flex-basis: 23.9%;
          }

          @include breakpoint(1500px) {
            flex-basis: 24%;
          }

          &:hover {
            background: lighten($light_grey, 2%);
            transition: all $duration $easeOutQuint;

            .title b {
              text-decoration: underline;
            }

            img {
              transform: translateY(-5px);
              transition: all $durationSmall $easeOutQuint;
            }
          }
        }

        a {
          display: flex;
          flex-direction: column;
          height: 100%;
          text-align: center;
          padding: 55px 50px 30px;

          @include breakpoint(900px) {
            padding: 55px 30px 30px;
          }

          @include breakpoint(1120px) {
            padding: 55px 50px 30px;
          }

          img {
            margin: 0 auto 35px;
            transition: all $durationSmall $easeOutQuint;
          }

          .title {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            b {
              font-size: 21px;
              font-weight: 800;
              line-height: 1.1;
            }
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .profil {
      max-width: 850px;
      margin: auto;
      text-align: center;

      .h3-title {
        color: $orange;
        text-align: center;
        margin: 0 auto 45px;

        @include breakpoint(phablet) {
          width: 80%;
        }

        @include breakpoint(915px) {
          width: 60%;
        }

        b {
          font-size: 29px;

          @include breakpoint(phablet) {
            display: block;
          }

          @media (max-width: 767px) {
            font-size: 25px;
          }
        }
      }

      &-infos {
        b {
          display: block;
          font-weight: 800;
          font-size: 21px;
          line-height: 1;
        }

        .percent {
          display: block;
          color: $orange;
          font-size: 48px;
          margin-bottom: 15px;
        }

        .progressbar {
          max-width: 415px;
          height: 12px;
          margin: auto;
          background: $text-color;
          border-radius: 15px;
          position: relative;
          margin-bottom: 40px;

          @include breakpoint(tablet) {
            height: 9px;
            margin-bottom: 65px;
          }

          span {
            display: block;
            height: 12px;
            padding: 0 1%;
            background: $orange;
            border-radius: 15px;
            position: absolute;
            left: 0;
            top: 0;

            @include breakpoint(tablet) {
              height: 9px;
            }
          }
        }
      }

      &-comp {
        display: table;
        width: 100%;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          margin-bottom: 35px;
        }

        .row {
          display: block;
          background: $light_grey;
          text-align: left;
          padding: 35px 0 5px;
          margin-bottom: 15px;

          @include breakpoint(phablet) {
            display: table-cell;
            width: 50%;
            padding: 25px 0 10px;
            margin-bottom: 0;

            &:first-child {
              border-right: 5px solid $whiteColor;
            }

            &:last-child {
              border-left: 5px solid $whiteColor;
            }
          }

          @include breakpoint(770px) {
            padding: 40px 0 10px;
          }

          @include breakpoint(850px) {
            padding: 60px 0 10px;
          }

          .title {
            display: block;
            text-align: left;
            font-weight: 800;
            font-size: 21px;
            margin-bottom: 25px;
          }

          input {
            background: #fbfbfa;
            border-radius: 5px;
            padding: 10px 9px;
            line-height: 1;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              padding: 10px 10px;
            }
          }

          &-line {
            width: 230px;
            margin: auto auto 35px;
            text-align: center;

            b {
              display: block;
              text-align: left;
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 25px;
            }
          }

          &-field {
            display: inline-block;
            margin-right: 45px;
          }
        }
      }
    }
  }
  .row-input {
    margin-bottom: 30px;
    text-align: center;
    .btn-less {
      display: inline-block;
      vertical-align: middle;
      width: 38px;
      height: 38px;
      background: url('./src/img/icone-btnless.svg') center center/ cover no-repeat;
      border: 0;
      outline-style: none;
      margin-right: 9px;
      transition: all $durationSmall $easeInOutCirc;
      cursor: pointer;
    }

    .btn-more {
      display: inline-block;
      vertical-align: middle;
      width: 39px;
      height: 38px;
      background: url('./src/img/icone-btnmore.svg') center center/ cover no-repeat;
      border: 0;
      outline-style: none;
      margin-left: 7px;
      transition: all $durationSmall $easeInOutCirc;
      cursor: pointer;
    }

    .locked {
      filter: grayscale(100%);
      opacity: 0.8;
      transition: all $durationSmall $easeInOutCirc;

      &:hover {
        filter: grayscale(100%);
        opacity: 0.8;
      }
    }

    .btn-less:not(.locked):hover, .btn-more:not(.locked):hover {
      filter: contrast(125%) brightness(110%);
      transition: all $durationSmall $easeInOutCirc;
    }

    input {
      display: inline-block;
      vertical-align: middle;
      width: 120px;
      padding: 10px;
      text-align: center;
      font-weight: 700;
      font-size: 12px;

      @supports (-ms-accelerator:true) {
        font-size: 12px;
      }
    }

    ::placeholder {
      color: $text-color;
      font-weight: 700;
      font-size: 12px;
      position: relative;
      bottom: 2px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-size: 11px;
      }
    }
  }
  .row-line input{
    font-size: 12px;
  }

  .prestation-offer {
    padding: 22px 28px;
    margin-top: 25px;
    margin-right: 15%;
    margin-bottom: 27px;
    background: rgb(243, 147, 2);
    background: -moz-linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
    background: -webkit-linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
    background: linear-gradient(-43deg, #f8aa00 0%, #f28500 100%);
    color: #fff;

    @media(max-width: 1279px) {
      margin-right: 0;
    }

    @include breakpoint(tablethor) {
      margin-top: 60px;
      padding: 22px 30px;
    }
  }

  .btn-prestation-offer {
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        color: #fff;
        text-transform: uppercase;

        @include breakpoint(phablet) {
          font-size: 16px;
        }
      }

      .icon {
        font-size: 40px;
        margin-left: 8px;
        transition: all 0.2s ease;

        @include breakpoint(phablet) {
          margin-left: 15px;
        }
      }

      @include breakpoint(laptop) {
        .txt {
          @media(max-width: 767px) {
            width: calc(100% - 70px);
          }
        }
        &:hover {
          .icon {
            margin-left: 25px;
          }
        }
      }

      .h2 {
        font-size: 26px;
        color: #fff;
        font-family: $special-font-family;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        color: #fff;
        line-height: 24px;
        margin-bottom: 10px;

        .strong {
          font-size: 21px;
          font-weight: 600;
        }
      }
    }

  .container-filter{
    margin-bottom: 10px;

    @include breakpoint(laptop) {
      width: 95%;
    }

    @include breakpoint(desktop) {
      width: 90%;
    }

    @media(max-width: 1359px){
      width: 100%;
    }

    @media(min-width: 1280px) and (max-width: 1380px){
      width: 97%;
    }
  }

  .select-content{
    display: inline-block;
    vertical-align: middle;
    select{
      border: 1px solid #9c9b9a;
      border-radius: 5px;
      height: 55px;
      line-height: 55px;
      width: 100%;
      background: url(./src/img/icone-fleche-bas-orange.svg) right 22px center/15px 10px no-repeat;
      display: block;
      width: 100%;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: calc(100% - (100% - 50px));
      padding-left: 15px;
      color: $text-color;
    }
    input::-ms-clear,
    select::-ms-expand {
      display: none;
    }
    select:focus::-ms-value {
      background: transparent;
      color: #000;
    }
  }

  .form-info-perso{
    .title-axeo{
      color: $text-color;
      &:before{
        background: $text-color;
      }
    }
    .btn-orange{
      float: right;
    }
  }