[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon{
  img{
    width: 100%;
  }
}

.icon-chevron{
  background: url(./src/img/icone-chevron.svg) center center / 30px 30px no-repeat;
  width: 30px;
  height: 30px;
}

.icon-job-bricolage:before {
  content: "\e903";
  color: #fff;
  font-size: 21px;
}
.icon-job-entretien:before {
  content: "\e904";
  color: #fff;
  font-size: 26px;
}
.icon-job-garde:before {
  content: "\e905";
  color: #fff;
  font-size: 23px;
}
.icon-job-jardin:before {
  content: "\e906";
  color: #fff;
}
.icon-job-senior {
  background: url(./src/img/icone-seniors-et-autonomie.svg) center center / 30px 30px no-repeat;
  width: 30px;
  height: 30px;
}
.icon-lavage-job-automobile:before {
  content: "\e931";
  color: #fff;
  font-size: 21px;
}
.icon-nettoyage-job-professionnel:before {
  content: "\e932";
  color: #fff;
  font-size: 21px;
}
.icon-facebook:before {
  content: "\e930";
}
.icon-camera:before {
  content: "\e92f";
  color: #fff;
}
.icon-fridge:before {
  content: "\e92c";
  color: #fff;
}
.icon-ironing:before {
  content: "\e92d";
  color: #fff;
}
.icon-oven:before {
  content: "\e92e";
  color: #fff;
}
.icone-appareil-photo:before {
  content: "\e92f";
  color: #fff;
}
.icon-check-white:before {
  content: "\e92a";
  color: #fff;
}
.icon-plus-orange:before {
  content: "\e929";
  color: #f18506;
  font-size: 62px;
}
.icon-bin:before {
  content: "\e928";
}
.icon-fleche-retour:before {
  content: "\e927";
  color: #f18506;
}
.icon-cross-basket:before {
  content: "\e926";
}
.icon-fleche-droite-aside:before {
  content: "\e925";
  color: #fff;
}
.icon-fleche-droite-aside-orange:before {
  content: "\e925";
  color: $orange;
}
.icon-loupe:before {
  content: "\e924";
  color: #fff;
}
.icon-croix-modal:before {
  content: "\e923";
  color: #f29400;
}
.icon-telephone:before {
  content: "\e922";
  color: #f39205;
}
.icon-fleche-bas-gris:before {
  content: "\e921";
  color: #6a6a6a;
}
.icon-fleche-bas-blanc:before {
  content: "\e920";
  color: #fff;
}
.icon-utilisateurs:before {
  content: "\e91f";
}
.icon-contactez-nous:before {
  content: "\e91b";
}
.icon-fleche-droite-bleu .path1:before {
  content: "\e91c";
  color: rgb(0, 0, 0);
}
.icon-fleche-droite-bleu .path2:before {
  content: "\e91d";
  margin-left: -52px;
  color: rgb(255, 255, 255);
}
.icon-fleche-droite-blanc:before {
  content: "\e91e";
}
.icon-instagram:before {
  content: "\e900";
}
.icon-connexion:before {
  content: "\e901";
}
.icon-panier:before {
  content: "\e902";
}
.icon-agence .path1:before {
  content: "\e908";
  color: rgb(243, 147, 2);
}
.icon-agence .path2:before {
  content: "\e909";
  margin-left: -0.92578125em;
  color: rgb(206, 206, 206);
}
.icon-cluster:before {
  content: "\e90a";
}
.icon-conseiller .path1:before {
  content: "\e90b";
  color: rgb(206, 206, 206);
}
.icon-conseiller .path2:before {
  content: "\e90c";
  margin-left: -1.0419921875em;
  color: rgb(243, 146, 0);
}
.icon-youtube:before {
  content: "\e90d";
}
.icon-agence-bis:before {
  content: "\e90e";
}
.icon-fb:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e918";
}
.icon-fleche:before {
  content: "\e919";
}
.icon-aide:before {
  content: "\e91a";
}
.icon-assurance{
  background: url(./src/img/icone-assurance.svg) center center / 69px 67px no-repeat;
  width: 69px;
  height: 67px;
}
.icon-certification{
  background: url(./src/img/icone-certifications.svg) center center / 47px 70px no-repeat;
  width: 47px;
  height: 70px;
}
.icon-qualite{
  background: url(./src/img/icone-qualite.svg) center center / 68px 68px no-repeat;
  width: 68px;
  height: 68px;
}
.icon-proximite{
  background: url(./src/img/icone-proximite.svg) center center / 69px 68px no-repeat;
  width: 69px;
  height: 68px;
}
.icon-reactivite{
  background: url(./src/img/icone-reactivite.svg) center center / 69px 68px no-repeat;
  width: 69px;
  height: 68px;
}
.icon-confiance{
  background: url(./src/img/icone-confiance.svg) center center / 75px 74px no-repeat;
  width: 75px;
  height: 68px;
}
.icon-satisfaction{
  background: url(./src/img/icone-satisfaction.svg) center center / 69px 68px no-repeat;
  width: 69px;
  height: 68px;
}
.icon-laposte{
  background: url(./src/img/icone-la-poste.png) center center / 46px 36px no-repeat;
  width: 46px;
  height: 63px;
}
.icon-help{
  background: url(./src/img/logo-depannage.png) center center / 61px 34px no-repeat;
  width: 61px;
  height: 34px;
}
.icon-service{
  background: url(./src/img/icone-service-personne.png) center center / 48px 38px no-repeat;
  width: 48px;
  height: 38px;
}
.icon-my-house{
  background: url(./src/img/icone-ma-maison.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-my-cleaning{
  background: url(./src/img/icone-nettoyage.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-my-children{
  background: url(./src/img/icone-mes-enfants.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-my-parents{
  background: url(./src/img/icone-mes-parents.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-maintain {
  background: url(./src/img/icone-maintenance.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-my-garden{
  background: url(./src/img/icone-mon-jardin.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-exteriors{
  background: url(./src/img/icone-espace-verts.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-my-crafts{
  background: url(./src/img/icone-mon-bricolage.svg) center center / 183px 177px no-repeat;
  width: 183px;
  height: 177px;
}
.icon-offre-complete{
  background: url(./src/img/icone-offre-complete.svg) center center / 68px 67px no-repeat;
  width: 68px;
  height: 67px;
}
.icon-prestation-professionnelle{
  background: url(./src/img/icone-prestation-professionnelle.svg) center center / 65px 69px no-repeat;
  width: 65px;
  height: 69px;
}
.icon-stairs{
  background: url(./src/img/icone-escalier.svg) center center / 61px 55px no-repeat;
  width: 74px;
  height: 62px;
}
.icon-pets{
  background: url(./src/img/icone-animaux-compagnie.svg) center center / 74px 62px no-repeat;
  width: 74px;
  height: 62px;
}
.icon-child{
  background: url(./src/img/icone-enfants.svg) center center / 60px 61px no-repeat;
  width: 74px;
  height: 62px;
}
.icon-keys{
  background: url(./src/img/icone-cle.svg) center center / 59px 65px no-repeat;
  width: 74px;
  height: 62px;
}
.icon-arrow-left{
  background: url(./src/img/icone-fleche-gauche.svg) center center / 12px 21px no-repeat;
  width: 12px;
  height: 21px;
}
.icon-user-black{
  background: url(./src/img/icone-utilisateur-noir.svg) center center / 15px 20px no-repeat;
  width: 15px;
  height: 20px;
}
.icon-password-black{
  background: url(./src/img/icone-cadenas-noir.svg) center center / 20px 22px no-repeat;
  width: 20px;
  height: 22px;
}
.icon-tax-return-document{
  background: url(./src/img/icone-attestation-fiscale.svg) center center / 94px 67px no-repeat;
  width: 94px;
  height: 67px;
}
.icon-magnifying-glass-check{
  background: url(./src/img/icone-loupe-check.svg) center center / 81px 81px no-repeat;
  width: 81px;
  height: 81px;
}
.icon-adress-orange{
  background: url(./src/img/icone-adresse-orange.svg) center center / 72px 62px no-repeat;
  width: 72px;
  height: 62px;
}
.icon-plus{
  background: url(./src/img/icone-plus-orange.svg) center center / 62px 62px no-repeat;
  width: 62px;
  height: 62px;
}
.icon-arrows-right-orange{
  background: url(./src/img/icone-fleches-droite-orange.svg) center center / 70px 48px no-repeat;
  width: 70px;
  height: 48px;
}
.icon-danger{
  background: url(/fonts/icone-attention.svg) center center/21px 20px no-repeat;
  width: 21px;
  height: 20px;
  margin-bottom: 10px;

  @include breakpoint(tablet) {
    background: url(./src/img/icone-attention.svg) center center / 11px 10px no-repeat;
    width: 11px;
    height: 10px;
    margin-bottom: 0;
  }
}
.icon-check-circle{
  background: url(./src/img/icone-cercle-check.svg) center center / 70px 70px no-repeat;
  width: 70px;
  height: 70px;
}
.icon-check-list{
  background: url(./src/img/icone-check-list.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-intervention{
  background: url(./src/img/icone-intervention.svg) center center / 65px 65px no-repeat;
  width: 65px;
  height: 65px;
}
.icon-envelope{
  background: url(./src/img/icone-enveloppe.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-shop{
  background: url(./src/img/icone-shopV2.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-calendar-ponctuel{
  background: url(./src/img/icone-calendar-confirmation.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-house{
  background: url(./src/img/icone-house.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-calendar-conversation{
  background: url(./src/img/icone-conversation.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-apply{
  background: url(./src/img/icone-recrutement.svg) center center / 40px 36px no-repeat;
  width: 65px;
  height: 65px;
}
.icon-contact-intervention{
  background: url(./src/img/icone-contact-intervention.svg) center center / 61px 62px no-repeat;
  width: 61px;
  height: 62px;
}
.icon-surveillance{
  background: url(./src/img/icone-surveillance.svg) center center / 80px 62px no-repeat;
  width: 80px;
  height: 62px;
}
.icon-contact-client{
  background: url(./src/img/icone-contact-client.svg) center center / 70px 65px no-repeat;
  width: 70px;
  height: 65px;
}
.icon-boutique{
  background: url(./src/img/icone-boutique.svg) center center / 74px 65px no-repeat;
  width: 74px;
  height: 65px;
}
.icon-offers{
  background: url(./src/img/icone-offres.svg) center center / 68px 68px no-repeat;
  width: 68px;
  height: 68px;
}
.icon-calendar2 {
  background: url(./src/img/icone-calendrier-v2.svg) center center / 65px 65px no-repeat;
  width: 65px;
  height: 65px;
}
.icon-login {
  background: url(./src/img/icone-header-deconnexion.svg) center center / 65px 65px no-repeat;
  width: 65px;
  height: 50px;
}
.icon-prestations-partners{
  background: url(./src/img/icone-prestations.svg) center center / 40px 40px no-repeat;
  width: 65px;
  height: 50px;
}
.icon-logout{
  background: url(./src/img/icone-logout.svg) center center / 65px 65px no-repeat;
  width: 65px;
  height: 65px;
}

.icon-benefits {
  background: url(./src/img/icone-header-prestations.svg) center center / 65px 65px no-repeat;
  width: 65px;
  height: 65px;
}
.icon-star{
  background: url(./src/img/note-etoile-full.svg) center center / 73px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-employees{
  background: url(./src/img/icone-salaries.svg) center center / 73px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-agency{
  background: url(./src/img/icone-shop.svg) center center / 73px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-private-clients{
  background: url(./src/img/icone-team.svg) center center / 73px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-professional-customers{
  background: url(./src/img/icone-contract.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-pantone{
  background: url(./src/img/icone-pantone.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-family{
  background: url(./src/img/icone-family.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-building{
  background: url(./src/img/icone-building.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-wall-clock{
  background: url(./src/img/icone-wall-clock.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-trophy{
  background: url(./src/img/icone-trophy.svg) center center / 55px 70px no-repeat;
  width: 73px;
  height: 70px;
}
.icon-check-img{
  img{
    position: relative;
    z-index: 2;
    width: 24px;
    height: 24px;
  }
}
.icon-envelope-blanc{
  background: url(./src/img/icone-enveloppe-blanc.svg) center center / 20px 20px no-repeat;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}