/* ==========================================================================
   Components / Navigation Breadcrumb
   ========================================================================== */

  .breadcrumb {
    font-size: 13px;
    font-weight: 500;
    color: $dark_grey;
    position: relative;
    z-index: 1;

    ul li {
      display: inline-block;

      &:not(:last-child):after {
        content: '›';
        font-size: 16px;
        margin: 0 12px;
        position: relative;
        bottom: 1px;
      }

      &:last-child {
        font-weight: 700;
      }
    }

    a {
      color: $dark_grey;

      &:hover {
        text-decoration: underline;
      }
    }
  }