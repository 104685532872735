.container-my-package{
  .download-contract{
    text-transform: uppercase;
    font-size: 14px;
    line-height: normal;
    font-weight: 700;
    color: $orange;
    text-decoration: underline;
    margin-bottom: 13px;
    display: block;
    float: right;
    padding-right: 1%;

    @include breakpoint(1025px){
      padding-right: 0;
    }

    &:hover{
      text-decoration: none;
    }
  }
  .container-info,
  .container-social{
    height: 180px;
    display: table;
    width: 100%;
    background: $light_grey;
    p{
      line-height: normal;
    }
  }
  .container-social{
    margin-top: 32px;
    &:hover{
      .icon-facebook {
        margin-bottom: 10px;
        color: $facebook;
      }
      p {
        color: $facebook;
      }
    }
    .icon-facebook{
      font-size: 40px;
      margin-bottom: 20px;
      transition: all 0.2s ease;
    }
    p{
      font-size: 16px;
      font-weight: 300;
      padding: 0px 20%;
    }
    .ct-center{
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
  .container-info{
    .ct-info,
    .ct-price{
      .date,
      .note,
      .status,
      p{
        line-height: normal;
      }
    }
    .date,
    .note,
    .status{
      display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 0px 20px;
        @media(max-width: 767px){
          display: block;
          padding: 20px;
        }
      }
    .status{
      color: $orange;
      font-size: 16px;
      font-weight: 700;
    }
    .date{
      font-size: 16px;
      font-weight: 700;
    }
    .ct-price{
      height: 180px;
      line-height: 180px;
      width: 30%;
      text-align: center;
      background: rgb(242,133,0);
      background: -moz-linear-gradient(-45deg, rgba(242,133,0,1) 0%, rgba(248,170,0,1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);
      background: linear-gradient(135deg, rgba(242,133,0,1) 0%,rgba(248,170,0,1) 100%);

      p{
        color: #fff;
      }
      .ct-center{
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        p{
          font-size: 19px;
          font-weight: 700;
        }
        .price{
          margin-top: 10px;
        }
      }
    }
    .ct-info{
      width: 69%;
      display: table;
      height: 180px;
    }
    .ct-info,
    .ct-price{
      float: left;
      @media(max-width: 767px){
        width: 100%;
        float: none;
        height: auto;
      }
    }
  }
  .container-info-inte{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    background-color: transparent;
    height: auto;

    .ct-price{
      width: 49%;

      @include breakpoint(1025px){
        width: 25%;
      }
    }
    .ct-info{
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f4f4f4;
      width: 99%;
      margin-top: 5%;
      order: 3;
      @include breakpoint(mobileonly){
        flex-direction: column;
      }
      @include breakpoint(1025px){
        order: 2;
        width: 58%;
        margin-right: 1%;
        margin-top: 0%;
      }
    }
    .container-social{
      width: 50%;
      background-color: #f4f4f4;
      display: flex;
      margin-top: 0;
      align-items: center;
      justify-content: center;
      order: 2;
      height: auto;
      @include breakpoint(1025px){
        order: 3;
        width: 16%;
      }
    }
  }
  .note{
    font-size: 16px;
    font-weight: 700;
  }
  .mark-star{
    display: block;
  }
  .price{
    font-size: 24px;
    font-weight: 700;
    .number{
      font-size: 46px;
    }
  }
}

.container-next-passages,
.container-passages-passed{
  margin-top: 70px;
}

.container-next-passages{
  .container-passage{
    background: $light_grey;
    margin-top: 20px;
    height: 75px;
    display: table;
    width: 100%;
    padding-left: 10px;
    @media(max-width:767px){
      height: auto;
    }
    @media(max-width:767px){
      display: block;
      width: 100%;
    }
    .date,
    .name,
    .change-date{
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 33%;
      @media(max-width:767px){
        display: block;
        width: 100%;
        padding: 10px;
      }
    }
    .date{
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
    .name{
      font-size: 16px;
      text-align: left;
      @media(max-width:767px){
        text-align: center;
      }
    }
    .change-date{
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: 600;
      color: $orange;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.list-passages-passed{
  .visible-xs{
    font-size: 14px;
    font-weight: 600;
    color: $orange;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover{
      text-decoration: none;
    }
    display: inline-block;
  }
  .h4{
    display: flex;
    width: 50%;
    min-width: 300px;
    justify-content: space-between;
    align-items: center;
  }
  .passage-passed{
    background: $light_grey;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    .date{
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
    a{
      font-size: 14px;
      font-weight: 600;
      color: $orange;
      text-decoration: underline;
      text-transform: uppercase;
      display: block;
      &:hover{
        text-decoration: none;
      }
    }
    td{
      vertical-align: middle;
      text-align: center;
      padding: 4px 0px;
      @media(max-width: 767px){
        display: block;
        &:before{
          content: attr(data-title);
          display: none;
          text-align: left;
          text-transform: uppercase;
          font-family: 'rimouski-sb', sans-serif;
          margin-bottom: 10px;
          color: #393835;
          font-size: 17px;
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 10px;
        }
      }
    }
  }
  .h4{
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 22px;
  }
  .claim{
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    &:hover{
      text-decoration: none;
    }
  }
  > li{
    margin-bottom: 55px;
  }
}
