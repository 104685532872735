/* ==========================================================================
   Page / Carrefour Produits
   ========================================================================== */

  .container-white{
    position: relative;
    padding: 0;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    background: #fff;

    @include breakpoint(tablet) {
      padding: 20px;
      margin-bottom: 0;
    }

    @include breakpoint(tablethor) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .arrow-search{
    background: rgb(252,176,61);
    background: -moz-linear-gradient(left, rgba(252,176,61,1) 0%, rgba(243,147,2,1) 88%);
    background: -webkit-linear-gradient(left, rgba(252,176,61,1) 0%,rgba(243,147,2,1) 88%);
    background: linear-gradient(to right, rgba(252,176,61,1) 0%,rgba(243,147,2,1) 88%);
    position: relative;
    width: 200px;
    height: 130px;
    line-height: 135px;
    display: block;
    float: left;
    text-align: center;

    @include breakpoint(phablet) {
      height: 100px;
      line-height: 105px;
    }

    @include breakpoint(tablet) {
      height: 182px;
      line-height: 182px;
    }

    @media(max-width: 767px){
      width: 100%;
      background: rgb(252,176,61);
      background: -moz-linear-gradient(top, rgba(252,176,61,1) 0%, rgba(243,147,2,1) 100%);
      background: -webkit-linear-gradient(top, rgba(252,176,61,1) 0%,rgba(243,147,2,1) 100%);
      background: linear-gradient(to bottom, rgba(252,176,61,1) 0%,rgba(243,147,2,1) 100%);
    }


    &:after{
      content: "";
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 0 17px 13px;
      border-color: transparent transparent transparent $orange;
      @media(max-width: 767px){
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 17px 0 17px;
        border-color: $orange transparent transparent transparent;
        right: auto;
        top: auto;
        bottom: -13px;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }

    .label{
      color: #fff;
      text-transform: uppercase;
      font-size: 28px;
      font-family: $special-font-family;
      text-align: center;
      line-height: normal;
      display: inline-block;
      vertical-align: middle;

      span {
        display: block;

        @include breakpoint(phablet) {
          display: inline-block;
        }
      }
    }
  }

  .search-by-city{
    float: left;
    width: calc(100% - 200px);
    background: #595959;
    height: 182px;
    padding-left: 70px;
    line-height: 182px;

    @media(max-width: 1023px){
      padding-left: 30px;
    }
    @media(max-width: 767px){
      width: 100%;
      padding: 20px;
      height: auto;
      padding-bottom: 30px;
    }

    .form-search-by-city{
      display: block;
      width: 100%;
      line-height: normal;
      text-align: center;
      padding: 10px 5px;

      @include breakpoint(tablet) {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
      }

      .input-ct {
        background: $whiteColor;
        padding: 0px 10px;

        @include breakpoint(tablet) {
          display: inline-block;
          width: 53%;
          margin-right: 30px;
        }

        @include breakpoint(tablethor) {
          width: 50%;
        }
      }

      .icon-cluster {
        color: $orange;
        display: inline-block;
        vertical-align: middle;
        font-size: 22px;
        margin-left: 5px;
        position: relative;
        bottom: 2px;
      }

      .label{
        color: #fff;
        font-size: 25px;
        font-family: $special-font-family;
        margin-bottom: 21px;
      }

      input{
        border: 0;
        background: #fff;
        width: 88%;
        height: 47px;
        padding-left: 10px;
        margin-right: 30px;

        @include breakpoint(phablet) {
          width: 92%;
        }

        @include breakpoint(tablet) {
          width: 74%;
        }

        @include breakpoint(tablethor) {
          width: 76%;
        }

        @media(max-width: 1023px){
          //width: 220px;
        }

        @media(max-width: 767px){
          margin: 0px;
        }

        @include placeholder{
          color: #767676;

        }
      }

      .form-line{
        margin-top: 20px;
      }

      .btn-main-white {
        display: inline-block;
        margin-top: 25px;
        margin-left: 10px;

        @include breakpoint(tablet) {
          margin-top: 0;
          margin-left: 0;
          padding: 12px 30px;
          position: relative;
          right: 5px;
        }

        @include breakpoint(tablethor) {
          margin-top: 0;
          padding: 12px 45px;
        }
      }
    }
  }

  .focus-content,
  .height-focus {

    .container-focus{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0px 30px 40px;
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
      border-bottom-right-radius: 17px;
      background: #f6f6f5;
      text-align: center;

      @media(max-width: 1279px){
        position: static;
      }
    }

    .content-img{
      position: relative;
      height: 50px;
      display: inline-block;

      img{
        top: -20%;
        left: 50%;
        border-radius: 50%;;
        overflow: hidden;
        transform: translate(-50%, -50%);
        border: 4px solid #fff;
        position: absolute;
        width: 98px;
        height: 98px;
      }
    }

    .content-description{
      .name {
        font-size: 24px;
        color: $orange;
        font-family: $special-font-family;
      }
      .experience{
        font-size: 16px;
        font-family: $special-font-family;
        margin-bottom: 20px;
      }
      .description{
        font-size: 16px;

        strong{
          font-weight: 800;
        }
      }
    }

  }

  .focus-content{
    margin-top: 90px;
    .container-focus{
      position: static;
      bottom: auto;
      left: auto;
    }
  }

  .height-focus {
    height: 182px;
    position: relative;

    @media(max-width: 1279px){
      height: auto;
      margin-top: 40px;
    }
  }

  .container-list-items{
    margin-bottom: 0px;
    margin-top: 20px;

    @include breakpoint(laptop) {
      margin-top: 0;
      padding: 0;
    }
  }

  .container-axeo {
     @include breakpoint(tablet) {
       margin-bottom: 75px;
     }

     @include breakpoint(tablethor) {
       margin-bottom: 80px;
     }

     .container-reinsurance {
       position: relative;
       z-index: 2;
       padding: 80px 0 70px;

       @include breakpoint(desktop) {
         padding: 100px 0 60px;
       }
     }

    .btn-with-arrow-white{
      font-weight: 700;
      text-transform: none;
      font-size: 28px;
      float: none;
    }

 }