/* ==========================================================================
   Components / Reassurance List
   ========================================================================== */

  .container-reinsurance-list{
    margin-bottom: 60px;
    margin-top: 50px;

    @include breakpoint(tablet) {
      margin-bottom: 80px;
    }

    @include breakpoint(tablethor) {
      margin-bottom: 145px;
    }

    .reinsurance-item{
      width: calc(33.33% - 8px);
      display: block;
      float: left;
      text-align: center;
      position: relative;
      padding-left: 35px;
      padding-right: 35px;

      @include breakpoint(mobileonly){
        width: 100%;
        margin-bottom: 40px;
      }
      .icon{
        margin-bottom: 20px;
      }
      .h3{
        font-size: 28px;
        color: $orange;
        font-family: $special-font-family;
        margin-bottom: 20px;
        line-height: 31px;
        @media(max-width: 1023px){

        }
      }
      p{
        font-size: 15px;

      }
      & + .reinsurance-item{

        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: -1px;
          width: 4px;
          height: 140px;
          border-radius: 4px;
          background: #f6f6f5;
          @media(max-width: 767px){
            display: none;
          }
        }
      }
    }
  }