/* ==========================================================================
   Layout / Univers Axeo
   ========================================================================== */

  .univer {
    margin-bottom: 10px;
    height: 75vw;
    overflow: hidden;
    position: relative;
    display: block;
    border: 0 solid transparent;
    transition: all 0.2s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(smallMobile) {
      height: 95vw;
    }

    @include breakpoint(phablet) {
      height: 50vw;
    }

    @include breakpoint(tablet) {
      height: 40vw;
    }

    @include breakpoint(laptop) {
      height: 30vw;
    }

    @include breakpoint(desktop) {
      height: 25vw;
    }

    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: auto;
        min-height: 100%;
      }
    }

    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      display: block;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(30%, rgba(255,255,255,1)), color-stop(100%, rgba(0,0,0,1)));
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,1) 100%);
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,1) 100%);
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,1) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,1) 100%);

      @media(max-width: 1024px){
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.15) 50%, rgba(0,0,0,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(50%, rgba(255,255,255,1)), color-stop(100%, rgba(0,0,0,1)));
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.15) 50%, rgba(0,0,0,1) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.15) 50%, rgba(0,0,0,1) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.15) 50%, rgba(0,0,0,1) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 50%, rgba(0,0,0,1) 100%);
      }

      @media(max-width: 767px){
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.48) 99%, rgba(0,0,0,0.48) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.48) 99%,rgba(0,0,0,0.48) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.48) 99%,rgba(0,0,0,0.48) 100%);
      }
    }

    &.offer{
      height: 75vw;

      @include breakpoint(phablet) {
        height: 50vw;
      }

      @include breakpoint(tablet) {
        height: 40vw;
      }

      @include breakpoint(laptop) {
        height: 30vw;
      }

      @include breakpoint(desktop) {
        height: 25vw;
      }

      &:after{
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, black 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,1)), color-stop(100%, rgba(0,0,0,1)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, black 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, black 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, black 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 25%, black 100%);
      }

      @media(max-width: 767px){
        .ct-univer{
          .h2{
            display: none;
          }
        }
      }
      .univer-hover{
        &:after {
          color: #ffffff;
        }
        &:before {
          color: #000000;
        }
      }
    }

    &.univer-maintain-of-the-house { .ct-univer .univers-title:after {  background: $orange;  } }
    &.univer-exterior-gardens { .ct-univer .univers-title:after {  background: $green;  } }
    &.univer-seniors-autonomy { .ct-univer .univers-title:after {  background: $blue;  } }
    &.univer-housing { .ct-univer .univers-title:after {  background: $brown;  } }
    &.univer-child-care { .ct-univer .univers-title:after {  background: $pink;  } }
    &.univer-car-wash { .ct-univer .univers-title:after {  background: $purple;  } }

    .ct-univer{
      position: absolute;
      color: #fff;
      z-index: 8;
      padding-left: 20px;
      padding-bottom: 15px;
      padding-right: 15px;
      bottom: 10px;
      left: 0;
      transition: all 0.2s ease;

      @include breakpoint(phablet) {
        padding-left: 35px;
      }

      @include breakpoint(tablethor) {
        padding-left: 30px;
        padding-bottom: 30px;
        padding-right: 30px;
      }

      @include breakpoint(laptop) {
        bottom: 0;
      }

      .univers-title {
        font-size: 12px;
        font-weight: 700;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.45);
        letter-spacing: 0.2em;
        text-transform: uppercase;
        position: relative;
        line-height: 15px;
        padding-bottom: 8px;
        margin-bottom: 15px;
        display: inline-block;
        width: auto;

        &:after {
          content: "";
          height: 2px;
          width: 80%;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .h2{
        font-size: 26px;
        line-height: 1.1;
        margin-bottom: 5px;
        font-family: $special-font-family;
        text-shadow: 1px 0px 8px rgba(0, 0, 0, 0.32);

        @media (max-width: 1200px){
          font-size: 22px;
          line-height: 1.2;
          margin-bottom: 5px;
        }

        span {
          display: block;
        }
      }

      .h3, .h4 {
        max-width: 300px;
        font-size: 15px;
        line-height: 1.3;
        text-shadow: 1px 0 8px rgba(0, 0, 0, 0.32);
      }
    }

    @include breakpoint(laptop) {
      &:hover{
        transition: all 0.2s ease;
        border: 6px solid $orange;

        .univer-hover {
          &:after,
          &:before { left: 105px; transition: all 0.2s ease; }
          .txt{
            width: 100px;
            opacity: 1;
            transition: all 0.2s ease;
          }
        }

        .ct-univer{
          padding-left: 24px;
          padding-bottom: 24px;
          padding-right: 23.5px;
        }
      }
    }
  }

.univer-hover {
  text-shadow: 3px 0 6px rgba(0, 0, 0, 0.15);
  width: 0;
  text-transform: uppercase;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  position: relative;
  width: 45px;
  height: 45px;
  .txt {
    display: block;
    padding-top: 10px;
    opacity: 0;
    transition: all 0.2s ease;
  }

  &:after,
  &:before {
    font-family: 'icomoon';
    display: inline-block;
    font-size: 45px;
    width: 45px;
    height: 45px;
    margin-left: 0;
    transition: all 0.2s ease;
    position: absolute;
    left: 0;
    top: -10px;
    font-weight: lighter;
  }

  &:after {
    content: "\e91c";
  }
  &:before {
    content: "\e91d";
    z-index: 3;
  }
}

.overlay{
  &:hover{
    .univer-hover {
      &:after,
      &:before {
        transform: translateX(5px);
        transition: all 0.2s ease;
      }
      .txt{
        width: 100px;
        opacity: 1;
        transition: all 0.2s ease;
      }
    }
  }
}



  .container-univers {
    .h1-title-uppercase {
      margin-bottom: 25px;

       @include breakpoint(tablethor) {
         margin-bottom: 55px;
       }
    }
  }