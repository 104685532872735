/* ==========================================================================
   Layout / Footer
   ========================================================================== */

  .footer {
    margin-top: 45px;

    @include breakpoint(tablet) {
      margin-top: 90px;
    }

    .container-reinsurance {
      background: $light_grey;
      line-height: 210px;
      padding: 25px 0;

      @include breakpoint(tablet) {
        line-height: 150px;
      }

      .reinsurance {
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        line-height: normal;

        .icon {
          font-size: 68px;
          width: 100%;
          position: relative;
        }

        .txt {
          display: block;
          font-size: 16px;
          font-family: $special-font-family;
          margin-top: 25px;
        }
      }
    }

    .logo-footer {
      display: block;
      line-height: 1.2;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      @include breakpoint(tablethor) {
        display: flex;
      }

      img {
        width: 100%;
        display: inline-block;

        @include breakpoint(tablet) {
          width: 175px;
          height: 100%;
        }
      }
    }

    .mid-footer {
      margin-top: 0;

      @include breakpoint(tablet) {
        margin-top: 11px;
      }

      .title-mid-footer{
        width: 100%;
        float: left;
        text-align: center;

        @include breakpoint(tablet) {
          width: calc(50% - 5px);
        }

        & + .title-mid-footer{
          margin-left: 0;

          @include breakpoint(tablet) {
            margin-left: 10px;
          }
        }
      }
    }

    .newsletter-footer {
      padding: 0 15px;

      @include breakpoint(tablet) {
        padding: 0;
      }

      .logo-footer {
        @media(max-width: 767px) {
          width: 160px;
          height: auto;
        }
      }

      .form-news-letter {
        padding-top: 35px;

        @media(max-width: 767px) {
          padding-top: 15px;
        }
        .error {
          font-size: 13px;
          color: $red;
          margin-top: 20px;
          font-weight: 400;
          width: inherit;
          margin-left: 170px;
          @media (max-width: 767px) {
            margin-left: inherit;
          }
        }
        .success{
          font-size: 13px;
          color: $green;
          margin-top: 20px;
          font-weight: 400;
          margin-left: 25px;
          @media (max-width: 820px){
            margin-left: inherit;
          }
        }
        .form-line,
        .list-link {
          width: 49%;
          float: left;
          @media(max-width: 767px) {
            width: 100%;
          }
        }

        .form-line {
          text-align: center;
          @media(max-width: 767px) {
            text-align: left;
            padding: 0 5px;
          }

          .label {
            font-size: 17px;
            font-weight: 700;
            @media(max-width: 767px) {
              margin-bottom: 5px;
            }
          }

          .label,
          .input-ct {
            display: inline-block;
            vertical-align: middle;
            @media(max-width: 767px) {
              width: 100%;
              display: block;
            }
          }

          .input-ct {
            background: $light_grey;
            height: 49px;
            line-height: 46px;
            width: 50%;
            margin-left: 17px;

            @media(max-width: 767px) {
              width: 100%;
              display: block;
              margin-left: 0;
            }

            input {
              border: 0;
              background: transparent;
              width: calc(100% - 80px);
              padding-left: 10px;
              height: 45px;
              placeholder {
                font-size: 13px;
                color: $placeholder;
              }
            }

            .btn-submit-arrow {
              line-height: normal;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .list-link {
          text-align: center;
          margin-top: 12px;
          @include flexbox();

          @media(max-width: 767px) {
            margin-top: 0;
            margin-bottom: 0;
            display: block;
          }

          @media(max-width: 1200px) {
            margin-top: 0;
            margin-bottom: 12px;
          }

          a {
            display: block;
            width: 100%;
            height: 100%;
            &:hover {
              .txt {
                text-decoration: underline;
              }
            }
          }

          li {
            display: inline-block;
            vertical-align: middle;
            margin: 0px 5%;
            @media(max-width: 767px) {
              width: 100%;
              display: block;
              margin: 15px 0px;
            }
            span {
              display: inline-block;
              vertical-align: middle;
            }
          }

          .icon {
            font-size: 25px;
            color: $orange;
          }

          .txt {
            font-size: 17px;
            font-family: $special-font-family;
            margin-left: 15px;

            @media(max-width: 1330px) {
              display: block;
              margin-top: 10px;
              margin-left: 0;
              font-size: 15px;
            }
            @media(max-width: 767px) {
              margin-top: 0;
              margin-left: 15px;
              display: inline-block;
              font-size: 14px;
            }
          }
        }
      }

      .list-social {
        float: right;
        margin-bottom: 15px;
        margin-top: 10px;

        @media(max-width: 767px) {
          float: none;
          display: inline-block;
          width: 100%;
          text-align: center;
        }

        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 30px;

          @media(max-width: 767px) {
            margin-right: 0;
            margin: 0px 15px;
          }

          .icon {
            font-size: 32px;
            color: $text-color;
            &:before {
              transition: all 0.2s ease;
            }
          }

          a:hover .icon {
            color: #000;
          }
        }
      }
    }

    .list-seo {
      border-top: 3px solid #f6f6f5;
      padding: 50px 15px 25px;
      margin-bottom: 25px;

      @include breakpoint(tablet) {
        padding: 50px 0 25px;
        margin-bottom: 0;
      }

      @media(max-width: 767px) {
        border-top: 0px;
        padding-top: 30px;
        padding-bottom: 10px;
      }

      .seo-lists {
        display: block;

        @include breakpoint(tablethor) {
          @include flexbox();
        }

        .content-seo {
          display: block;
          width: 100%;
          text-align: left;

          @include breakpoint(phablet) {
            text-align: center;
          }

          @include breakpoint(tablethor) {
            text-align: left;
            @include flex-grow(1);
          }

          ul {
            display: none;

            @include breakpoint(tablethor) {
              display: block;
            }

            li {
              list-style-type: none;
              font-size: $base-font-size;
              color: #898988;
              margin-bottom: 5px;

              @include breakpoint(tablet) {
                font-size: 14px;
              }
            }
          }
        }

        .list-title {
          font-family: $special-font-family;
          color: #696969;
          margin-bottom: 15px;
          display: block;

          .txt {
            color: #696969;
          }

          .icon {
            display: none;
          }

          &:hover {
            text-decoration: underline;
          }

          @media(max-width: 767px) {
            padding: 5px;
            padding-right: 15px;
            padding-left: 0;
            margin-bottom: 0px;
            position: relative;
            cursor: pointer;

            .icon {
              position: absolute;
              top: 50%;
              right: 5px;
              display: inline-block;
              transform: translateY(-50%);
              font-size: 10px;
            }
          }
        }
      }
    }

    .last-footer {
      background: $light_grey;
      text-align: center;
      height: 63px;
      line-height: 60px;
    }

    .link-footer {
      text-align: center;
      height: 66px;
      line-height: 52px;
      padding: 0 15px;

      @include breakpoint(tablet) {
        padding: 0;
      }

      @media(max-width: 767px) {
        height: auto;
        line-height: normal;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      .list-icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 50px;

        @media(max-width: 767px){
          display: none;
        }

        li {
          display: inline-block;
          vertical-align: middle;
          padding: 0px 20px;
          width: 100px;

          a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;

            &:after {
              content: "";
              width: 0px;
              height: 0px;
              background: #ccc;
              transition: all 0.2s ease;
              border-radius: 50%;;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &:hover {
              &:after {
                width: 55px;
                height: 55px;
              }
            }

            .icon {
              position: relative;
              z-index: 2;
            }
          }
        }
      }
    }

    .list-footer {
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      @media(max-width: 767px) {
        margin-bottom: 50px;
        width: 100%;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        @media(max-width: 767px) {
          width: 100%;
        }

        &:last-child {
          a {
            &:after {
              display: none;
            }
          }
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          color: #898988;
          padding: 0 20px;
          font-size: 13px;
          position: relative;

          &:hover {
            text-decoration: underline;
          }

          &:after {
            content: "";
            width: 1px;
            height: 9px;
            display: inline-block;
            vertical-align: middle;
            background: #767676;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            @media(max-width: 767px) {
              display: none;
            }
          }

          @media(max-width: 767px) {
            text-align: left;
            padding: 2px 0px;
          }
        }
      }
    }
  }