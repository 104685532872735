/* ==========================================================================
   Components / Titles
   ========================================================================== */

  .h1-title {
    font-size: 47px;
    line-height: 1.1;
    font-family: $special-font-family;

    @media (max-width: 767px) {
      font-size: 35px;
      line-height: 1;
    }

    sup {
      font-size: 20px;
      position: relative;
      bottom: 20px;
    }
  }

  .h1-title-white {
    @extend .h1-title;
    color: $whiteColor;
    text-shadow: 3px 1px 8px rgba(0, 0, 0, 0.25);
  }

  .h1-title-uppercase {
    line-height: 1.1;
    font-family: $special-font-family;
    font-size: 45px;
    text-transform: uppercase;
    position: relative;
    padding-left: 35px;

    &:before {
      content: "";
      width: 8px;
      height: 100%;
      bottom: 0;
      top: -8px;
      border-radius: 4px;
      background-color: $text-color;
      background-clip: padding-box;
      left: 0;
      position: absolute;
    }
    &-research{
      line-height: 1.1;
      font-family: $special-font-family;
      font-size: 45px;
      text-transform: uppercase;
      position: relative;
      padding-left: 35px;
      &:before {
        content: "";
        width: 8px;
        height: 100%;
        bottom: 0;
        top: -8px;
        border-radius: 4px;
        background-color: $text-color;
        background-clip: padding-box;
        left: 0;
        position: absolute;
      }
    }

    @media (max-width: 767px) {
      font-size: 35px;
      line-height: 35px;
    }
  }

  .h1-title-uppercase-white {
    @extend .h1-title-uppercase;
    color: $whiteColor;

    &:before {
      background-color: $whiteColor;
    }
  }

  .h2-title {
    font-size: 33px;
    line-height: 1;
    font-family: $special-font-family;
    text-shadow: 3px 1px 10px rgba(0, 0, 0, 0.25);

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 1.3;
    }

    &-white {
      @extend .h2-title;
      color: $whiteColor;
    }
  }

  .h2-extrabold {
    font-size: 21px;
    font-weight: 900;
  }

  .h3-title {
    font-size: 24px;
    font-family: $special-font-family;
    letter-spacing: 0.02rem;

    @include breakpoint(tablet) {
      font-size: 26px;
      line-height: 1.3;
    }
  }

  .h4-title {
    font-size: 18px;
    font-weight: 700;
  }

  .h1-title-basket{
    text-transform: none;
    font-size: 30px;
    line-height: 1;
    font-family: $special-font-family;
    margin-top: 45px;
    margin-bottom: 20px;
    opacity: 0.7;

    @include breakpoint(tablethor) {
      font-size: 42px;
    }
  }

  .h1-basket-step {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 35px;
    line-height: 1.2;
    font-family: $special-font-family;
    padding: 20px;

    @include breakpoint(tablet) {
      padding: 40px 20px;
    }

    &:before{
      display: none;
    }
  }

  .h2-title-basket{
    font-family: 'Rimouski Semibold', sans-serif;
    color: #393835;
    font-size: 26px;
    margin-bottom: 30px;
  }

