@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

@font-face {
  font-family: 'icomoon';
  src:  url('./src/font/icomoon.eot?is8shn');
  src:  url('./src/font/icomoon.eot?is8shn#iefix') format('embedded-opentype'),
  url('./src/font/icomoon.woff2?is8shn') format('woff2'),
  url('./src/font/icomoon.ttf?is8shn') format('truetype'),
  url('./src/font/icomoon.woff?is8shn') format('woff'),
  url('./src/font/icomoon.svg?is8shn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rimouski Regular';
  src: url('./src/font/RimouskiRg-Regular.woff2') format('woff2'),
  url('./src/font/RimouskiRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rimouski Light';
  src: url('./src/font/RimouskiLt-Regular.woff2') format('woff2'),
  url('./src/font/RimouskiLt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rimouski Semibold';
  src: url('./src/font/RimouskiSb-Regular.woff2') format('woff2'),
  url('./src/font/RimouskiSb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rimouski Bold';
  src: url('./src/font/Luzern-BoldItalic.woff2') format('woff2'),
  url('./src/font/Luzern-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}