/* ==========================================================================
   Layout / Header
   ========================================================================== */

  .header {
    display: block;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative;

    @include breakpoint(tablet) {
      padding-bottom: 280px;
    }

    @include breakpoint(tablethor) {
      padding-top: 195px;
      padding-bottom: 320px;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }

    &.header-recruitment{
      padding-top: 110px;
      padding-bottom: 205px;
      min-height: 560px;
      overflow: hidden;
      background-position: center 30%;
      @media (max-width: 767px) {
        padding-top: 100px;
        padding-bottom: 100px;
        min-height: 300px;
      }
      .slider-recruitement{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .slider-item {
          img{
            @media(max-width: 1366px){
              max-width: none;
            }
            @media(max-width: 1279px){
              position: relative;
              left: 50%;
              transform: translateX(-50%)
            }
          }
        }
      }
      .slider-item{
        width: 100%;
        overflow: hidden;
      }
    }

    &-content {
      width: 95%;
      padding: 0 20px;
      max-width: 1440px;
      margin: auto;
      position: relative;
      z-index: 1;

      .breadcrumb {
        margin-bottom: 35px;

        @include breakpoint(tablet) {
          margin-bottom: 25px;
        }
      }

      .h1-recruitment,
      .h2-recruitment{
        color: #fff;
        text-align: center;
      }
      .h1-recruitment{
        font-size: 47px;
        line-height: 47px;
        font-family: 'Rimouski Semibold', sans-serif;
        text-transform: uppercase;
        margin-bottom: 3px;
        max-width: 800px;
        margin: 0px auto;
        @media (max-width: 767px) {
          font-size: 35px;
          line-height: 35px;
        }
        .orange{
          color: $orange;
        }
      }
      .h2-recruitment{
        font-size: 24px;
        font-weight: 100;
        margin-bottom: 37px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .introductionproduct {
        display: block;
        color: $whiteColor;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0.01rem;
        font-family: $special-font-familyReg;
        margin-top: 21px;

        @include breakpoint(tablet) {
          width: 80%;
          font-size: 23px;
        }
      }
    }

    .breadcrumb li {
      color: $whiteColor;

      a {
        color: $whiteColor;
      }
    }
  }