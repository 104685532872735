.navigation{
  .nav-recruitment{
    > ul{
      > li{
        position: relative;
       > a{
          border-bottom: 3px solid transparent;
          font-size: 14px;
          text-transform: uppercase;
          color: #ffffff;
          font-weight: 700;
          &:hover{
            border-bottom: 3px solid $orange;
          }
        }
        .sub-menu{
          float: none;
          display: none;
          width: auto;
          position: absolute;
          top: 100%;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          white-space: nowrap;
          padding: 10px 0px;
          li{
            a{
              height: 30px;
              line-height: 30px;
              border: 0px;
              font-size: 15px;
              font-weight: 700;
              padding: 0px 30px;
              &:hover{
                border: 0px;
                color: $orange;
              }
            }
          }
          &.active{
            display: block;
          }
          li{
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}

.container-actualities{
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
  .container-actuality{
    position: relative;
    overflow: hidden;
    margin-top: 40px;
  }
  .h3-title{
    margin-bottom: 6px;
  }
  .ct-overlay{
    position: relative;
    padding: 100px;
    padding-bottom: 60px;
    color: #fff;
    @media (max-width: 767px) {
      padding: 50px;
    }
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      height: 100%;
      z-index: 1;
    }
  }
  .ct-txt{
    position: relative;
    z-index: 2;
  }
  .btn-with-arrow{
    margin-top: 30px;
  }
}

.list-numbers{
  li{
    list-style: none;
    flex-basis: 100%;
    text-align: center;
    @media (min-width: 768px) {
      flex-basis: 50%;
      margin-bottom: 30px;
    }
    @media (min-width: 980px) {
      flex-basis: 20%;
    }
    @media (min-width: 1025px) {
      flex-basis: 20%;
    }
  }
  @include flexbox();
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 125px;
  @media (max-width: 767px) {
    display: block;
    li{
      margin: 40px 0px;
    }
  }
  .number{
    font-size: 35px;
    font-weight: 800;
    color: $orange;
    margin-top: 10px;
  }
  .txt{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-top: 3px;
    text-align: center;
  }
  span{
    display: inline-block;
    width: 100%;
    text-align: center;
    &.icon{
      height: 70px;
      img{
        height: 100%;
        width: auto;
      }
    }
    img{
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin: 0;
    }
  }
}

.container-offers{
  margin-bottom: 90px;
  .univer{
    &:hover{
      border-color: #fff;
    }
    .univer-hover{
      .txt{
        color: #fff;
      }
    }
  }
}

.container-list-recruitment{
  margin-bottom: 150px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  .univer{
    &.unsolicited-applications,
    &.become-franchisee{
      text-align: center;
      @media(max-width: 767px){
        height: 75vh;
        min-height: 350px;
      }
      .ct-univer{
        width: 100%;
        right: 0;
        max-width: none;
        bottom: auto;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
        @media(max-width: 767px){
        }
      }
      &:after{
        display: none;
      }
      img{
        object-fit: initial;
        @media(max-width: 767px) {
          position: absolute;
          object-fit: cover;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      h2{
        font-size: 45px;
        line-height: 45px;
        font-family: 'Rimouski Semibold', sans-serif;
        margin-bottom: 20px;
        @media(max-width: 767px){
          font-size: 30px;
          line-height: 30px;
        }
      }
      .btn{
        margin-top: 25px;
        display: inline-block;
      }
    }
    &.unsolicited-applications{
      background: #5f5f5f;
      background: -webkit-linear-gradient(-39deg, $orange_light, $orange_dark);
      background: -o-linear-gradient(-39deg, $orange_light, $orange_dark);
      background: -moz-linear-gradient(-39deg, $orange_light, $orange_dark);
      background: linear-gradient(-39deg, $orange_light, $orange_dark);
    }
    &.become-franchisee{
      background: #5f5f5f;
      background: -webkit-linear-gradient(-39deg, #636363, #4e4e4e);
      background: -o-linear-gradient(-39deg, #636363, #4e4e4e);
      background: -moz-linear-gradient(-39deg, #636363, #4e4e4e);
      background: linear-gradient(-39deg, #636363, #4e4e4e);
    }
  }
}

.job-types{
  @include flexbox();
  justify-content: center;
  @media(max-width: 1279px){
    display: block;
    text-align: center;
  }
  li{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 25px 1%;
    &.home-maintenance{
      a{
        background: $orange;
      }
    }
    &.gardens-exteriors{
      a{
        background: #95c11e;
      }
    }
    &.diy{
      a{
        background: #b49882;
      }
    }
    &.child-care{
      a{
        background: #e62075;
      }
    }
    &.seniors-autonomy{
      a{
        background: #009ee3;
      }
    }
    &.professional-cleaning{
      a{
        background: #a7a7a7;
      }
    }
    &.car-wash{
      a{
        background: #816ee2;
      }
    }
    a{
      padding: 5px 20px;
      display: inline-block;
      border-radius: 50px;
      transition: all 0.2s ease;
      border: 2px solid #fff;
      &:hover{
        background: transparent;
      }
      .icon,
      .txt{
        display: inline-block;
        vertical-align: middle;
      }
      .txt{
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
        text-transform: uppercase;
      }
    }
  }
}

.container-edito .container-white .img-full{
  margin: 0 auto;
  float: none;
  display: block;
  width: 800px;
  float: none;
  @media (max-width: 1023px) {
    width: 80%;
  }
}

.list-elem{
  display: block;
  float: left;
  width: 100%;
  .icon{
    float: none;
    margin: 0px auto 20px;
    @media (min-width: 768px) {
      float: left;
      margin-right: 33px;
      margin-bottom: 40px;
    }
  }
}

#page{
  .job-types{
    li{
      a{
        &:hover{
          background: rgba(0, 0, 0, 0.5) !important;
        }
        .icon{
          img{
            width: 30px;
            height: 25px;
          }
        }
      }
    }
  }
}