@charset 'UTF-8';

// Vendor
@import "vendor/mybootstrap";

// Base
@import 'base/fonts';
@import 'base/variables';
@import 'base/base';
@import 'base/flex';
@import 'base/mixins';
@import 'base/form';

// Components
@import "components/button";
@import "components/breadcrumb";
@import "components/icons";
@import "components/titles";
@import "components/search-bar";
@import "components/agency";
@import "components/advice";
@import "components/reassurance";
@import "components/modal-contact";
@import "components/aim";
@import "components/zebradatepicker";
@import "components/slick";
@import "components/slick-theme";

// Layout
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/universe';
@import 'layout/bloctext';
@import 'layout/offers';
@import 'layout/prestations';
@import 'layout/footer';

// Pages
@import "pages/home";
@import "pages/carrefour";
@import "pages/edito";
@import "pages/search-results";
@import "pages/product";
@import "pages/panier";
@import "pages/order";
@import "pages/login";
@import "pages/create";
@import "pages/home-account";
@import "pages/infosperso";
@import "pages/agencies";
@import "pages/partenaires";
@import "pages/configurator";
@import "pages/detail-prestation";
@import "pages/contact";
@import "pages/recruitment";
