/* ==========================================================================
   Page / Page Partenaire
   ========================================================================== */

  #page-partenaire {
    /* HEADER */
    .navigation {
      //height: 121px;
      background-color: $whiteColor;
      box-shadow: 2px 0 20px 0 rgba(0,0,0,0.15);
      transition: all $duration $easeInOutCirc;

      @include breakpoint(tablethor) {
        padding-top: 27px;
      }

      &.active {
        padding-top: 0;
        transition: all $duration $easeInOutCirc;

        .contact {
          display: none;
        }
        & + .search-results{
          .searchbar{
            top: 83px;
          }
        }
      }
      .btn-basket-CASTORAMA:hover{background: darken(#0078d7, 10)}
      .vam .icon{
        width: 65px;
        display: inherit;
        height: 50px;
      }

      .nav {
        background-color: $whiteColor;

        ul {
          width: calc(100% - 560px);

          a {
            transition: all $durationSmall $easeInOutQuart;

            &:hover {
              transition: all $durationSmall $easeInOutQuart;
              span {
                transition: all $durationSmall $easeInOutQuart;
              }
            }
            span {
              transition: all $durationSmall $easeInOutQuart;

              @include breakpoint(tablethor) {
                color: $dark_grey;
              }
            }
          }
        }
      }

      .logo {
        max-width: 300px;
        max-height: 95px;
        width: 100%;
        margin: 0;
        padding-right: 0;

        @include breakpoint(tablethor) {
          position: relative;
          bottom: 15px;
        }
        img {
          width: auto;
          height: auto;
          max-height: 95px;
          max-width: 100%;
          -ms-interpolation-mode: bicubic;
        }
      }

      .contact {
        font-size: 16px;
        font-weight: 700;
        color: $dark_grey;
        line-height: 1.6;
        padding: 0;
        position: absolute;
        top: 0;
        right: 20px;

        b {
          font-size: 15px;
          color: $orange;
          font-weight: 800;
        }
      }
    }

    .nav-mobile {
      background-color: $whiteColor;
      box-shadow: 2px 0 20px 0 rgba(0,0,0,0.15);

      .logo {
        max-width: 300px;
        max-height: 60px;
        width: 100%;
        margin: 0;
        padding-right: 0;
        padding-top: 4px;

        img {
          width: auto;
          height: auto;
          max-height: 50px;
          max-width: 100%;
          -ms-interpolation-mode: bicubic;
        }
      }
      .contact-us-disconect{
        display: inline-block;
        @include breakpoint(mobileonly){
          font-size: 12px;
        }
        @media (max-width: 328px) {
          font-size: 11px;
        }
      }
      @include breakpoint(mobileonly) {
        .logo {
          max-width: 110px;
          max-height: 70px;
          width: 100%;
          margin: 0;
          padding-right: 0;
          padding-top: 4px;
          &-disconect{
            @include breakpoint(mobileonly){
              margin-left: 3%;
              margin-right: 5%;
            }
          }
          img {
            width: auto;
            height: auto;
            max-height: 50px;
            max-width: 100%;
            -ms-interpolation-mode: bicubic;
          }
        }
      }

      .btn-burger > span {
        background: $dark_grey;

        &:after, &:before {
          background: $dark_grey;
        }
      }

      .btn-burger.active span {
        background: white;
      }
    }

    .list-item-menu-mobile{
      .line-height{
        .icon{
          img{
            width: 100%;
          }
        }
      }
    }

    .search-results {
      .header {
        background-position: top left;
        padding-top: 65px;
        padding-bottom: 20px;
        position: relative;

        @include breakpoint(tablet) {
          padding-top: 250px;
          padding-bottom: 260px;
        }

        @include breakpoint(tablethor) {
          padding-top: 320px;
          padding-bottom: 285px;
        }

        &-content {
          margin-bottom: 10px;

          @include breakpoint(tablet) {
            margin-bottom: 35px;
          }
        }
      }

      .container-white {
        @include breakpoint(tablet) {
          padding: 20px 20px 120px;
        }
      }

      .searchbar {
        @include breakpoint(tablet) {
          top: 65px;
        }

        @include breakpoint(tablethor) {
          top: 121px;
        }
      }
    }

    .input-ct{
      position: relative;
    }

    .clear-search{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      .plus{
        display: block;
        font-size: 25px;
        transform: rotate(45deg);
      }
    }

    /* GLOBAL */
    .univer-hover {
      &:after {
        background: url("./src/img/icone-fleche-seule.svg") center center/contain no-repeat;
        transition: all 0.2s ease;
        left: 1px;
        z-index: 1;
      }

      &:before {
        content: ' ';
        display: block;
        width: 36px;
        height: 36px;
        background: $orange;
        border-radius: 8px;
        transform: rotate(45deg);
        transition: all 0.2s ease;
        position: absolute;
        top: 4px;
        left: 4px;
        bottom: 0;
        z-index: 0;
      }
    }

    .univer:hover .univer-hover {
      &:before {
        left: 108px;
        transition: all 0.2s ease;
      }

      &:after {
        left: 105px;
        transition: all 0.2s ease;
      }
    }

    /* Page de résultats */

    .aim {
      padding: 5px 0 20px;

      p {
        padding: 0;
      }
    }

    .btn-with-arrow {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      margin-left: 2%;
      text-align: center;
      position: relative;

      @include breakpoint(phablet) {
        margin-left: 25%;
      }

      @include breakpoint(tablet) {
        display: table;
        text-align: left;
        margin-left: 0;
        margin-right: 10px;
      }

      &:after {
        background: url("./src/img/icone-fleche-seule.svg") center center/contain no-repeat;
        left: 3px;
        position: relative;
        z-index: 1;
      }

      &:before {
        content: ' ';
        display: block;
        width: 35px;
        height: 35px;
        background: $orange;
        border-radius: 8px;
        transform: rotate(45deg);
        transition: all $duration $easeInOutCirc;
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        z-index: 0;
      }

      &:hover {
        &:before {
          transform: rotate(45deg) translateX(5px) translateY(-5px);
          transition: all $duration $easeInOutCirc;
        }

        &:after {
          transform: translateX(7px);
        }
      }
    }

    .container-univers {
      .btn-with-arrow {
        margin: 20px auto;
        margin-left: 20%;

        @include breakpoint(phablet) {
          margin-left: 34%;
        }

        @include breakpoint(tablet) {
          margin-left: 0;
          float: right;
          text-align: right;
        }

        &:after {
          transform: rotate(90deg);
        }

        &:hover {
          &:before {
            transform: rotate(45deg) translateX(3px) translateY(3px);
            transition: all $duration $easeInOutCirc;
          }

          &:after {
            transform: rotate(90deg) translateX(5px);
          }
        }
      }
    }

    .container-prestations {
      width: 98%;

      @include breakpoint(tablet) {
        width: 95%;
      }

      @include breakpoint(tablethor) {
        width: 90%;
      }

      @media (min-width: 1499px) {
        width: 1440px;
      }


      .h1-title-uppercase {
        margin-bottom: 30px;

        @include breakpoint(tablet) {
          margin-bottom: 60px;
        }
      }

      .prestations {
        width: 100%;
      }

      .prestation {
        .ct-icon {
          width: 63px;
          height: 61px;
          position: relative;
          @include breakpoint(mobileonly){
            margin-right: 0;
          }

          &:after {
            content: ' ';
            display: block;
            width: 45px;
            height: 45px;
            background: $orange;
            border-radius: 8px;
            transform: rotate(45deg);
            position: absolute;
            top: 7px;
            left: 7px;
            bottom: 0;
            z-index: 0;
          }

          img {
            width: 45%;
            height: auto;
            position: relative;
            top: 15px;
            left: 15px;
            z-index: 2;
          }
        }

        &-title {
          width: 100%;

          .text {
            width: 70%;
            font-size: 14px;

            @include breakpoint(smallMobile) {
              width: 64%;
            }
          }

          @include breakpoint(tablet) {
            width: 45%;
          }
        }

        //&-date {
        //  //width: 100;
        //  text-align: center;
        //  @media (max-width: 800px) {
        //    margin-left: 5%;
        //  }
        //  @media (max-width: 725px) {
        //    width: 100%;
        //    text-align: left;
        //    margin-left: calc(100% - (100% - 63px - 20px) );
        //  }
        //  @include breakpoint(mobileonly){
        //    margin-left: calc(100% - (100% - 63px));
        //  }
        //  @include breakpoint(813px){
        //    margin-left: 5%;
        //    margin-right: 5%;
        //  }
        //}
        //
        //&-state {
        //  @include breakpoint(916px) {
        //    margin: 0 3% 0 0;
        //  }
        //  @include breakpoint(mobileonly){
        //    width: 100%;
        //    margin-left: calc(100% - (100% - 63px) );
        //    text-align: left;
        //  }
        //  @include breakpoint(phablet){
        //    width: 100%;
        //    margin-left: calc(100% - (100% - 63px - 20px) );
        //    text-align: left;
        //  }
        //  @include breakpoint(726px){
        //    width: auto;
        //    margin-left: 5%;
        //  }
        //}
        //&-price{
        //  @include breakpoint(mobileonly){
        //    width: 100%;
        //    margin-left: calc(100% - (100% - 63px) );
        //    text-align: left;
        //  }
        //  @include breakpoint(phablet){
        //    width: 100%;
        //    margin-left: calc(100% - (100% - 63px - 20px) );
        //    text-align: left;
        //  }
        //  @include breakpoint(726px){
        //    width: auto;
        //    margin-left: 5%;
        //  }
        //  @include breakpoint(813px){
        //    margin-right: 5%;
        //  }
        //
        //}
        //&-info{
        //  width: 30%;
        //  @include breakpoint(mobileonly){
        //    width: calc(100% - 65px);
        //  }
        //  @media (max-width: 798px) {
        //    width: 34%;
        //  }
        //  @media (max-width: 725px) {
        //    width: calc(100% - 85px);
        //  }
        //
        //}

        .btn-with-arrow {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          text-align: center;
          margin: 9px auto;
          position: relative;
          float: inherit;

          &:after {
            background: url("./src/img/icone-fleche-seule.svg") center center/contain no-repeat;
            left: 2px;
            position: relative;
            z-index: 1;
          }

          &:before {
            content: ' ';
            display: block;
            width: 35px;
            height: 35px;
            background: $orange;
            border-radius: 8px;
            transform: rotate(45deg);
            transition: all $duration $easeInOutCirc;
            position: absolute;
            top: 2px;
            right: 0;
            bottom: 0;
            z-index: 0;
          }

          &:hover {
            &:before {
              transform: rotate(45deg) translateX(5px) translateY(-5px);
              transition: all $duration $easeInOutCirc;
            }

            &:after {
              transform: translateX(7px);
            }
          }
        }

        &:hover {
          .btn-with-arrow {
            &:before {
              transform: rotate(45deg) translateX(5px) translateY(-5px);
              transition: all $duration $easeInOutCirc;
            }

            &:after {
              transform: translateX(7px);
            }
          }
        }
      }

      .btn-with-arrow {
        @include breakpoint(tablet) {
          display: block;
          float: right;
          text-align: right;
        }
      }
    }

    /* Pages agence détails*/
    .container-agency-info {
      padding: 0;

      @include breakpoint(phablet) {
        padding: 0px 15px 0;
      }

      @include breakpoint(tablethor) {
        padding-top: 0px;
      }
      .list-prestations{
        margin-top: 30px;

        li{
          font-size: 21px;
          font-family: 'Rimouski Semibold', sans-serif;
          margin-bottom: 30px;
          a {
            display: flex;
            align-items: center;
            .ct-icon {
              width: 63px;
              height: 61px;
              display: inline-block;
              margin-right: 15px;

              &:after,
              .diamond {
                content: none;
                display: block;
                width: 45px;
                height: 45px;
                background: $orange;
                border-radius: 8px;
                transform: rotate(45deg);
                position: absolute;
                top: auto;
                left: 1.5%;
                bottom: auto;
                margin-top: 1.4%;
                z-index: 0;
              }

              .diamond {
                z-index: 1;
              }
              img{
                left: 9px;
                top: 6px;
              }
            }
          }
        }
      }
  }
  /* page product */
    .product .h2-title{
      margin-top: 28px;
      margin-bottom: 55px;
      font-size: 25px;
      line-height: 1.5;
    }
    .product .h3-title{
      margin-bottom: 25px;
    }
    .container-fluid .btn-main{
      margin-top: 50px;
    }
      /* Footer */

    .footer {
      background: #f6f6f5;
      padding: 35px 0 25px;
      text-align: center;
      margin-top: 65px;

      @include breakpoint(phablet) {
        text-align: left;
        padding: 40px 25px 25px;
      }

      @include breakpoint(laptop) {
        padding: 30px 0 25px;
      }

      .container {
        padding: 0;
      }

      &-legal {
        display: block;
        margin: auto;
        margin-bottom: 35px;

        @include breakpoint(phablet) {
          display: inline-block;
          margin-bottom: 0;
        }

        b {
          display: block;
          font-family: $special-font-family;
          font-weight: 300;
          color: #696969;
          font-size: 16px;
          margin-bottom: 20px;

          @include breakpoint(phablet) {
            margin-bottom: 30px;
          }

          @include breakpoint(laptop) {
            margin-bottom: 40px;
          }
        }

        ul li {
          margin-bottom: 5px;

          @include breakpoint(phablet) {
            display: inline-block;
          }

          a {
            color: #898988;
            opacity: 0.7;
            font-size: 13px;
            transition: all $duration $easeInOutQuart;

            &:hover {
              color: $text-color;
              opacity: 1.0;
              transition: all $duration $easeInOutQuart;
            }
          }

          &:after {
            display: inline-block;
            margin: 0 20px;
            width: 2px;
            height: 10px;
            background: #898988;
            opacity: 0.4;

            @include breakpoint(phablet) {
              content: ' ';
            }
          }

          &:last-child:after {
            display: none;
          }
        }
      }

      &-logo {
        display: block;
        margin: auto;
        width: 105px;
        height: 72px;

        @include breakpoint(phablet) {
          display: inline-block;
          float: right;
        }

        @include breakpoint(laptop) {
          width: 125px;
          height: 85px;
        }
      }
      #infos-perso{
        #birthdate{
          width: 100%;
          .Zebra_DatePicker_Icon{
            right: 10px !important;
          }
        }
      }
    }

    .modal{
      .input-ct{
        background: #f4f4f4;
        padding: 10px;
        input{
          background: transparent;
          border: 0;
          width: calc(100% - 30px);
        }
      }
      .icon-cluster{
        color: $orange;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .ct-icon {
      width: 63px;
      height: 61px;
      display: inline-block;
      position: relative;

      &:after,
      .diamond{
        content: ' ';
        display: block;
        width: 45px;
        height: 45px;
        background: $orange;
        border-radius: 8px;
        transform: rotate(45deg);
        position: absolute;
        top: 7px;
        left: 7px;
        bottom: 0;
        z-index: 0;
        @media (max-width: 575px){
          top: 40px;
          left: 15px;
        }
      }

      .diamond{
        z-index: 1;
      }

      img {
        width: 45%;
        height: auto;
        position: relative;
        top: 15px;
        left: 15px;
        z-index: 2;
        @include breakpoint(mobileonly){
          top: 47px;
          left: 24px;
        }
        @media (max-width: 415px) {
          top: 47px;
          left: 23px;
        }
      }
    }
    .prestation{
      &-info{
        width: 30%;
        @include breakpoint(mobileonly){
          width: calc(100% - 65px);
        }
        @media (max-width: 798px) {
          width: 34%;
        }
        @media (max-width: 767px) {
          width: calc(100% - 85px);
        }
      }
      &-date {
        //width: 100;
        text-align: center;
        @media (max-width: 800px) {
          margin-left: 5%;
        }
        @media (max-width: 767px) {
          width: 100%;
          text-align: left;
          margin-left: calc(100% - (100% - 63px - 20px) );
        }
        @include breakpoint(813px){
          margin-left: 5%;
          margin-right: 5%;
        }
        @include breakpoint(mobileonly){
          margin-left: calc(100% - (100% - 63px));
        }
      }

      &-state {
        @include breakpoint(916px) {
          margin: 0 3% 0 0;
        }
        @include breakpoint(mobileonly){
          width: 100%;
          margin-left: calc(100% - (100% - 63px) );
          text-align: left;
        }
        @include breakpoint(phablet){
          width: 100%;
          margin-left: calc(100% - (100% - 63px - 20px) );
          text-align: left;
        }
        @include breakpoint(768px){
          width: auto;
          margin-left: 5%;
        }
      }
      &-price{
        @include breakpoint(mobileonly){
          width: 30px;
          margin-left: calc(100% - (100% - 63px) );
          text-align: left;
        }
        @include breakpoint(phablet){
          width: 30px;
          margin-left: calc(100% - (100% - 63px - 20px) );
          text-align: left;
        }
        @include breakpoint(768px){
          margin-left: 5%;
        }
        @include breakpoint(813px){
          margin-right: 5%;
        }

      }
    }
    #listPrestations{
      .prestation-date, .prestation-price, .prestation-state{
        @include breakpoint(mobileonly){
          margin-left: calc(100% - (100% - 83px));
        }
      }
    }

    .container-sticked-content {

      .ct-head{
        @media(max-width: 1279px){
          display: block !important;
        }
      }

      &.fixed{
        position: fixed;
        top: 133px;
      }

      .aside{
        .agencies{
          padding: 30px;
          .h3-title{
            width: calc(100% - 35px);
            margin-bottom: 0;
            vertical-align: middle;
            display: inline-block;
          }
        }
        .ct-text{
          display: none;
          @media(max-width: 1279px){
            display: block;
          }
        }
      }
    }

    .btn-chevron{
      @media(max-width: 1279px){
        display: none;
      }
    }

    .step-container-price{
      @media (max-width: 1023px){
        margin-bottom: 100px;
      }
    }
    .validCommandMobile{
      left: inherit;
      .ct-btn .btn-main-black-lite{
        margin: 0px 15px;
      }
    }

  }

  .pac-container { z-index: 100000; }
