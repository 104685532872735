/* ==========================================================================
   Layout / Menu Navigation
   ========================================================================== */

  .navigation {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 30;

    /* NAVIGATION WITH BACKGROUND IMAGE IF NO HEADER */
    &.visuel {
      background: url('./src/img/visuel-menu.jpg') 80% center/ cover no-repeat;

      @include breakpoint(phablet) {
        background: url('./src/img/visuel-menu.jpg') center center/ cover no-repeat;
      }
    }

    &.active {
      .nav {
        height: 83px;
        line-height: 80px;

        .logo img {
          width: 98px;
        }

        .btn-basket,
        .btn-login {
          height: 83px;
          line-height: 80px;
        }
      }

      .logo{
        width: 100px;
      }
    }

    /* PRE HEADER */
    .preheader {
      height: 49px;
      line-height: 46px;
      padding: 0 0 0 5px;
      border-bottom: 2px solid $orange;
      background: $backgroundLight;

      .f-left {
        float: left;
      }

      .f-right {
        float: right;
      }

      .btn, .ct-contact {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
      }

      .btn-particular,
      .btn-professional,
      .btn-franchise {
        height: 40px;
        padding: 0 20px;
        line-height: 40px;
        color: $whiteColor;
        text-transform: uppercase;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        transition: background $duration $easeOutCubic;

        &.active {
          background: $orange;
          vertical-align: bottom;
          height: 42px;
          margin-top: 5px;
        }

        &:not(.active):hover {
          background: #5f5f5f;
          background: -webkit-linear-gradient(-39deg, $orange_light, $orange_dark);
          background: -o-linear-gradient(-39deg, $orange_light, $orange_dark);
          background: -moz-linear-gradient(-39deg, $orange_light, $orange_dark);
          background: linear-gradient(-39deg, $orange_light, $orange_dark);
          transition: background $duration $easeOutCubic;
        }
      }

      .btn-particular {
        background: #c2c0c0; /* Old browsers */
        background: -moz-linear-gradient(39deg, #c2c0c0 0%, #969694 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(39deg, #c2c0c0 0%,#969694 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(39deg, #c2c0c0 0%,#969694 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        transition: background $duration $easeOutCubic;
      }

      .btn-professional {
        background: #c2c0c0;
        background: -moz-linear-gradient(-39deg, #c2c0c0 0%, #969694 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-39deg, #c2c0c0 0%,#969694 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-39deg, #c2c0c0 0%,#969694 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        transition: background $duration $easeOutCubic;
      }

      .btn-franchise {
        background: #5f5f5f;
        background: -webkit-linear-gradient(left, #5f5f5f, #383838);
        background: -o-linear-gradient(right, #5f5f5f, #383838);
        background: -moz-linear-gradient(right, #5f5f5f, #383838);
        background: linear-gradient(to right, #5f5f5f, #383838);
        transition: background $duration $easeOutCubic;
      }

      .ct-contact {
        position: relative;
        height: 47px;
        vertical-align: top;

        .menu-contact {
          display: none;
          width: 100%;
          position: absolute;
          top: calc(100% - 1px);
          background: $backgroundLight;
          box-shadow: 1px 4px 11px 0 rgba(0, 0, 0, 0.2);
          left: 0;
          z-index: 3;

          .contact-us, .help-us {
            border-top: 1px solid $light_grey;
          }

          .be-called {
            text-align: center;
            border-top: 1px solid $light_grey;
            padding: 15px 0px;
          }

          .call, .service-customer {
            padding: 13px 10px;
            padding-left: 25px;
          }

          .call {
            p {
              line-height: normal;
              font-size: 14px;
            }

            .phone-number {
              margin-top: 2px;

              span {
                font-size: 15px;
                font-weight: 700;
              }
              .icon {
                color: $orange;
              }
            }
          }

          .service-customer {
            p {
              line-height: normal;
              font-size: 14px;
            }
            .schedule {
              font-size: 15px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .removeSpaceArround{
      justify-content: left !important;
    }
    /* NAVIGATION */
    .nav {
      width: 100%;
      height: 94px;
      line-height: 91px;
      padding-left: 20px;
      background: rgba(0, 0, 0, 0.5);
      //transition: all 0.2s ease;

      .logo {
        display: inline-block;
        width: 120px;
        transition: all 0.2s ease;

        img {
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          height: auto;
          transition: all 0.2s ease;
        }

        @media(max-width:1366px) {
          width: 100px;
        }
      }

      ul {
        display: inline-block;
        float: right;
        width: calc(100% - 150px);
        margin-bottom: 0;
        @include flexbox();
        @include justify-content(space-around);

        @include breakpoint(tablethor) {
          width: calc(100% - 350px);
        }

        @include breakpoint(desktop) {
          width: calc(100% - 375px);
        }

        //@include breakpoint(1380) {
        //  width: calc(100% - 360px);
        //}

        li {
          display: inline-block;
          vertical-align: middle;
        }

        a {
          color: $whiteColor;
          display: block;
          width: 100%;
          height: 100%;
          transition: all 0.2s ease;
          border-bottom: 3px solid transparent;
          position: relative;
          padding: 0px 15px;

          .icon{
            position: relative;

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 0px;
              height: 0px;
              border-radius: 6px;
              transform: rotate(45deg) translate(-50%, -50%);
              transform-origin: 0 0;
              transition: all 0.2s ease;
            }

            &:before{
              z-index: 2;
              position: relative;
            }
          }

          &.active,
          &:hover{
            .icon {
              &:after {
                width: 44px;
                height: 44px;
              }
            }
          }

          .line-height {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            position: relative;
            z-index: 2;

            .icon{
              width: 30px;
              height: 32px;

              img{
                vertical-align: middle;
                position: relative;
                z-index: 3;
                bottom: 3px;
                width: 100%;
              }
            }
          }

          &.univer-maintain-of-the-house {
            .icon {
              &:after {
                background: rgb(243, 141, 0);
                background: -moz-linear-gradient(45deg, rgba(243, 141, 0, 1) 0%, rgba(247, 162, 0, 1) 100%);
                background: -webkit-linear-gradient(45deg, rgba(243, 141, 0, 1) 0%, rgba(247, 162, 0, 1) 100%);
                background: linear-gradient(45deg, rgba(243, 141, 0, 1) 0%, rgba(247, 162, 0, 1) 100%);
              }
            }
          }
          &.univer-seniors-autonomy {
            .icon {
              &:after {
                background: rgb(21,166,229);
                background: -moz-linear-gradient(45deg, rgba(21,166,229,1) 0%, rgba(86,190,234,1) 100%);
                background: -webkit-linear-gradient(45deg, rgba(21,166,229,1) 0%,rgba(86,190,234,1) 100%);
                background: linear-gradient(45deg, rgba(21,166,229,1) 0%,rgba(86,190,234,1) 100%);
              }
            }
          }
          &.univer-child-care {
            .icon {
              &:after {
                background: rgb(232,34,108);
                background: -moz-linear-gradient(45deg, rgba(232,34,108,1) 0%, rgba(233,57,109,1) 100%);
                background: -webkit-linear-gradient(45deg, rgba(232,34,108,1) 0%,rgba(233,57,109,1) 100%);
                background: linear-gradient(45deg, rgba(232,34,108,1) 0%,rgba(233,57,109,1) 100%);
              }
            }
          }
          &.univer-exterior-gardens {
            .icon {
              &:after {
                background: rgb(158,199,45);
                background: -moz-linear-gradient(45deg, rgba(158,199,45,1) 0%, rgba(183,218,90,1) 100%);
                background: -webkit-linear-gradient(45deg, rgba(158,199,45,1) 0%,rgba(183,218,90,1) 100%);
                background: linear-gradient(45deg, rgba(158,199,45,1) 0%,rgba(183,218,90,1) 100%);
              }
            }
          }
          &.univer-housing {
            .icon {
              &:after {
                background: rgb(184,159,139);
                background: -moz-linear-gradient(45deg, rgba(184,159,139,1) 0%, rgba(197,178,164,1) 100%);
                background: -webkit-linear-gradient(45deg, rgba(184,159,139,1) 0%,rgba(197,178,164,1) 100%);
                background: linear-gradient(45deg, rgba(184,159,139,1) 0%,rgba(197,178,164,1) 100%);
              }
            }
          }

          span {
            font-size: 14px;
            text-transform: uppercase;
            color: $whiteColor;
            font-weight: 700;

            @media(max-width: 1463px) {
              font-size: 12px;
            }

            @media(max-width:1333px) {
              font-weight: 600;
              width: 100%;
              text-align: center;
              font-size: 11px;
            }
          }

          .txt{
            position: relative;
            z-index: 2;
          }

          .txt,
          .icon {
            display: inline-block;
            vertical-align: middle;
          }

          .icon {
            font-size: 29px;
            margin-right: 8px;
            @media(max-width: 1280px) {
              margin-bottom: 5px;
              margin-right: 0;
            }
          }
          .icon-garde{
            padding-right: 5px;
          }
        }
      }
    }

    /* RESPONSIVE MENU */
    .nav-mobile {
      height: 65px;
      line-height: 56px;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: relative;
      z-index: 2;

      .logo {
        display: inline-block;
        width: 80px;
        height: 60px;
        vertical-align: middle;

        img {
          height: 60px;
        }
      }
    }

    .mobile-menu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.9);
      padding-top: 65px;
      padding-bottom: 30px;
      overflow: auto;
      transition: all 0.2s ease;
      transform: translateX(-100%);

      @include breakpoint(tablethor) {
        display: none;
      }

      &.active {
        transform: translateX(0%);
      }

      .contact-us {
        border-top: 0px;
        padding: 4vh 10vw;
        .txt {
          font-size: 17px;
          font-weight: 700;
          color: $whiteColor;
          @media(max-width: 360px){
            font-size: 15px;
          }
        }
        .number {
          font-size: 18px;
          font-weight: 700;
          color: #f39200;
          @media(max-width: 360px){
            font-size: 16px;
          }
        }

      }

      .list-btn-entrance {
        width: 100%;
        border-bottom: 2px solid $orange;
        li {
          width: 47%;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          margin-left: 1%;

          a {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            color: $whiteColor;
            height: 38px;
            line-height: 38px;
            margin-top: 15px;
            margin-bottom: 3px;

            &.active {
              background: $orange;
              border-bottom: 3px solid $orange;
              height: 41px;
              vertical-align: bottom;
              line-height: 41px;
              margin-top: 14px;
              margin-bottom: 0;
            }
          }

          .btn-professional {
            background: rgb(0, 158, 227);
            background: -moz-linear-gradient(left, rgba(0, 158, 227, 1) 0%, rgba(106, 197, 236, 1) 100%);
            background: -webkit-linear-gradient(left, rgba(0, 158, 227, 1) 0%, rgba(106, 197, 236, 1) 100%);
            background: linear-gradient(to right, rgba(0, 158, 227, 1) 0%, rgba(106, 197, 236, 1) 100%);
          }
        }
      }

      .menu {
        > li {
          padding: 4vh 10vw 0;
          > a{
            font-weight: 700;
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            .icon{
              transform: rotate(44deg);
              padding: 3px;
              width: 30px;
              height: 30px;
              img{
                transform: rotate(-45deg);
              }
            }
          }

          .icon{
            width: 30px;
          }

          .txt,
          .icon {
            color: $whiteColor;
          }

          .txt {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
          }

          .icon {
            font-size: 26px;
            margin-right: 20px;
          }
        }
        .sub-menu{
          a{
            color: #fff;
            &:hover{
              color: $orange;
            }
          }
          li{
            padding: 4vh;
            @media (max-width: 767px){
              padding: 2vh;
            }
          }
        }
      }

    }
  }