/* ==========================================================================
   Components / Modal Contact
   ========================================================================== */

  .modal-dialog {
    width: 95%;
    margin: auto;
    max-width: 630px;
    min-height: 95%;
    max-height: 95%;
    padding-top: 30px;
    align-items: baseline;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      top: 20%;
    }

    @include breakpoint(tablet) {
      align-items: center;
      padding-top: 0;
    }

    &.modal-lg {
      max-width: 765px;
    }
  }

  .modal-content {
    border-radius: 0px;;
    padding: 30px 20px;

    @include breakpoint(phablet) {
      padding: 30px 40px 30px;
    }

    .modal-title {
      width: 85%;
      font-size: 21px;
      font-family: $font-family-base;
      font-weight: 800;

      .orange {
        color: $orange;
      }
    }

    .well {
      display: none;
    }

    .Zebra_DatePicker {
      background: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 0 0 transparent;
      border-radius: 0px;;
      padding: 0 15px 15px;
      width: 100% !important;
      .dp_body th {
        cursor: auto;
      }
      .dp_body th,
      .dp_body td {
        font-size: 14px;
        border: 0;
        background: transparent;
        color: #fff;
        padding: 7px;
        background: url(./src/img/icone-date-active.svg) center center / 0px 0px no-repeat;
        &.dp_selected {
          background: url(./src/img/icone-date-active.svg) center center / 31px 31px no-repeat;
          transition: all 0.2s ease;
        }
        &.dp_disabled {
          cursor: auto;
          opacity: 0;
        }
      }
      .dp_weekend {
        background: transparent;
        color: #fff;
      }
      .dp_caption {
        border-radius: 0px;;
        padding: 18px 5px 18px;
      }
      .dp_header {
        border-bottom: 0;
      }
      .dp_actions {
        td {
          border-radius: 0px;;
        }
      }
    }

    .hour {
      margin-bottom: 30px;

      .between,
      .content-h {
        display: inline-block;
        vertical-align: middle;
      }
      .between {
        font-size: 16px;
        color: #393939;
      }
      input {
        border: 0;
        display: inline-block;
        width: 35px;
        line-height: normal;
      }
      .content-h {
        border: 1px solid #9c9b9a;
        border-radius: 3px;
        width: 93px;
        height: 55px;
        line-height: 55px;
        margin: 0 10px;

        @include breakpoint(smallMobile) {
          margin: 0 2px;
        }

        @include breakpoint(phablet) {
          margin: 0 6px;
        }
      }
      #from-hour,
      #to-hour {
        text-align: right;
      }
      .letter {
        color: #a0a0a0;
        text-transform: uppercase;
        font-family: 'opensans-semibold', sans-serif;
      }
    }

    .modal-header {
      position: relative;

      .btn-close-modal {
        position: absolute;
        top: -5px;
        right: 0;
        background: transparent;

        .icon {
          font-size: 16px;
        }
      }
    }

    .modal-body {
      padding-top: 40px;

      .btn{
        margin-top: 30px;
      }
      .error{
        font-size: 13px;
        color: $red;
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .oontainer-date .error{
        margin-top: 50px;
      }
    }

    .oontainer-date {
      display: block;
      width: 100%;

      @include breakpoint(800px) {
        width: 350px;
        float: left;
      }
    }

    .container-hours {
      display: block;
      width: 100%;
      margin-top: 60px;

      @include breakpoint(800px) {
        width: calc(100% - 350px);
        margin-top: 0;
        float: left;
        padding-left: 30px;
      }
    }

    .dp_header {}
    .label-title {
      display: block;
      margin-bottom: 15px;
    }

    .phone-number {
      width: 81%;
      border: 1px solid #9c9b9a;
      border-radius: 3px;
      height: 54px;
      padding: 10px;

      @include placeholder {
        text-transform: uppercase;
        color: #a0a0a0;
        opacity: 1;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 800;
      }
    }

    .ct-recaptcha {
      margin-top: 35px;
      margin-bottom: 16px;
    }

    .required-fields {
      font-size: 12px;
      color: $text-color;
    }

    .modal-footer {
      padding-top: 40px;
    }

    .form-be-called {
      input[type="radio"] {
        position: absolute;
        left: -999px;
      }
      .form-line {
        & + .form-line {
          margin-top: 28px;
        }
      }
      .label {
        display: block;
        position: relative;
        padding-left: 35px;
        font-size: 16px;
        cursor: pointer;

        strong {
          font-weight: 700;
        }
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 3px;
          left: 0;
          border-radius: 50%;
        }
        &:before {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #9e9e9c;
        }
        &:after {
          width: 12px;
          height: 12px;
          margin: 4px;
          background-color: $orange;
          display: none;
        }
      }
      input:checked + label:after {
        display: block;
      }
    }

    .form-phone-number {
      .label {
        display: block;
        margin-bottom: 15px;
      }

      input {
        width: 81%;
        border: 1px solid #9c9b9a;
        height: 54px;
        padding: 10px;

        @include placeholder {
          text-transform: uppercase;
          color: #a0a0a0;
          opacity: 1;
          border-radius: 3px;
          font-size: 12px;
          font-family: 'opensans-semibold', sans-serif;
        }
      }
    }

    .btn-main-full {
      float: right;
    }
  }

  .modal-open {
    .header {
      padding-right: 17px;
    }
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    border: 0;
    padding: 0px;
  }

  #modal-validation{
    p{
      text-align: center;
      color: $green;
    }
    .modal-header{
      z-index: 2;
    }
    .modal-body{
      padding-bottom: 35px;
    }
  }