// Layout
$wrapperMax             : 1300px;
$wrapperMin             : 1250px;
$wrapperSmall           : 1180px;

// Font sizes
$base-font-size         : 16px;
$line-height            : 1.4;
$baseline               : $base-font-size * $line-height;

// Font stacks
$font-stack-common      : sans-serif;

// Transitions
$durationSmall: .5s;
$duration: .6s;
$durationMedium: 0.9s;
$durationLong: 1.2s;
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);

// Palettes
$whiteColor      : #ffffff;
$backgroundLight : #ffffff;
$blackColor      : #000;
$redColor        : #ff0000;
$greenColor      : #15D23B;
$redColor        : #f00;


// SOCIAL MEDIA COLORS
$facebook:       #3b5998;
$googleplus:     #dd4b39;
$instagram:      #517fa4;
$linkedin:       #007bb6;
$pinterest:      #cb2027;
$twitter:        #0dcfed;
$youtube:        #bb0000;

  // Font sizes
$base-font-size         : 16px;
$line-height            : 1.4;
$baseline               : $base-font-size * $line-height;

$font-family-base         : 'Open Sans', sans-serif;
$special-font-family      : 'Rimouski Semibold', sans-serif;
$special-font-familyReg   : 'Rimouski Regular', sans-serif;

// Color
$black              : #000000;
$black-border-textarea : #9c9b9a;
$text-color         : #393835;
$bg-grey            : #4b4b4b;
$grey-border-block  : #dadada;
$backgroundLight    : #ffffff;
$whiteColor         : #ffffff; // dafuck ??
$light_grey         : #f4f4f4;
$orange             : #f39200;
$orange_light       : #f8aa00;
$orange_dark        : #f28500;
$red                : #ff0000;
$green              : #95c11e;
$blue               : #009ee3;
$pink               : #e62075;
$brown              : #b49882;
$dark_grey          : #2b2b2b;
$greyTextColor      : #9f9e9e;
$placeholder        : #767676;
$title-disable      : #aaaaa9;
$purple             : #816ee2;
$facebook           : #3b5998;