/* ==========================================================================
   Components / Formulaires communs
   ========================================================================== */

.form-common {
  input[type='checkbox'] {
    cursor: pointer;
  }

  .type {
    margin-bottom: 20px;
    margin-left: 3px;

    span {
      display: block;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;

      @include breakpoint(phablet) {
        display: inline-block;
        margin-right: 13px;
        margin-bottom: 0;
      }
    }

    &-option {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;

      @include breakpoint(phablet) {
        margin-bottom: 0;
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"] + label {
        font-size: 16px;
        font-weight: 600;
        position: relative;
        cursor: pointer;

        &:before {
          content: ' ';
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 500px;
          border: 1px solid rgba(57, 56, 53, 0.5);
          padding: 0;
          margin-top: 0;
          margin-right: 14px;
          position: relative;
          top: 2px;
          cursor: pointer;
        }

        &:after {
          content: ' ';
          border-radius: 800px;
          width: 10px;
          height: 10px;
          background: $orange;
          position: absolute;
          top: 4px;
          left: 1px;
          right: 0;
          bottom: 0;
          margin: 2px;
          transform: scale(0.0);
          opacity: 0;
          transition: all $durationSmall $easeOutQuint;
        }
      }

      input[type="radio"]:checked + label {
        &:after {
          transform: scale(1.0);
          opacity: 1.0;
          transition: all $durationSmall $easeOutQuint;
        }
      }
    }
  }

  .civilite {
    margin-bottom: 25px;
    margin-left: 3px;

    span {
      display: block;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;

      @include breakpoint(phablet) {
        display: inline-block;
        margin-right: 35px;
        margin-bottom: 0;
      }
    }

    &-option {
      display: inline-block;
      margin-right: 49px;

      input[type="radio"] {
        display: none;
      }

      input[type="radio"] + label {
        font-size: 16px;
        font-weight: 600;
        position: relative;
        cursor: pointer;

        &:before {
          content: ' ';
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 500px;
          border: 1px solid rgba(57, 56, 53, 0.5);
          padding: 0;
          margin-top: 0;
          margin-right: 14px;
          position: relative;
          top: 2px;
          cursor: pointer;
        }

        &:after {
          content: ' ';
          border-radius: 800px;
          width: 10px;
          height: 10px;
          background: $orange;
          position: absolute;
          top: 4px;
          left: 1px;
          right: 0;
          bottom: 0;
          margin: 2px;
          transform: scale(0.0);
          opacity: 0;
          transition: all $durationSmall $easeOutQuint;
        }
      }

      input[type="radio"]:checked + label {
        &:after {
          transform: scale(1.0);
          opacity: 1.0;
          transition: all $durationSmall $easeOutQuint;
        }
      }
    }
  }

  .form-line {
    position: relative;
    margin-bottom: 19px;
  }

  .form-row:first-child {
    display: inline-block;
    width: 35%;
    position: relative;
  }

  .form-row:nth-child(2) {
    display: inline-block;
    width: 60%;
    float: right;
    position: relative;
  }

  input,
  textarea{
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    color: $text-color;
    border: 1px solid #9c9b9a;
    border-radius: 3px;
  }

  ::placeholder {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #a0a0a0;
  }

  textarea{
    resize: none;
    padding: 40px 17px 10px;
  }

  .container-placeholder{
    display: inline-block;
    position: absolute;
    top: 38px;
    left: 17px;
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 600;
  }

  input{
    height: 55px;
    padding-left: 15px;
  }

  .required + label {
    &:after {
      content: '*';
      margin-left: 3px;
      color: $red;
    }
  }

  .label{
    position: absolute;
    top: 20px;
    left: 17px;
    z-index: 1;
    transition: all 0.2s ease;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #a0a0a0;

    &.on{
      font-size: 10px;
      top: 7px;
      left: 14px;
      z-index: 2;
    }

    &.required {
      &:after {
        content: '*';
        margin-left: 3px;
        color: $red;
      }
    }
  }

  .special {
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 15px;
    vertical-align: middle;

    @include breakpoint(phablet) {
      margin-right: 40px;
    }

    & + input {
      display: inline-block;
      width: calc(100% - 156px);
      padding-top: 0;

      @include breakpoint(phablet) {
        width: calc(100% - 185px);
      }
    }
  }

  .input-checkbox{
    margin: 20px 0px;

    input{
      height: auto;
      position: absolute;
      left: -999px;
      width: auto;
      &:checked{
        & + label{
          &:after{
            display: block;
          }
        }
      }
    }
    .label{
      top: auto;
      left: auto;
      font-size: 16px;
      color: $dark_grey;
      position: relative;
      text-transform: none;
      padding-left: 40px;
      cursor: pointer;
      display: block;
      position: relative;
      &:before,
      &:after{
        content: '';
        position: absolute;
        top: -1px;
        left: 4px;
      }
      &:before{
        display: block;
        width: 23px;
        height: 23px;
        border: 1px solid #9e9e9c;
        border-radius: 3px;
      }
      &:after{
        width: 34px;
        height: 30px;
        margin: 4px;
        background: url('./src/img/icone-coche.svg') center center/ 34px 30px no-repeat;
        display: none;
        top: -13px;
        left: 0;
      }
    }

    span {
      font-size: 14px;
      user-select : none;
    }
  }

  .input-radio {
    input[type="radio"] {
      display: none;
    }

    input[type="radio"] + label {
      position: relative;
      cursor: pointer;

      &:after {
        content: ' ';
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 500px;
        border: 1px solid rgba(57, 56, 53, 0.5);
        padding: 0;
        margin-top: 0;
        margin-left: 20px;
        position: relative;
        top: 2px;
        cursor: pointer;
      }

      &:before {
        content: ' ';
        border-radius: 800px;
        width: 10px;
        height: 10px;
        background: $orange;
        position: absolute;
        top: 4px;
        right: 1px;
        bottom: 0;
        margin: 2px;
        transform: scale(0.0);
        opacity: 0;
        transition: all $durationSmall $easeOutQuint;
      }
    }

    input[type="radio"]:checked + label {
      font-weight: 600;

      &:before {
        transform: scale(1.0);
        opacity: 1.0;
        transition: all $durationSmall $easeOutQuint;
      }
    }
  }

  .part-2,
  .part-3{
    margin-top: 60px;
  }

  .part-3{
    margin-bottom: 55px;
  }

  .parag{
    strong{
      font-weight: 700;
    }
  }

  .part-1{
    .input-checkbox{
      margin: 5px 0;
    }
  }

  .part-1,
  .part-2,
  .part-3{
    .h2,
    .title-part-3{
      font-size: 21px;
      font-weight: 800;
    }
    .title-part-3{
      margin-bottom: 10px;
    }
  }

  .error {
    font-size: 13px;
    color: $red;
    position: relative;
    top: 7px;
    left: 2px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .fieldError {
    border: 1px solid $red;
  }

  .valid {
    border: 1px solid $green;
  }

  .viewPassword {
    display: block;
    width: 24px;
    height: 16px;
    background: url('./src/img/icone-voir-mdp.svg') center center/ 24px 16px no-repeat;
    position: absolute;
    top: 21px;
    bottom: 0;
    right: 20px;
    transition: all $duration $easeInOutCirc;
    cursor: pointer;
    z-index: 5;

    &.active {
      opacity: 0.5;
      filter: grayscale(100%);
      transition: all $duration $easeInOutCirc;
    }
  }

  .buttons {
    margin-top: 30px;
    margin-bottom: 65px;

    .btn {
      padding: 15px 53px;
    }
  }
}



#page-partenaire {
  .error {
    font-size: 18px;
    color: $red;
    position: relative;
    top: 7px;
    margin-bottom: 15px;
    font-weight: 300;
  }

  .fieldError {
    border: 1px solid $red;
  }

  .valid {
    border: 1px solid $green;
  }
  .input-error{
    input,
    select,
    .select-content select,
    textarea{
      border: 1px solid $red;
    }
    .label,
    label,
    .invalid-timeinterval{
      color: $red;
    }
    .ct-choice{
      border-left: 5px solid $red;
    }
  }
}

#card-element{
  color: #393835;
  border: 1px solid #9c9b9a;
  border-radius: 3px;
  padding: 18px;
}