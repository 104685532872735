/* ==========================================================================
   Page / Informations Personnelles
   ========================================================================== */

  .infosperso {
    margin-top: 40px;
    padding: 0 15px;

    @include breakpoint(tablethor) {
      margin-top: 70px;
    }

    .content {
      margin-top: 25px;

      @include breakpoint(tablethor) {
        margin-top: 55px;
      }
    }

    .infos {
      margin-top: 30px;
      font-size: 16px;
      margin-bottom: 25px;

      p {
        margin-bottom: 3px;

        &:first-child {
          margin-bottom: 5px;
        }

        &.uppercase {
          text-transform: uppercase;
        }
      }

      b {
        font-weight: 700;
      }
    }

    .adresses {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
      }

      @include breakpoint(laptop) {
        justify-content: space-between;
      }

      @include breakpoint(desktop) {
        justify-content: baseline;
      }

      &-elem {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        margin-bottom: 40px;

        @include breakpoint(laptop) {
          flex-basis: 31%;
          margin-right: 2%;
        }

        .h3-title {
          margin-bottom: 30px;
        }

        .infosadress {
          background: url('./src/img/picto-adresse.svg') left center/70px 59px no-repeat;
          padding-left: 90px;
          margin-bottom: 25px;
          flex-grow: 1;

          @include breakpoint(phablet) {
            background: transparent;
            padding-left: 0;
          }

          @include breakpoint(tablet) {
            background: url('./src/img/picto-adresse.svg') left center/73px 62px no-repeat;
            padding-left: 108px;
          }

          p {
            margin-bottom: 3px;
            font-size: 16px;

            &:first-child {
              margin-bottom: 5px;
            }


            &.uppercase {
              text-transform: uppercase;
            }

            b {
              font-weight: 700;
            }
          }
        }
      }
      .dashed{
        min-height: inherit;
        border: inherit;
        @include breakpoint(tablethor){
          justify-content: flex-start;
        }
        @include breakpoint(phablet){
          width: 49%;
          align-items: flex-start;
        }
      }
    }

    .btn-main {
      display: table;
      margin: 0;
      width: auto!important;
      padding: 15px 80px;
      align-self: baseline;
    }

    .btn-main-black-large {
      padding: 15px 85px;
      margin: 0;
    }
  }