/*-- RESET ALL --*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-weight:inherit;
  font-style:inherit;
  font-size:100%;
  font-family:inherit;
  vertical-align:baseline;
}

* {
  font-family: $font-family-base;
  font-size: $base-font-size;
  font-weight: 400;
}

html {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  display: block;
  width: 100%;
  min-height: 100vh;
  color: $text-color;
  background-color: $backgroundLight;
  font-family: $font-family-base;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: $line-height;
  position: relative;
  overflow-x: hidden;
}

ul, li{
  list-style: none;
}

b, strong {
    font-weight: 600;
  }

  em, i {
    font-style: italic;
  }

  a {


    &:hover {

    }
  }

  .hidden {
    display: none;
  }

  .sr-only {
   border: 0 !important;
   clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
   clip-path: inset(50%) !important; /* 2 */
   height: 1px !important;
   overflow: hidden !important;
   padding: 0 !important;
   position: absolute !important;
   width: 1px !important;
   white-space: nowrap !important; /* 3 */
}

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  #card-element{
    .CardField-child{
      color: #393835;
      border: 1px solid #9c9b9a;
      border-radius: 3px;
    }
  }
